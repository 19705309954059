/*eslint-disable*/
import React, { useEffect, useState, useRef } from "react";

import IndexNavbarr from "components/Navbars/IndexNavbarr.js";
import './ImageMapComponent.css';
import Footer from "components/Footers/Footer";
import embassymasterplan from "assets/img/embassymasterplan.webp"
import masterplanimage from "assets/img/masterplanimage.webp";

export default function Goldenwillows() {

    const containerRef = useRef(null);
    const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
    const originalWidth = 14997;
    const originalHeight = 5999;

    const highlight = (id) => {
        const place = document.getElementById("place_" + id);
        if (place) {
            place.style.backgroundColor = '#b93d29';
            place.style.color = 'white';

        }
        const markers = document.getElementsByClassName('marker-' + id);
        Array.from(markers).forEach(marker => {
            marker.style.borderWidth = '2px';
            marker.style.borderColor = '#bf925e';
            marker.style.outline = '2px solid #bf925e';
            marker.style.borderStyle = 'solid';

            const label = document.createElement('span');
            label.className = "span-" + id;
            label.innerText = place.innerText;
            label.style.position = 'absolute';
            label.style.top = '-50px';
            label.style.right = '-10px';
            label.style.backgroundColor = '#bf925e';
            label.style.color = 'white';
            label.style.padding = '2px 2px';
            label.style.fontSize = '10px';
            label.style.borderRadius = '4px';
            label.style.zIndex = '1000';
            label.style.fontWeight = '500';
            label.style.maxWidth = '100px'; // Allow label to grow but limit the width
            label.style.wordWrap = 'break-word'; // Ensure text wraps to the next line
            label.style.marginTop = '8px';

            // Append the label to the marker element
            marker.appendChild(label);
        })
    }

    const removeHighlight = (id) => {
        const place = document.getElementById("place_" + id);
        if (place) {
            place.style.backgroundColor = '';
            place.style.color = 'black';
        }
        const markers = document.getElementsByClassName('marker-' + id);
        Array.from(markers).forEach(marker => {
            marker.style.borderWidth = '0px';
            marker.style.borderColor = 'black';
            marker.style.outline = '0px solid black';
            marker.style.borderStyle = 'solid';
        })
        const labels = document.getElementsByClassName('span-' + id);
        Array.from(labels).forEach(marker => {
            marker.remove();
        })
    }

    useEffect(() => {
        const handleResize = () => {
            if (containerRef.current) {
                const { width, height } = containerRef.current.getBoundingClientRect();
                setImageSize({ width, height });
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Initial size
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const markers = [
        { id: 1, x: 2690, y: 1451, label: '3' },
        { id: 2, x: 3100, y: 1470, label: '4' },
        { id: 3, x: 3580, y: 1500, label: '1' },
        { id: 4, x: 4200, y: 1400, label: '5' },
        { id: 5, x: 4720, y: 1480, label: '1' },
        { id: 6, x: 5220, y: 1400, label: '6' },
        { id: 7, x: 5800, y: 1500, label: '1' },
        { id: 8, x: 7690, y: 1400, label: '18' },
        { id: 9, x: 9495, y: 1600, label: '2' },
        { id: 10, x: 10540, y: 1600, label: '2' },
        { id: 11, x: 11600, y: 1600, label: '2' },
        { id: 12, x: 13080, y: 1470, label: '19' },
        { id: 13, x: 2950, y: 3200, label: '13' },
        { id: 14, x: 3200, y: 2800, label: '13' },
        { id: 15, x: 3800, y: 2400, label: '12' },
        { id: 16, x: 4200, y: 2850, label: '9' },
        { id: 17, x: 4150, y: 3050, label: '10' },
        { id: 18, x: 4750, y: 2600, label: '11' },
        { id: 19, x: 4700, y: 3480, label: '7' },
        { id: 20, x: 5000, y: 3000, label: '8' },
        { id: 21, x: 5820, y: 3950, label: '16' },
        { id: 22, x: 6850, y: 4350, label: '15' },
        { id: 23, x: 7700, y: 3400, label: '3' },
        { id: 24, x: 7700, y: 2600, label: '17' },
        { id: 25, x: 8200, y: 4550, label: '14' },
        { id: 26, x: 9150, y: 4500, label: '13' },
    ];

    return (
        <>
            <IndexNavbarr fixed />

            <section className="relative xl:pb-12 xl:pt-0 pt-24 lg:pt-0 md:pt-32 bg-gray-100 min-h-screen flex justify-center items-center" >
                <div className="container mx-auto ">
                    <div className="flex items-center flex-wrap xxl:flex-col-reverse xl:flex-col-reverse lg:flex-col-reverse md:flex-col-reverse flex-col-reverse">
                        <div
                            className="w-full xl:w-2/12 pr-2 lg:w-2/12 md:w-12/12 w-full bg-white border rounded-lg mt-6 xl:mt-0 lg:mt-0  "
                            style={{ boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px" }}
                        >
                            <div
                                className="flex xl:flex-wrap lg:flex-wrap items-center justify-between xl:py-6 py-6"
                                style={{ background: "#f0eeee" }}
                            >
                                <div className="pt-4 w-full px-6">
                                    <div className="font xxl:text-sm xl:text-sm lg:text-xs text-sm font-semibold text-center text-white py-2 rounded bgbutton">
                                        Legend
                                    </div>
                                </div>
                            </div>

                            <ol className="list-decimal list-inside px-4 pb-4 xl:pt-4 pt-6 xl:block lg:block cursor-pointer " style={{ listStyleType: 'decimal' }}>
                                <li className="xl:pt-1 pt-1 font xxl:text-xs xl:text-xs lg:text-xs text-sm" id="place_1" onMouseOver={() => highlight('1')} onMouseLeave={() => removeHighlight('1')}>Existing Development</li>
                                <li className="xl:pt-1 pt-1 font xxl:text-xs xl:text-xs lg:text-xs text-sm" id="place_2" onMouseOver={() => highlight('2')} onMouseLeave={() => removeHighlight('2')}>Future Development</li>
                                <li className="xl:pt-1 pt-1 font xxl:text-xs xl:text-xs lg:text-xs text-sm" id="place_3" onMouseOver={() => highlight('3')} onMouseLeave={() => removeHighlight('3')}>Seating Area</li>
                                <li className="xl:pt-1 pt-1 font xxl:text-xs xl:text-xs lg:text-xs text-sm" id="place_4" onMouseOver={() => highlight('4')} onMouseLeave={() => removeHighlight('4')}>Senior Citizens Plaza</li>
                                <li className="xl:pt-1 pt-1 font xxl:text-xs xl:text-xs lg:text-xs text-sm" id="place_5" onMouseOver={() => highlight('5')} onMouseLeave={() => removeHighlight('5')}>Outdoor Exercise</li>
                                <li className="xl:pt-1 pt-1 font xxl:text-xs xl:text-xs lg:text-xs text-sm" id="place_6" onMouseOver={() => highlight('6')} onMouseLeave={() => removeHighlight('6')}>Meeting Pods</li>
                                <li className="xl:pt-1 pt-1 font xxl:text-xs xl:text-xs lg:text-xs text-sm" id="place_7" onMouseOver={() => highlight('7')} onMouseLeave={() => removeHighlight('7')}>Club House</li>
                                <li className="xl:pt-1 pt-1 font xxl:text-xs xl:text-xs lg:text-xs text-sm" id="place_8" onMouseOver={() => highlight('8')} onMouseLeave={() => removeHighlight('8')}>Swimming Pool</li>
                                <li className="xl:pt-1 pt-1 font xxl:text-xs xl:text-xs lg:text-xs text-sm" id="place_9" onMouseOver={() => highlight('9')} onMouseLeave={() => removeHighlight('9')}>Kids Pool</li>
                                <li className="xl:pt-1 pt-1 font xxl:text-xs xl:text-xs lg:text-xs text-sm" id="place_10" onMouseOver={() => highlight('10')} onMouseLeave={() => removeHighlight('10')}>Bubbler Pool</li>
                                <li className="xl:pt-1 pt-1 font xxl:text-xs xl:text-xs lg:text-xs text-sm" id="place_11" onMouseOver={() => highlight('11')} onMouseLeave={() => removeHighlight('11')}>Pool Deck</li>
                                <li className="xl:pt-1 pt-1 font xxl:text-xs xl:text-xs lg:text-xs text-sm" id="place_12" onMouseOver={() => highlight('12')} onMouseLeave={() => removeHighlight('12')}>Party Lawn</li>
                                <li className="xl:pt-1 pt-1 font xxl:text-xs xl:text-xs lg:text-xs text-sm" id="place_13" onMouseOver={() => highlight('13')} onMouseLeave={() => removeHighlight('13')}>Kids Play Area</li>
                                <li className="xl:pt-1 pt-1 font xxl:text-xs xl:text-xs lg:text-xs text-sm" id="place_14" onMouseOver={() => highlight('14')} onMouseLeave={() => removeHighlight('14')}>Tennis Court</li>
                                <li className="xl:pt-1 pt-1 font xxl:text-xs xl:text-xs lg:text-xs text-sm" id="place_15" onMouseOver={() => highlight('15')} onMouseLeave={() => removeHighlight('15')}>Cricket Pitch</li>
                                <li className="xl:pt-1 pt-1 font xxl:text-xs xl:text-xs lg:text-xs text-sm" id="place_16" onMouseOver={() => highlight('16')} onMouseLeave={() => removeHighlight('16')}>Resting Plaza</li>
                                <li className="xl:pt-1 pt-1 font xxl:text-xs xl:text-xs lg:text-xs text-sm" id="place_17" onMouseOver={() => highlight('17')} onMouseLeave={() => removeHighlight('17')}>Water Feature</li>
                                <li className="xl:pt-1 pt-1 font xxl:text-xs xl:text-xs lg:text-xs text-sm" id="place_18" onMouseOver={() => highlight('18')} onMouseLeave={() => removeHighlight('18')}>TMC Amenity Plot</li>
                                <li className="xl:pt-1 pt-1 font xxl:text-xs xl:text-xs lg:text-xs text-sm" id="place_19" onMouseOver={() => highlight('19')} onMouseLeave={() => removeHighlight('19')}>Inclusive Housing</li>
                            </ol>
                        </div>
                        <div className="relative w-full xxl:w-10/12 xl:w-10/12 lg:w-10/12 pl-4 pr-4 xl:pt-0 lg:pt-0 md:pt-12" useRef={containerRef}>
                            <img src={masterplanimage} alt="" className="w-full h-auto" />
                            {markers.map((marker) => (
                                <a
                                    key={marker.id}
                                    href="#!"
                                    className={'absolute p-1 cursor-pointer marker-' + marker.label}
                                    style={{
                                        top: `${(marker.y / originalHeight) * 100}%`, // Position relative to image height
                                        left: `${(marker.x / originalWidth) * 100}%`, // Position relative to image width
                                        transform: 'translate(-50%, -50%)', // Center the circle on the marker
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        height: '15px',
                                        width: '15px',
                                        backgroundColor: '#b93d29',
                                        color: 'white',
                                        fontSize: '8px', // Adjust font size for small markers
                                        borderRadius: '50%',
                                        textAlign: 'center',
                                        textDecoration: 'none'
                                    }}
                                    onMouseOver={() => highlight(marker.label)}
                                    onMouseLeave={() => removeHighlight(marker.label)}
                                >
                                    {marker.label}
                                </a>
                            ))}
                        </div>


                    </div>
                </div>
            </section>

            <Footer />
        </>
    );
}
