/*eslint-disable*/
import React, { useEffect, useState, useRef } from "react";
import Atowertypical from "assets/img/floor-plan/Tower-A-Typical.jpg";
import Atowerrefugee from "assets/img/floor-plan/Tower-A-Refugee.jpg";
import Atowersecond from "assets/img/floor-plan/Tower-A-Second-floor.jpg";
import IndexNavbarr from "components/Navbars/IndexNavbarr.js";
import "./ImageMapComponent.css";
import Footer from "components/Footers/Footer";
import { useParams } from "react-router";

export default function Detail() {

    const paddingX = 2;
  const paddingY = 2;

  
  const [activeArea, setActiveArea] = useState(null);
  const [activeAreaName, setActiveAreaName] = useState("");
  const [activeElement, setActiveElement] = useState(null);
  const [imageSize, setImageSize] = useState({
    width: 0,
    height: 0,
    top: 0,
    left: 0,
  });
  const [isTouchDevice, setIsTouchDevice] = useState(false);
  const imgRef = useRef(null);
  const originalWidth = 886;
  const { floor, plan } = useParams();

  const resizeImageMap = () => {
    const img = imgRef.current;
    const map = document.querySelector('map[name="image-map"]');
    const widthRatio = img.clientWidth / originalWidth;

    map.querySelectorAll("area").forEach((area) => {
      const originalCoords = area.dataset.coords.split(",").map(Number);
      const newCoords = originalCoords.map((coord) => coord * widthRatio);
      area.coords = newCoords.join(",");
    });
  };

  useEffect(() => {
    setIsTouchDevice("ontouchstart" in window || navigator.maxTouchPoints > 0);
    setActiveArea(null);
    resizeImageMap();

    window.addEventListener("resize", resizeImageMap);
    window.addEventListener("load", resizeImageMap);

    const img = document.querySelector("img[usemap]");
    if (img) {
      const updateImageSize = () => {
        const { clientWidth, clientHeight, offsetTop, offsetLeft } = img;
        setImageSize({
          width: clientWidth,
          height: clientHeight,
          top: offsetTop,
          left: offsetLeft,
        });
      };
      updateImageSize();
      window.addEventListener("load", updateImageSize);
      window.addEventListener("resize", updateImageSize);

      return () => window.removeEventListener("resize", updateImageSize);
    }

    return () => {
      window.removeEventListener("resize", resizeImageMap);
      window.removeEventListener("load", resizeImageMap);
    };
  }, []);

  const navigateToNext = (unit) => {
    // console.log('/iso-3d/' + floor + '/' + plan + '/' + unit);
    window.location = "/iso-3d/" + floor + "/" + plan + "/" + unit;
  };

  const handleAreaClick = (area) => {
    setActiveArea(area.target.coords.split(",").map(Number));
    setActiveElement(area.target.id);
    setActiveAreaName(area.target.title);
  };

  const handleAreaMouseEnter = (area) => {
    if (!isTouchDevice) {
      setActiveArea(area.target.coords.split(",").map(Number));
      setActiveElement(area.target.id);
      setActiveAreaName(area.target.title);
    }
  };

  const handleAreaMouseLeave = (area) => {
    if (!isTouchDevice) {
      setActiveArea(null);
      setActiveElement(null);
      setActiveAreaName("");
    }
  };

  const [isZoomed, setIsZoomed] = useState(false);
  const [zoomedImage, setZoomedImage] = useState(null);

  // Object holding images for each plan type
  const images = {
    typical: Atowertypical,
    refugee: Atowerrefugee,
    second: Atowersecond,
  };

  // Plan state for dynamic image selection
  // Default plan is 'typical'

  // Zoom image function, triggered by the button
  const zoomImage = (selectedPlan) => {
    if (images[selectedPlan]) {
      setZoomedImage(images[selectedPlan]);
      setIsZoomed(true);
    }
  };

  // Close the zoomed image
  const closeZoom = () => {
    setIsZoomed(false);
    setZoomedImage(null);
  };

  return (
    <>
      <IndexNavbarr fixed />

      <section className="relative h-screen xl:py-32 lg:pt-32 md:pt-24 xl:pb-12 pt-24 bg-gray-100">
        <div className="container mx-auto flex justify-center items-center">
          <div className="flex flex-wrap justify-between  lg:flex-row flex-col-reverse">
            <div
              className="w-full xl:w-2/12  lg:w-2/12 md:w-12/12 w-full bg-white border rounded-lg mt-6 xl:mt-0 lg:mt-0 "
              style={{ boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px" }}
            >
              <div
                className="flex xl:flex-wrap lg:flex-wrap items-center justify-between xl:py-6 py-6"
                style={{ background: "#f0eeee" }}
              >
                <div className="pt-4 w-full px-6">
                  <div className="font text-sm font-semibold text-center text-white py-2 rounded bgbutton">
                    Floor No. : {floor}
                  </div>
                </div>
              </div>

              <div className="px-6 xl:w-12/12 lg:w-12/12 md:w-4/12 mx-auto pb-4 xl:pt-4 lg:pt-6 pt-2 xl:block lg:block ">
                <div
                  className="font xl:text-xs lg:text-xs text-xs font-semibold text-center text-white py-3 rounded-xl"
                  style={{
                    background: "#d8b387",
                    textTransform:
                      activeElement === "mainImage_1_1" ? "uppercase" : "none",
                    fontSize:
                      activeElement === "mainImage_1_1" ? "13px" : "14px",
                  }}
                >
                  Unit No. 1
                </div>
                <div
                  className="mt-2 font xl:text-xs lg:text-xs text-xs font-semibold text-center text-white py-3 rounded-xl"
                  style={{
                    background: "#d8b387",
                    textTransform:
                      activeElement === "mainImage_1_2" ? "uppercase" : "none",
                    fontSize:
                      activeElement === "mainImage_1_2" ? "13px" : "14px",
                  }}
                >
                  Unit No. 2
                </div>
                <div
                  className="mt-2 font xl:text-xs lg:text-xs text-xs font-semibold text-center text-white py-3 rounded-xl"
                  style={{
                    background: "#d8b387",
                    textTransform:
                      activeElement === "mainImage_1_3" ? "uppercase" : "none",
                    fontSize:
                      activeElement === "mainImage_1_3" ? "13px" : "14px",
                  }}
                >
                  Unit No. 3
                </div>
                {floor !== "2" && (
                  <div
                    className="mt-2 font xl:text-xs lg:text-xs text-xs font-semibold text-center text-white py-3 rounded-xl"
                    style={{
                      background: "#d8b387",
                      textTransform:
                        activeElement === "mainImage_1_4"
                          ? "uppercase"
                          : "none",
                      fontSize:
                        activeElement === "mainImage_1_4" ? "13px" : "14px",
                    }}
                  >
                    Unit No. 4
                  </div>
                )}
              </div>
            </div>

            <div className="w-full xl:w-8/12 lg:w-8/12 md:w-12/12 px-6 xl:pt-0 lg:pt-0 md:pt-0 pt-6 pb-2">
              {plan === "typical" && (
                <>
                  <img
                    src={Atowertypical}
                    useMap="#image-map"
                    ref={imgRef}
                    style={{ maxWidth: "100%", height: "auto", width: "100%" }}
                  />
                  <map name="image-map">
                    <area
                      target=""
                      alt=""
                      href="#!"
                      data-coords="412,196,435,197,439,142,500,144,500,58,438,59,436,37,317,38,314,52,251,52,249,163,282,166,282,131,311,130,312,162,334,163,335,207,351,209,410,208"
                      shape="poly"
                      onClick={
                        isTouchDevice
                          ? handleAreaClick
                          : () => navigateToNext("1")
                      }
                      onDoubleClick={
                        isTouchDevice ? () => navigateToNext("1") : undefined
                      }
                      onMouseLeave={
                        !isTouchDevice ? handleAreaMouseLeave : undefined
                      }
                      onMouseEnter={
                        !isTouchDevice ? handleAreaMouseEnter : undefined
                      }
                      id="mainImage_1_1"
                      title="Unit No. 1"
                    />
                    <area
                      target=""
                      alt=""
                      href="#!"
                      data-coords="566,198,590,198,590,209,666,207,666,166,688,165,689,131,717,132,720,163,751,164,749,54,689,54,688,38,565,42,564,60,501,59,501,144,564,145"
                      shape="poly"
                      onClick={
                        isTouchDevice
                          ? handleAreaClick
                          : () => navigateToNext("2")
                      }
                      onDoubleClick={
                        isTouchDevice ? () => navigateToNext("2") : undefined
                      }
                      onMouseLeave={
                        !isTouchDevice ? handleAreaMouseLeave : undefined
                      }
                      onMouseEnter={
                        !isTouchDevice ? handleAreaMouseEnter : undefined
                      }
                      id="mainImage_1_2"
                      title="Unit No. 2"
                    />
                    <area
                      target=""
                      alt=""
                      href="#!"
                      data-coords="502,329,566,329,566,285,636,285,637,327,622,328,623,360,657,361,657,327,682,328,682,438,627,436,626,455,564,451,503,451"
                      shape="poly"
                      onClick={
                        isTouchDevice
                          ? handleAreaClick
                          : () => navigateToNext("3")
                      }
                      onDoubleClick={
                        isTouchDevice ? () => navigateToNext("3") : undefined
                      }
                      onMouseLeave={
                        !isTouchDevice ? handleAreaMouseLeave : undefined
                      }
                      onMouseEnter={
                        !isTouchDevice ? handleAreaMouseEnter : undefined
                      }
                      id="mainImage_1_3"
                      title="Unit No. 3"
                    />
                    <area
                      target=""
                      alt=""
                      href="#!"
                      data-coords="501,327,480,328,434,328,435,285,363,285,364,328,388,331,388,370,353,372,354,329,288,329,290,391,273,392,274,418,293,420,293,459,441,460,443,450,503,452"
                      shape="poly"
                      onClick={
                        isTouchDevice
                          ? handleAreaClick
                          : () => navigateToNext("4")
                      }
                      onDoubleClick={
                        isTouchDevice ? () => navigateToNext("4") : undefined
                      }
                      onMouseLeave={
                        !isTouchDevice ? handleAreaMouseLeave : undefined
                      }
                      onMouseEnter={
                        !isTouchDevice ? handleAreaMouseEnter : undefined
                      }
                      id="mainImage_1_4"
                      title="Unit No. 4"
                    />
                  </map>
                </>
              )}
              {plan === "refugee" && (
                <>
                  <img
                    src={Atowerrefugee}
                    useMap="#image-map"
                    ref={imgRef}
                    style={{ maxWidth: "100%", height: "auto", width: "100%" }}
                  />
                  <map name="image-map">
                    <area
                      target=""
                      alt="unit 1"
                      href="#!"
                      data-coords="412,196,434,196,435,39,315,37,316,51,252,53,250,163,281,163,282,129,313,130,312,163,335,164,335,207,411,207"
                      shape="poly"
                      onClick={
                        isTouchDevice
                          ? handleAreaClick
                          : () => navigateToNext("1")
                      }
                      onDoubleClick={
                        isTouchDevice ? () => navigateToNext("1") : undefined
                      }
                      onMouseLeave={
                        !isTouchDevice ? handleAreaMouseLeave : undefined
                      }
                      onMouseEnter={
                        !isTouchDevice ? handleAreaMouseEnter : undefined
                      }
                      id="mainImage_1_1"
                      title="Unit No. 1"
                    />
                    <area
                      target=""
                      alt="unit2"
                      href="#!"
                      data-coords="567,195,589,195,590,204,666,207,666,167,690,165,688,126,720,130,718,163,750,163,749,52,690,52,688,40,627,40,567,40"
                      shape="poly"
                      onClick={
                        isTouchDevice
                          ? handleAreaClick
                          : () => navigateToNext("2")
                      }
                      onDoubleClick={
                        isTouchDevice ? () => navigateToNext("2") : undefined
                      }
                      onMouseLeave={
                        !isTouchDevice ? handleAreaMouseLeave : undefined
                      }
                      onMouseEnter={
                        !isTouchDevice ? handleAreaMouseEnter : undefined
                      }
                      id="mainImage_1_2"
                      title="Unit No. 2"
                    />
                    <area
                      target=""
                      alt="unit 3"
                      href="#!"
                      data-coords="504,327,528,331,568,329,568,285,639,284,636,327,623,328,621,360,654,360,657,327,684,327,681,433,624,437,624,453,566,455,506,449"
                      shape="poly"
                      onClick={
                        isTouchDevice
                          ? handleAreaClick
                          : () => navigateToNext("3")
                      }
                      onDoubleClick={
                        isTouchDevice ? () => navigateToNext("3") : undefined
                      }
                      onMouseLeave={
                        !isTouchDevice ? handleAreaMouseLeave : undefined
                      }
                      onMouseEnter={
                        !isTouchDevice ? handleAreaMouseEnter : undefined
                      }
                      id="mainImage_1_3"
                      title="Unit No. 3"
                    />
                    <area
                      target=""
                      alt="unit 4"
                      href="#!"
                      data-coords="501,326,479,327,434,328,436,285,364,286,363,327,388,331,390,371,355,372,352,331,289,331,290,388,276,390,274,415,292,419,292,458,442,460,444,451,504,449"
                      shape="poly"
                      onClick={
                        isTouchDevice
                          ? handleAreaClick
                          : () => navigateToNext("4")
                      }
                      onDoubleClick={
                        isTouchDevice ? () => navigateToNext("4") : undefined
                      }
                      onMouseLeave={
                        !isTouchDevice ? handleAreaMouseLeave : undefined
                      }
                      onMouseEnter={
                        !isTouchDevice ? handleAreaMouseEnter : undefined
                      }
                      id="mainImage_1_4"
                      title="Unit No. 4"
                    />
                  </map>
                </>
              )}
              {plan === "second" && floor === "2" && (
                <>
                  <img
                    src={Atowersecond}
                    useMap="#image-map"
                    ref={imgRef}
                    style={{ maxWidth: "100%", height: "auto", width: "100%" }}
                  />
                  <map name="image-map">
                    <area
                      target=""
                      alt="unit1"
                      href="#!"
                      data-coords="412,196,434,196,432,143,498,142,498,58,440,59,438,40,316,40,314,54,256,52,253,162,279,162,279,127,313,128,312,164,335,166,334,206,409,208"
                      shape="poly"
                      onClick={
                        isTouchDevice
                          ? handleAreaClick
                          : () => navigateToNext("1")
                      }
                      onDoubleClick={
                        isTouchDevice ? () => navigateToNext("1") : undefined
                      }
                      onMouseLeave={
                        !isTouchDevice ? handleAreaMouseLeave : undefined
                      }
                      onMouseEnter={
                        !isTouchDevice ? handleAreaMouseEnter : undefined
                      }
                      id="mainImage_1_1"
                      title="Unit No. 1"
                    />
                    <area
                      target=""
                      alt="unit 2"
                      href="#!"
                      data-coords="568,197,590,197,591,207,664,207,666,165,690,163,687,130,720,132,720,164,751,164,749,52,690,51,689,39,567,39,568,59,501,58,501,143,565,145"
                      shape="poly"
                      onClick={
                        isTouchDevice
                          ? handleAreaClick
                          : () => navigateToNext("2")
                      }
                      onDoubleClick={
                        isTouchDevice ? () => navigateToNext("2") : undefined
                      }
                      onMouseLeave={
                        !isTouchDevice ? handleAreaMouseLeave : undefined
                      }
                      onMouseEnter={
                        !isTouchDevice ? handleAreaMouseEnter : undefined
                      }
                      id="mainImage_1_2"
                      title="Unit No. 2"
                    />
                    <area
                      target=""
                      alt="unit 3"
                      href="#!"
                      data-coords="503,328,503,452,565,451,624,453,624,435,682,436,683,327,657,329,656,361,623,361,623,327,637,328,636,285,564,285,565,329"
                      shape="poly"
                      onClick={
                        isTouchDevice
                          ? handleAreaClick
                          : () => navigateToNext("3")
                      }
                      onDoubleClick={
                        isTouchDevice ? () => navigateToNext("3") : undefined
                      }
                      onMouseLeave={
                        !isTouchDevice ? handleAreaMouseLeave : undefined
                      }
                      onMouseEnter={
                        !isTouchDevice ? handleAreaMouseEnter : undefined
                      }
                      id="mainImage_1_3"
                      title="Unit No. 3"
                    />
                  </map>
                </>
              )}

              {activeArea && (
                <svg
                  className="absolute pointer-events-none"
                  xmlns="http://www.w3.org/2000/svg"
                  width={imageSize.width}
                  height={imageSize.height}
                  style={{
                    top: `${imageSize.top}px`,
                    left: `${imageSize.left}px`,
                  }}
                >
                  <polygon
                    className="highlight"
                    points={activeArea.join(",")}
                  />

                  {(() => {
                    // Split text into multiple lines with maximum 15 characters per line
                    const lines = activeAreaName.split(" ").reduce(
                      (acc, word) => {
                        const lastLine = acc[acc.length - 1] || "";
                        if ((lastLine + " " + word).length <= 25) {
                          acc[acc.length - 1] = lastLine
                            ? `${lastLine} ${word}`
                            : word;
                        } else {
                          acc.push(word);
                        }
                        return acc;
                      },
                      [""]
                    );

                    // Calculate width and height for the rect based on longest line and number of lines
                    const maxLineWidth = Math.max(
                      ...lines.map((line) => line.length * 9)
                    );
                    const rectWidth = maxLineWidth + paddingX * 2;
                    const rectHeight = lines.length * 20 + paddingY * 2;

                    // X and Y coordinates for positioning the rect
                    const rectX =
                      Math.max(
                        ...activeArea.filter((_, index) => index % 2 === 0)
                      ) + 5;
                    const rectY =
                      Math.min(
                        ...activeArea.filter((_, index) => index % 2 !== 0)
                      ) -
                      25 -
                      paddingY;

                    // Adjust rectX to prevent overflow beyond the right boundary of the image
                    const adjustedRectX =
                      rectX + rectWidth > imageSize.width
                        ? imageSize.width - rectWidth - 5
                        : rectX;

                    return (
                      <>
                        {/* Background rect with calculated dimensions */}
                        <rect
                          x={adjustedRectX}
                          y={rectY}
                          width={rectWidth}
                          height={rectHeight}
                          fill="#d8b387"
                          rx="5"
                          ry="5"
                        />

                        {/* Render each line of text with calculated positioning */}
                        {lines.map((line, index) => (
                          <text
                            key={index}
                            x={adjustedRectX + paddingX}
                            y={rectY + paddingY + 16 + index * 16}
                            fill="white"
                            fontSize="14"
                            fontFamily="Outfit, sans-serif"
                            textAnchor="start"
                          >
                            {line}
                          </text>
                        ))}
                      </>
                    );
                  })()}
                </svg>
              )}
            </div>
            <div
              className="w-full xl:w-2/12  lg:w-2/12 md:w-12/12 w-full rounded-lg mt-6 xl:mt-0 lg:mt-0 "
              // style={{ boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px" }}
            >
              <div
                className="flex xl:flex-wrap rounded-lg border lg:flex-wrap xl:gap-0 lg:gap-0 gap-4 items-center justify-between xl:py-6 py-6 xl:px-0 px-2"
                style={{
                  background: "#f0eeee",
                  boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                }}
              >
                <div className="xl:pt-4 w-full xl:w-9/12 mx-auto">
                  <a href="/tower">
                    <div className="font text-sm font-semibold text-center text-white py-2 rounded bgbutton">
                      Go Back
                    </div>
                  </a>
                </div>
                <div className="xl:pt-2 pt-2 xl:block lg:block block w-full xl:w-9/12 mx-auto">
                  {/* Zoom Image button */}
                  <div
                    className="cursor-pointer  font text-sm font-semibold text-center text-white py-2 rounded bgbutton"
                    onClick={() => zoomImage(plan)} // Pass the selected plan
                  >
                    Zoom Image
                  </div>
                </div>
              </div>
            </div>
          </div>
          {isZoomed && (
            <div className="zoom-overlay">
              <div className="zoom-container">
                {/* Image to be zoomed */}
                <img
                  src={zoomedImage} // Dynamically set the zoomed image
                  alt={`${plan} plan`}
                  className="zoomed-image"
                />

                {/* Close button */}
                <div className="close-button " onClick={closeZoom}>
                  <i className="fas fa-times pl-1 "></i>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>

      <Footer />
    </>
  );
}
