/*eslint-disable*/
import React, { useEffect, useState, useRef } from "react";
import towertypicala from "assets/img/typical/towertypicala.jpg";
import unita from "assets/img/typical/unita.jpg";
import unit1Typical from "assets/img/typical-3d/TOWER A UNIT 1.jpg";
import unit2Typical from "assets/img/typical-3d/TOWER A UNIT 2.jpg";
import unit3Typical from "assets/img/typical-3d/TOWER A UNIT 3.jpg";
import unit4Typical from "assets/img/typical-3d/TOWER A UNIT 4.jpg";
import unit1Refugee from "assets/img/refugee-3d/TOWER A REFUGE UNIT 1.jpg";
import unit2Refugee from "assets/img/refugee-3d/TOWER A REFUGE UNIT 2.jpg";
import IndexNavbarr from "components/Navbars/IndexNavbarr.js";
import "./ImageMapComponent.css";
import Footer from "components/Footers/Footer";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";

export default function Iso() {
  const history = useHistory();
  const [activeArea, setActiveArea] = useState(null);
  const [activeAreaName, setActiveAreaName] = useState("");
  const [activeElement, setActiveElement] = useState(null);
  const [imageSize, setImageSize] = useState({
    width: 0,
    height: 0,
    top: 0,
    left: 0,
  });
  const [isTouchDevice, setIsTouchDevice] = useState(false);
  const imgRef = useRef(null);
  const originalWidth = 886;
  const { floor, plan, unit } = useParams();
  const paddingX = 2;
  const paddingY = 2;

  const resizeImageMap = () => {
    const img = imgRef.current;
    const map = document.querySelector('map[name="image-map"]');
    const widthRatio = img.clientWidth / originalWidth;

    map.querySelectorAll("area").forEach((area) => {
      const originalCoords = area.dataset.coords.split(",").map(Number);
      const newCoords = originalCoords.map((coord) => coord * widthRatio);
      area.coords = newCoords.join(",");
    });
  };

  useEffect(() => {
    setIsTouchDevice("ontouchstart" in window || navigator.maxTouchPoints > 0);
    setActiveArea(null);
    resizeImageMap();

    window.addEventListener("resize", resizeImageMap);
    window.addEventListener("load", resizeImageMap);

    const img = document.querySelector("img[usemap]");
    if (img) {
      const updateImageSize = () => {
        const { clientWidth, clientHeight, offsetTop, offsetLeft } = img;
        setImageSize({
          width: clientWidth,
          height: clientHeight,
          top: offsetTop,
          left: offsetLeft,
        });
      };
      updateImageSize();
      window.addEventListener("load", updateImageSize);
      window.addEventListener("resize", updateImageSize);

      return () => window.removeEventListener("resize", updateImageSize);
    }

    return () => {
      window.removeEventListener("resize", resizeImageMap);
      window.removeEventListener("load", resizeImageMap);
    };
  }, []);

  const handleAreaClick = (area) => {
    setActiveArea(area.target.coords.split(",").map(Number));
    setActiveElement(area.target.id);
    setActiveAreaName(area.target.getAttribute("data-title"));
  };

  const handleAreaMouseEnter = (area) => {
    if (!isTouchDevice) {
      setActiveArea(area.target.coords.split(",").map(Number));
      setActiveElement(area.target.id);
      setActiveAreaName(area.target.getAttribute("data-title"));
    }
  };

  const handleAreaMouseLeave = (area) => {
    if (!isTouchDevice) {
      setActiveArea(null);
      setActiveElement(null);
      setActiveAreaName("");
    }
  };

  return (
    <>
      <IndexNavbarr fixed />

      <section className="relative h-screen xl:py-32 lg:pt-32 md:pt-24 xl:pb-12 pt-24 bg-gray-100">
        <div className="container mx-auto flex justify-center items-center">
          <div className="flex flex-wrap justify-between  lg:flex-row flex-col-reverse">
            <div className="w-full xl:w-2/12 lg:w-2/12 md:w-12/12 w-full  px-1 pt-2 ">
              <div
                className="bg-white  rounded-lg  "
                style={{
                  boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                  height: "500px",
                  overflowY: "scroll",
                }}
              >
                {plan === "typical" && unit === "1" && (
                  <table className=" rounded-lg w-full py-2">
                    <tr
                      className="t_foyer"
                      style={
                        ["mainImage_1_11"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Entrance Foyer
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        4'6" x 6'3"
                      </td>
                    </tr>
                    <tr
                      className="t_living "
                      style={
                        ["mainImage_1_14"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Living/Dining{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs"
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'4" x 19'10" <br></br>& 3'8" x 12'2"
                      </td>
                    </tr>
                    <tr
                      className="t_balcony_1"
                      style={
                        ["mainImage_1_13"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Deck
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'2" x 4'7"
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["mainImage_1_10"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Kitchen
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        10'10" x 8'
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["mainImage_1_7"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Dry Balcony
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        3'3" x 8'0"
                      </td>
                    </tr>
                    <tr
                      className="t_utility"
                      style={
                        ["mainImage_1_6"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Utility/Storage
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        7' x 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_bedroom_1"
                      style={
                        ["mainImage_1_8"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Pooja Room
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        1'2" x 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_balcony"
                      style={
                        ["mainImage_1_16"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        {" "}
                        Bedroom 2 Passage
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        3'5" x 3'7"
                      </td>
                    </tr>
                    <tr
                      className="t_passage"
                      style={
                        ["mainImage_1_17"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Bedroom 2 Wardrobe
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        2'10" × 1'18"
                      </td>
                    </tr>
                    <tr
                      className="t_bedroom_2"
                      style={
                        ["mainImage_1_15"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Bedroom 2
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'7"× 11'
                      </td>
                    </tr>
                    <tr
                      className="t_toilet_2"
                      style={
                        ["mainImage_1_18"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        {" "}
                        Toilet 2{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        8' x 5'
                      </td>
                    </tr>
                    <tr
                      className="t_master_passage"
                      style={
                        ["mainImage_1_9"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Passage 1
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5'× 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_bedroom"
                      style={
                        ["mainImage_1_12"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        {" "}
                        Bedroom 3
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11' x 12'
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_1_5"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Common Toilet
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        8' x 4'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_1_2"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Passage
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        3'5" x 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_1_1"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Bedroom
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11' x 12'10"
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_1_3"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Wardrobe
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5' x 2'
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_1_4"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Toilet
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5' x 8'4"
                      </td>
                    </tr>
                  </table>
                )}
                {plan === "typical" && unit === "2" && (
                  <table className=" rounded-lg w-full py-2">
                    <tr
                      className="t_foyer"
                      style={
                        ["mainImage_2_11"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Entrance Foyer
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        4'6" x 6'3"
                      </td>
                    </tr>
                    <tr
                      className="t_living "
                      style={
                        ["mainImage_2_17"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Living/Dining{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs"
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'4" x 19'10" <br></br>& 3'8" x 12'2"
                      </td>
                    </tr>
                    <tr
                      className="t_balcony_1"
                      style={
                        ["mainImage_2_16"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Deck
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'2" x 4'7"
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["mainImage_2_10"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Kitchen
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        10'10" x 8'
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["mainImage_2_9"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Dry Balcony
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        3'3" x 8'0"
                      </td>
                    </tr>
                    <tr
                      className="t_utility"
                      style={
                        ["mainImage_2_8"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Utility/Storage
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        7' x 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_bedroom_1"
                      style={
                        ["mainImage_2_0"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Pooja Room
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        1'2" x 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_balcony"
                      style={
                        ["mainImage_2_15"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        {" "}
                        Bedroom 2 Passage
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        3'5" x 3'7"
                      </td>
                    </tr>
                    <tr
                      className="t_passage"
                      style={
                        ["mainImage_2_14"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Bedroom 2 Wardrobe
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        2'10" × 1'18"
                      </td>
                    </tr>
                    <tr
                      className="t_bedroom_2"
                      style={
                        ["mainImage_2_12"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Bedroom 2
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'7"× 11'
                      </td>
                    </tr>
                    <tr
                      className="t_toilet_2"
                      style={
                        ["mainImage_2_13"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        {" "}
                        Toilet 2{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        8' x 5'
                      </td>
                    </tr>
                    <tr
                      className="t_master_passage"
                      style={
                        ["mainImage_2_6"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Passage 1
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5'× 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_bedroom"
                      style={
                        ["mainImage_2_7"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        {" "}
                        Bedroom 3
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11' x 12'
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_2_5"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Common Toilet
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        8' x 4'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_2_2"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Passage
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        3'5" x 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_2_1"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Bedroom
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11' x 12'10"
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_2_4"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Wardrobe
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5' x 2'
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_2_3"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Toilet
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5' x 8'4"
                      </td>
                    </tr>
                  </table>
                )}
                {plan === "typical" && unit === "3" && (
                  <table className=" rounded-lg w-full py-2">
                    <tr
                      className="t_living "
                      style={
                        ["mainImage_3_10"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Living/Dining{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs"
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'2" x 19'4" <br></br>& 6'3" x 11'8"
                      </td>
                    </tr>
                    <tr
                      className="t_balcony_1"
                      style={
                        ["mainImage_3_9"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Deck
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'2" x 4'7"
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["mainImage_3_7"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Kitchen
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        10'6" x 8'4"
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["mainImage_3_6"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Dry Balcony
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        3'3" x 7'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_balcony"
                      style={
                        ["mainImage_3_5"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Passage
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5' x 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_bedroom_2"
                      style={
                        ["mainImage_3_8"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Bedroom 2
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        10'× 12'
                      </td>
                    </tr>
                    <tr
                      className="t_toilet_2"
                      style={
                        ["mainImage_3_4"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        {" "}
                        Common Toilet{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5' x 8'
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_3_1"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Bedroom
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11' x 12'
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_3_3"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Wardrobe
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        6'3" x 2'
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_3_2"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Toilet
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5' x 8'
                      </td>
                    </tr>
                  </table>
                )}
                {plan === "typical" && unit === "4" && (
                  <table className=" rounded-lg w-full py-2">
                    <tr
                      className="t_living "
                      style={
                        ["mainImage_4_11"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Living/Dining{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs"
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'2" x 19'14" <br></br>& 5'5" x 11'10"
                      </td>
                    </tr>
                    <tr
                      className="t_balcony_1"
                      style={
                        ["mainImage_4_10"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Deck
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'2" x 4'7"
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["mainImage_4_9"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Kitchen
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        10'6" x 8'4"
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["mainImage_4_8"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Dry Balcony
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        3'3" x 8'4"
                      </td>
                    </tr>
                    <tr
                      className="t_master_balcony"
                      style={
                        ["mainImage_4_3"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Passage
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        12'2" x 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_passage"
                      style={
                        ["mainImage_4_6"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Bedroom 3
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        10'8" × 12'10"
                      </td>
                    </tr>
                    <tr
                      className="t_bedroom_2"
                      style={
                        ["mainImage_4_7"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Common Toilet
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5'× 8'
                      </td>
                    </tr>
                    <tr
                      className="t_toilet_2"
                      style={
                        ["mainImage_4_5"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        {" "}
                        Bedroom 2{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        10'10" x 12'10"
                      </td>
                    </tr>
                    <tr
                      className="t_master_passage"
                      style={
                        ["mainImage_4_4"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Toilet 2
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5'× 7'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_bedroom"
                      style={
                        ["mainImage_4_1"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        {" "}
                        Master Bedroom
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        12'6" x 11'10"
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_4_2"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Toilet
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        8'8" x 5'
                      </td>
                    </tr>
                  </table>
                )}
                {plan === "second" && unit === "1" && (
                  <table className=" rounded-lg w-full py-2">
                    <tr
                      className="t_foyer"
                      style={
                        ["mainImage_1_11"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Entrance Foyer
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        4'6" x 6'3"
                      </td>
                    </tr>
                    <tr
                      className="t_living "
                      style={
                        ["mainImage_1_14"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Living/Dining{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs"
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'4" x 19'10" <br></br>& 3'8" x 12'2"
                      </td>
                    </tr>
                    <tr
                      className="t_balcony_1"
                      style={
                        ["mainImage_1_13"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Deck
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'2" x 4'7"
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["mainImage_1_10"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Kitchen
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        10'10" x 8'
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["mainImage_1_7"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Dry Balcony
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        3'3" x 8'0"
                      </td>
                    </tr>
                    <tr
                      className="t_utility"
                      style={
                        ["mainImage_1_6"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Utility/Storage
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        7' x 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_bedroom_1"
                      style={
                        ["mainImage_1_8"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Pooja Room
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        1'2" x 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_balcony"
                      style={
                        ["mainImage_1_16"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        {" "}
                        Bedroom 2 Passage
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        3'5" x 3'7"
                      </td>
                    </tr>
                    <tr
                      className="t_passage"
                      style={
                        ["mainImage_1_17"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Bedroom 2 Wardrobe
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        2'10" × 1'18"
                      </td>
                    </tr>
                    <tr
                      className="t_bedroom_2"
                      style={
                        ["mainImage_1_15"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Bedroom 2
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'7"× 11'
                      </td>
                    </tr>
                    <tr
                      className="t_toilet_2"
                      style={
                        ["mainImage_1_18"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        {" "}
                        Toilet 2{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        8' x 5'
                      </td>
                    </tr>
                    <tr
                      className="t_master_passage"
                      style={
                        ["mainImage_1_9"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Passage 1
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5'× 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_bedroom"
                      style={
                        ["mainImage_1_12"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        {" "}
                        Bedroom 3
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11' x 12'
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_1_5"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Common Toilet
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        8' x 4'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_1_2"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Passage
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        3'5" x 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_1_1"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Bedroom
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11' x 12'10"
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_1_3"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Wardrobe
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5' x 2'
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_1_4"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Toilet
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5' x 8'4"
                      </td>
                    </tr>
                  </table>
                )}
                {plan === "second" && unit === "2" && (
                  <table className=" rounded-lg w-full py-2">
                    <tr
                      className="t_foyer"
                      style={
                        ["mainImage_2_11"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Entrance Foyer
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        4'6" x 6'3"
                      </td>
                    </tr>
                    <tr
                      className="t_living "
                      style={
                        ["mainImage_2_17"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Living/Dining{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs"
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'4" x 19'10" <br></br>& 3'8" x 12'2"
                      </td>
                    </tr>
                    <tr
                      className="t_balcony_1"
                      style={
                        ["mainImage_2_16"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Deck
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'2" x 4'7"
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["mainImage_2_10"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Kitchen
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        10'10" x 8'
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["mainImage_2_9"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Dry Balcony
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        3'3" x 8'0"
                      </td>
                    </tr>
                    <tr
                      className="t_utility"
                      style={
                        ["mainImage_2_8"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Utility/Storage
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        7' x 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_bedroom_1"
                      style={
                        ["mainImage_2_11"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Pooja Room
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        1'2" x 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_balcony"
                      style={
                        ["mainImage_2_15"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        {" "}
                        Bedroom 2 Passage
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        3'5" x 3'7"
                      </td>
                    </tr>
                    <tr
                      className="t_passage"
                      style={
                        ["mainImage_2_14"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Bedroom 2 Wardrobe
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        2'10" × 1'18"
                      </td>
                    </tr>
                    <tr
                      className="t_bedroom_2"
                      style={
                        ["mainImage_2_12"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Bedroom 2
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'7"× 11'
                      </td>
                    </tr>
                    <tr
                      className="t_toilet_2"
                      style={
                        ["mainImage_2_13"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        {" "}
                        Toilet 2{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        8' x 5'
                      </td>
                    </tr>
                    <tr
                      className="t_master_passage"
                      style={
                        ["mainImage_2_6"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Passage 1
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5'× 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_bedroom"
                      style={
                        ["mainImage_2_7"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        {" "}
                        Bedroom 3
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11' x 12'
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_2_5"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Common Toilet
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        8' x 4'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_2_2"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Passage
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        3'5" x 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_2_1"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Bedroom
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11' x 12'10"
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_2_4"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Wardrobe
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5' x 2'
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_2_3"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Toilet
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5' x 8'4"
                      </td>
                    </tr>
                  </table>
                )}
                {plan === "second" && unit === "3" && (
                  <table className=" rounded-lg w-full py-2">
                    <tr
                      className="t_living "
                      style={
                        ["mainImage_3_10"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Living/Dining{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs"
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'2" x 19'4" <br></br>& 6'3" x 11'8"
                      </td>
                    </tr>
                    <tr
                      className="t_balcony_1"
                      style={
                        ["mainImage_3_9"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Deck
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'2" x 4'7"
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["mainImage_3_7"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Kitchen
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        10'6" x 8'4"
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["mainImage_3_6"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Dry Balcony
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        3'3" x 7'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_balcony"
                      style={
                        ["mainImage_3_5"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Passage
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5' x 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_bedroom_2"
                      style={
                        ["mainImage_3_8"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Bedroom 2
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        10'× 12'
                      </td>
                    </tr>
                    <tr
                      className="t_toilet_2"
                      style={
                        ["mainImage_3_4"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        {" "}
                        Common Toilet{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5' x 8'
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_3_1"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Bedroom
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11' x 12'
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_3_3"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Wardrobe
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        6'3" x 2'
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_3_2"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Toilet
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5' x 8'
                      </td>
                    </tr>
                  </table>
                )}
                {plan === "refugee" && unit === "1" && (
                  <table className=" rounded-lg w-full py-2">
                    <tr
                      className="t_living "
                      style={
                        ["s_mainImage_1_9"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Entrance Foyer{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs"
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        4'6" x 6'3"
                      </td>
                    </tr>
                    <tr
                      className="t_living "
                      style={
                        ["s_mainImage_1_14"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Living/Dining{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs"
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'4" x 19'10"{" "}
                      </td>
                    </tr>
                    <tr
                      className="t_balcony_1"
                      style={
                        ["s_mainImage_1_13"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Deck
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'2" x 4'7"
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["s_mainImage_1_8"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Study Room
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        6'8" x 8'4"
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["s_mainImage_1_10"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Kitchen
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        10'10" x 8'
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["s_mainImage_1_7"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Dry Balcony
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        3'3" x 8'0"
                      </td>
                    </tr>
                    <tr
                      className="t_master_balcony"
                      style={
                        ["s_mainImage_1_11"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Passage
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        10'0" x 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_bedroom_2"
                      style={
                        ["s_mainImage_1_15"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Bedroom 2
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'× 12'
                      </td>
                    </tr>
                    <tr
                      className="t_toilet_2"
                      style={
                        ["s_mainImage_1_5"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        {" "}
                        Common Toilet{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5'0" x 8'4"
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["s_mainImage_1_2"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Bedroom Passage
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        2'4" x 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["s_mainImage_1_1"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Bedroom
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11' x 12'10"
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["s_mainImage_1_3"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Wardrobe
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5' x 2'
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["s_mainImage_1_4"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Toilet
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5' x 8'4"
                      </td>
                    </tr>
                  </table>
                )}
                {plan === "refugee" && unit === "2" && (
                  <table className=" rounded-lg w-full py-2">
                    <tr
                      className="t_living "
                      style={
                        ["s_mainImage_2_6"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Entrance Foyer{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs"
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        4'6" x 6'3"
                      </td>
                    </tr>
                    <tr
                      className="t_living "
                      style={
                        ["s_mainImage_2_17"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Living/Dining{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs"
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'4" x 19'10"{" "}
                      </td>
                    </tr>
                    <tr
                      className="t_balcony_1"
                      style={
                        ["s_mainImage_2_16"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Deck
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'2" x 4'7"
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["s_mainImage_2_15"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Study Room
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        6'8" x 8'4"
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["s_mainImage_2_10"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Kitchen
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        10'10" x 8'
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["s_mainImage_2_9"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Dry Balcony
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        3'3" x 8'0"
                      </td>
                    </tr>
                    <tr
                      className="t_master_balcony"
                      style={
                        ["s_mainImage_2_11"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Passage
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        10'0" x 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_bedroom_2"
                      style={
                        ["s_mainImage_2_12"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Bedroom 2
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'× 12'
                      </td>
                    </tr>
                    <tr
                      className="t_toilet_2"
                      style={
                        ["s_mainImage_2_13"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        {" "}
                        Common Toilet{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5'0" x 8'4"
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["s_mainImage_2_2"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Bedroom Passage
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        2'4" x 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["s_mainImage_2_1"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Bedroom
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11' x 12'10"
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["s_mainImage_2_4"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Wardrobe
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5' x 2'
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["s_mainImage_2_3"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Toilet
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5' x 8'4"
                      </td>
                    </tr>
                  </table>
                )}
                {plan === "refugee" && unit === "3" && (
                  <table className=" rounded-lg w-full py-2">
                    <tr
                      className="t_living "
                      style={
                        ["mainImage_3_10"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Living/Dining{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs"
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'2" x 19'4" <br></br>& 6'3" x 11'8"
                      </td>
                    </tr>
                    <tr
                      className="t_balcony_1"
                      style={
                        ["mainImage_3_9"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Deck
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'2" x 4'7"
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["mainImage_3_7"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Kitchen
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        10'6" x 8'4"
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["mainImage_3_6"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Dry Balcony
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        3'3" x 7'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_balcony"
                      style={
                        ["mainImage_3_5"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Passage
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5' x 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_bedroom_2"
                      style={
                        ["mainImage_3_8"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Bedroom 2
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        10'× 12'
                      </td>
                    </tr>
                    <tr
                      className="t_toilet_2"
                      style={
                        ["mainImage_3_4"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        {" "}
                        Common Toilet{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5' x 8'
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_3_1"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Bedroom
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11' x 12'
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_3_3"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Wardrobe
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        6'3" x 2'
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_3_2"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Toilet
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5' x 8'
                      </td>
                    </tr>
                  </table>
                )}
                {plan === "refugee" && unit === "4" && (
                  <table className=" rounded-lg w-full py-2">
                    <tr
                      className="t_living "
                      style={
                        ["mainImage_4_11"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Living/Dining{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs"
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'2" x 19'14" <br></br>& 5'5" x 11'10"
                      </td>
                    </tr>
                    <tr
                      className="t_balcony_1"
                      style={
                        ["mainImage_4_10"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Deck
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'2" x 4'7"
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["mainImage_4_9"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Kitchen
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        10'6" x 8'4"
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["mainImage_4_8"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Dry Balcony
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        3'3" x 8'4"
                      </td>
                    </tr>
                    <tr
                      className="t_master_balcony"
                      style={
                        ["mainImage_4_3"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Passage
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        12'2" x 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_passage"
                      style={
                        ["mainImage_4_6"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Bedroom 3
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        10'8" × 12'10"
                      </td>
                    </tr>
                    <tr
                      className="t_bedroom_2"
                      style={
                        ["mainImage_4_7"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Common Toilet
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5'× 8'
                      </td>
                    </tr>
                    <tr
                      className="t_toilet_2"
                      style={
                        ["mainImage_4_5"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        {" "}
                        Bedroom 2{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        10'10" x 12'10"
                      </td>
                    </tr>
                    <tr
                      className="t_master_passage"
                      style={
                        ["mainImage_4_4"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Toilet 2
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5'× 7'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_bedroom"
                      style={
                        ["mainImage_4_1"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        {" "}
                        Master Bedroom
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        12'6" x 11'10"
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_4_2"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Toilet
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        8'8" x 5'
                      </td>
                    </tr>
                  </table>
                )}
              </div>
              <div className="px-2 pt-12 pb-16 xl:hidden lg:hidden block">
                <h1
                  className="font-bold uppercase text-sm font"
                  style={{ color: "#be935f" }}
                >
                  Apartment Features
                </h1>
                <p className="xl:pt-4 pt-2 font font-semibold text-sm">
                  1. Homes have wide sundecks
                </p>
                <p className="xl:pt-2 pt-2 font font-semibold text-sm">
                  2. Internal Flooring
                </p>
                <p className=" font text-sm">
                  Large format Vitrified tiles, anti-skid Vitrified tiles in
                  balcony and utility room
                </p>
                <p className="xl:pt-2 pt-2 font font-semibold text-sm">
                  3. Windows
                </p>
                <p className=" font text-sm">
                  Heavy Duty aluminium Single-glazed windows
                </p>
                <p className="xl:pt-2 pt-2 font font-semibold text-sm">
                  4. Doors
                </p>
                <p className=" font text-sm">
                  Main entrance door in veneer with melamine finish & laminate
                  on all internal doors
                </p>
              </div>
            </div>

            <div className="w-full xl:w-8/12 lg:w-8/12 md:w-12/12 px-6 xl:pt-0 lg:pt-0 md:pt-0 pt-6 pb-2">
              {/* <img src={unita} usemap="#image-map" ref={imgRef} style={{ maxWidth: '100%', height: 'auto', width: '100%' }}/> */}
              {plan === "typical" && (
                <>
                  {unit === "1" && (
                    <>
                      <img
                        src={unit1Typical}
                        useMap="#image-map"
                        ref={imgRef}
                        style={{
                          maxWidth: "100%",
                          height: "auto",
                          width: "100%",
                        }}
                      />
                      <map name="image-map">
                        <area
                          target=""
                          alt="master bedroom"
                          data-title={"Master Bedroom\n 11' x 12'10\""}
                          href=""
                          data-coords="215,139,228,164,271,183,322,204,341,177,353,182,395,109,390,79,278,36"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_1"
                        />
                        <area
                          target=""
                          alt="master passage"
                          data-title={"Master Passage\n 3'5\" x 3'5\""}
                          href=""
                          data-coords="324,204,342,176,375,190,359,220"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_2"
                        />
                        <area
                          target=""
                          alt="master wardrobe"
                          data-title={"Master Wardrobe\n 5' x 2'"}
                          href=""
                          data-coords="265,200,275,185,323,204,310,222"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_3"
                        />
                        <area
                          target=""
                          alt="master toilet"
                          data-title={"Master Toilet\n 5' x 8'4\""}
                          href=""
                          data-coords="173,234,159,213,177,185,184,188,214,140,227,166,271,185,227,259"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_4"
                        />
                        <area
                          target=""
                          alt="common Toilet"
                          data-title={"Common Toilet\n 8' x 4'5\""}
                          href=""
                          data-coords="303,249,315,229,323,234,334,214,404,244,383,284"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_5"
                        />
                        <area
                          target=""
                          alt="utility/storage"
                          data-title={"Utility/Storage\n 7' x 3'5\""}
                          href=""
                          data-coords="287,286,304,254,371,283,353,317"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_6"
                        />
                        <area
                          target=""
                          alt="dry balcony"
                          data-title={"Dry Balcony\n 3'3\" x 8'0\""}
                          href=""
                          data-coords="287,384,328,307,356,320,315,398"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_7"
                        />
                        <area
                          target=""
                          alt="pooja room"
                          data-title={"Pooja Room\n 1'2\" x 3'5\""}
                          href=""
                          data-coords="373,285,385,291,369,323,357,317"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_8"
                        />
                        <area
                          target=""
                          alt="passage 1"
                          data-title={"Passage 1\n 5' x 3'5\""}
                          href=""
                          data-coords="360,223,376,191,422,212,407,243"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_9"
                        />
                        <area
                          target=""
                          alt="kitchen"
                          data-title={"Kitchen\n 10'10\" x 8'"}
                          href=""
                          data-coords="319,398,360,323,465,372,427,452"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_10"
                        />
                        <area
                          target=""
                          alt="passage 2"
                          data-title={"Entrance Foyer\n 4'6\" x 6'3\""}
                          href=""
                          data-coords="440,434,469,374,516,395,489,457"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_11"
                        />
                        <area
                          target=""
                          alt="bedroom 3"
                          data-title={"Bedroom 3\n 11' x 12'"}
                          href=""
                          data-coords="391,78,405,57,510,100,492,133,489,159,458,224,355,180,395,107"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_12"
                        />
                        <area
                          target=""
                          alt="deck"
                          data-title={"Deck\n 11'2\" x 4'7\""}
                          href=""
                          data-coords="518,100,628,142,611,182,500,137"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_13"
                        />
                        <area
                          target=""
                          alt="Living / Dining"
                          data-title={
                            "Living / Dining\n 11'4\" x 19'10\" & 3'8\" x 12'2\""
                          }
                          href=""
                          data-coords="371,323,424,213,461,225,492,160,495,137,607,183,516,393"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_14"
                        />
                        <area
                          target=""
                          alt="Bedroom 2"
                          data-title={"Bedroom 2\n 11'7\" x 11'"}
                          href=""
                          data-coords="620,192,736,240,685,358,569,309"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_15"
                        />
                        <area
                          target=""
                          alt="Bedroom 2 Passage"
                          data-title={"Bedroom 2 Passage\n 3'5\" x 3'7\""}
                          href=""
                          data-coords="558,306,603,326,589,355,544,336"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_16"
                        />
                        <area
                          target=""
                          alt="Bedroom 2 Wardrobe"
                          data-title={"Bedroom 2 Wardrobe\n 2'10\" x 1'18\""}
                          href=""
                          data-coords="552,356,557,342,587,355,582,371"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_17"
                        />
                        <area
                          target=""
                          alt="Bedroom 2 Toilet"
                          data-title={"Toilet 2 \n 8' x 5'"}
                          href=""
                          data-coords="584,373,605,325,688,361,665,410"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_18"
                        />
                      </map>
                    </>
                  )}
                  {unit === "2" && (
                    <>
                      <img
                        src={unit2Typical}
                        useMap="#image-map"
                        ref={imgRef}
                        style={{
                          maxWidth: "100%",
                          height: "auto",
                          width: "100%",
                        }}
                      />
                      <map name="image-map">
                        <area
                          target=""
                          alt="master bedroom"
                          data-title={"Master Bedroom\n 11' x 12'10\""}
                          href=""
                          data-coords="149,216,164,224,157,232,171,257,262,292,324,197,233,165,225,125"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_1"
                        />
                        <area
                          target=""
                          alt="master passage"
                          data-title={"Master Passage\n 3'5\" x 3'5\""}
                          href=""
                          data-coords="309,220,325,197,360,211,346,234"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_2"
                        />
                        <area
                          target=""
                          alt="master toilet"
                          data-title={"Master Toilet\n 5' x 8'4\""}
                          href=""
                          data-coords="225,123,251,90,245,87,260,68,313,83,306,87,309,105,301,116,304,136,272,177,234,165"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_3"
                        />
                        <area
                          target=""
                          alt="master wardrobe"
                          data-title={"Master Wardrobe\n 5' x 2'"}
                          href=""
                          data-coords="274,180,285,165,332,180,324,196"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_4"
                        />
                        <area
                          target=""
                          alt="common bathroom"
                          data-title={"Common Toilet\n 8' x 4'5\""}
                          href=""
                          data-coords="340,199,348,184,340,180,351,163,428,190,408,223"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_5"
                        />
                        <area
                          target=""
                          alt="passage 1"
                          data-title={"Passage 1\n 5' x 3'5\""}
                          href=""
                          data-coords="408,226,394,253,346,236,360,209"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_6"
                        />
                        <area
                          target=""
                          alt="bedroom 3"
                          data-title={"Bedroom 3\n 11' x 12'"}
                          href=""
                          data-coords="255,323,318,230,424,268,394,318,392,302,371,337,370,360,363,367"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_7"
                        />
                        <area
                          target=""
                          alt="utility / storage"
                          data-title={"Utility/Storage\n 7' x 3'5\""}
                          href=""
                          data-coords="417,184,356,162,370,136,435,158"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_8"
                        />
                        <area
                          target=""
                          alt="dry balcony"
                          data-title={"Dry Balcony\n 3'3\" x 8'0\""}
                          href=""
                          data-coords="444,60,443,102,413,147,440,159,451,144,450,120,466,97,463,82,472,68"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_9"
                        />
                        <area
                          target=""
                          alt="kitchen"
                          data-title={"Kitchen\n 10'10\" x 8'"}
                          href=""
                          data-coords="442,157,545,193,554,178,554,213,562,189,569,121,580,101,478,70,468,82,466,97,451,119"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_10"
                        />
                        <area
                          target=""
                          alt="passage 2"
                          data-title={"Entrance Foyer\n 4'6\" x 6'3\""}
                          href=""
                          data-coords="547,197,565,170,569,123,577,116,621,131,581,212,573,236,543,224"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_11"
                        />
                        <area
                          target=""
                          alt="bedroom 2"
                          data-title={"Bedroom 2\n 11'7\" x 11'"}
                          href=""
                          data-coords="603,303,570,289,526,381,538,387,533,395,648,437,690,333"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_12"
                        />
                        <area
                          target=""
                          alt="bedroom 2 toilet"
                          data-title={"Toilet 2 \n 8' x 5'"}
                          href=""
                          data-coords="610,299,621,275,627,242,634,224,721,253,688,329"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_13"
                        />
                        <area
                          target=""
                          alt="bedroom 2 wardrobe"
                          data-title={"Bedroom 2 Wardrobe\n 2'10\" x 1'18\""}
                          href=""
                          data-coords="615,284,621,269,579,253,570,266"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_14"
                        />
                        <area
                          target=""
                          alt="bedroom 2 passage"
                          data-title={"Bedroom 2 Passage\n 3'5\" x 3'7\""}
                          href=""
                          data-coords="568,290,573,284,561,279,569,267,614,284,606,303"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_15"
                        />
                        <area
                          target=""
                          alt="deck"
                          data-title={"Deck\n 11'2\" x 4'7\""}
                          href=""
                          data-coords="373,382,370,338,392,302,394,334,510,378,486,428"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_16"
                        />
                        <area
                          target=""
                          alt="Living / Dining"
                          data-title={
                            "Living / Dining\n 11'4\" x 19'10\" & 3'8\" x 12'2\""
                          }
                          href=""
                          data-coords="511,373,396,330,394,321,428,265,411,258,409,229,450,164,545,196,542,226,573,237,572,260"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_17"
                        />
                      </map>
                    </>
                  )}
                  {unit === "3" && (
                    <>
                      <img
                        src={unit3Typical}
                        useMap="#image-map"
                        ref={imgRef}
                        style={{
                          maxWidth: "100%",
                          height: "auto",
                          width: "100%",
                        }}
                      />
                      <map name="image-map">
                        <area
                          target=""
                          alt="master bedroom"
                          data-title={"Master Bedroom\n 11' x 12'"}
                          href=""
                          data-coords="245,288,257,315,267,304,367,380,448,289,349,221,353,216,344,187"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_1"
                        />
                        <area
                          target=""
                          alt="master toilet"
                          data-title={"Master Toilet\n 5' x 8'"}
                          href=""
                          data-coords="362,390,400,347,473,401,438,448"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_2"
                        />
                        <area
                          target=""
                          alt="master wardrobe"
                          data-title={"Master Wardrobe\n 6'3\" x 2'"}
                          href=""
                          data-coords="401,343,445,292,463,304,418,355"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_3"
                        />
                        <area
                          target=""
                          alt="common toilet"
                          data-title={"Common Toilet\n 5' x 8'"}
                          href=""
                          data-coords="454,292,487,253,556,298,524,340"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_4"
                        />
                        <area
                          target=""
                          alt="master passage"
                          data-title={"Passage\n 5' x 3'5\""}
                          href=""
                          data-coords="421,267,455,228,485,247,449,287"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_5"
                        />
                        <area
                          target=""
                          alt="dry balcony"
                          data-title={"Dry Balcony\n 3'3\" x 7'5\""}
                          href=""
                          data-coords="508,385,533,353,602,401,578,435"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_6"
                        />
                        <area
                          target=""
                          alt="kitchen"
                          data-title={"Kitchen\n 10'6\"  x 8'4\""}
                          href=""
                          data-coords="535,347,569,302,563,297,594,256,676,308,611,401"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_7"
                        />
                        <area
                          target=""
                          alt="bedroom 2"
                          data-title={"Bedroom 2\n 10' x 12'"}
                          href=""
                          data-coords="343,185,315,169,388,97,420,118,423,150,485,190,419,266,355,220"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_8"
                        />
                        <area
                          target=""
                          alt="deck"
                          data-title={"Deck\n 11'2\" x 4'7\""}
                          href=""
                          data-coords="391,85,426,109,495,38,462,17"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_9"
                        />
                        <area
                          target=""
                          alt="living / dining"
                          data-title={
                            "Living/Dining \n 11'2\" x 19'4\" & 6'3\" x 11'8\""
                          }
                          href=""
                          data-coords="455,227,494,183,434,143,425,150,423,118,502,37,672,137,660,168,559,297"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_10"
                        />
                      </map>
                    </>
                  )}
                  {unit === "4" && (
                    <>
                      <img
                        src={unit4Typical}
                        useMap="#image-map"
                        ref={imgRef}
                        style={{
                          maxWidth: "100%",
                          height: "auto",
                          width: "100%",
                        }}
                      />
                      <map name="image-map">
                        <area
                          target=""
                          alt="master bedroom"
                          data-title={"Master Bedroom\n 12'6\" x 11'10\""}
                          href=""
                          data-coords="329,362,260,301,244,280,324,200,336,221,411,279"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_1"
                        />
                        <area
                          target=""
                          alt="master toilet"
                          data-title={"Master Toilet\n 8'8\" x 5'"}
                          href=""
                          data-coords="348,412,317,382,373,326,404,352"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_2"
                        />
                        <area
                          target=""
                          alt="master passage"
                          data-title={" Passage\n 12'2\" x 3'5\""}
                          href=""
                          data-coords="389,260,457,189,480,206,413,280"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_3"
                        />
                        <area
                          target=""
                          alt="bedroom 2 bathroom"
                          data-title={"Toilet 2\n 5' x 7'5\""}
                          href=""
                          data-coords="377,389,408,355,458,398,428,435"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_4"
                        />
                        <area
                          target=""
                          alt="bedroom 2"
                          data-title={" Bedroom 2 \n 10'10\" x 12'10\""}
                          href=""
                          data-coords="374,321,440,252,531,320,463,395"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_5"
                        />
                        <area
                          target=""
                          alt="bedroom 3"
                          data-title={" Bedroom 3 \n 10'8\" x 12'10\""}
                          href=""
                          data-coords="447,245,508,179,596,241,534,310"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_6"
                        />
                        <area
                          target=""
                          alt="common toilet"
                          data-title={"Common Toilet\n 5' x 8'"}
                          href=""
                          data-coords="378,180,407,151,455,187,426,218"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_7"
                        />
                        <area
                          target=""
                          alt="dry balcony"
                          data-title={"Dry Balcony \n 3'3\" x 8'4\""}
                          href=""
                          data-coords="340,212,323,193,320,196,301,181,281,151,304,132,314,140,328,165,362,192"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_8"
                        />
                        <area
                          target=""
                          alt="kitchen"
                          data-title={"Kitchen \n 10'6\" x 8'4\""}
                          href=""
                          data-coords="316,138,304,131,366,70,420,105,432,151,424,162,407,150,363,191,328,162"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_9"
                        />
                        <area
                          target=""
                          alt="deck"
                          data-title={"Deck\n 11'2\" x 4'7\""}
                          href=""
                          data-coords="569,206,619,147,622,122,627,115,661,136,659,170,604,231"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_10"
                        />
                        <area
                          target=""
                          alt="living / dining"
                          data-title={
                            "Living/Dining\n 11'2\" x 19'14\" & 5'5\" x 11'10\""
                          }
                          href=""
                          data-coords="566,206,512,168,480,203,426,162,434,151,421,105,493,31,626,113,621,122,619,147"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_11"
                        />
                      </map>
                    </>
                  )}
                </>
              )}
              {plan === "refugee" && (
                <>
                  {unit === "1" && (
                    <>
                      {/* Change Unit 1 Refugee Image here */}
                      <img
                        src={unit1Refugee}
                        useMap="#image-map"
                        ref={imgRef}
                        style={{
                          maxWidth: "100%",
                          height: "auto",
                          width: "100%",
                        }}
                      />
                      <map name="image-map">
                        <area
                          target=""
                          alt="master bedroom"
                          data-title={"Master Bedroom\n 11' x 12'10\""}
                          href=""
                          data-coords="480,171,561,122,568,85,656,171,649,181,639,206,565,263"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_1_1"
                        />
                        <area
                          target=""
                          alt="master passage"
                          data-title={"Master Bedroom Passage\n 2'4\" x 3'5\""}
                          href=""
                          data-coords="482,211,505,199,480,171,457,188"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_1_2"
                        />
                        <area
                          target=""
                          alt="master wardrobe"
                          data-title={"Master Wardrobe\n 5' x 2'"}
                          href=""
                          data-coords="480,167,469,158,508,130,517,142"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_1_3"
                        />
                        <area
                          target=""
                          alt="master toilet"
                          data-title={"Master Toilet\n 5' x 8'4\""}
                          href=""
                          data-coords="521,136,557,114,564,83,529,48,534,42,516,24,476,51,475,109,491,134,507,125"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_1_4"
                        />
                        <area
                          target=""
                          alt="common bathroom"
                          data-title={"Common Toilet\n 5'0\" x 8'4\""}
                          href=""
                          data-coords="430,198,465,176,452,163,459,155,446,145,443,112,419,87,378,110,379,132,382,144"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_1_5"
                        />
                        <area
                          target=""
                          alt="dry balcony"
                          data-title={"Dry Balcony\n 3'3\" x 8'0\""}
                          href=""
                          data-coords="360,154,313,101,336,87,382,143"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_1_7"
                        />
                        <area
                          target=""
                          alt="pooja room"
                          data-title={"Study Room\n 6'8\" x 8'4\""}
                          href=""
                          data-coords="379,235,329,175,375,150,424,204"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_1_8"
                        />
                        <area
                          target=""
                          alt="passage 1"
                          data-title={"Entrance foyer\n 4'6\" x 6'3\""}
                          href=""
                          data-coords="218,246,258,217,225,178,184,200"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_1_9"
                        />
                        <area
                          target=""
                          alt="kitchen"
                          data-title={"Kitchen\n 10'10\" x 8'"}
                          href=""
                          data-coords="260,215,354,156,308,104,220,159"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_1_10"
                        />
                        <area
                          target=""
                          alt="passage 2"
                          data-title={"Passage\n 10'0\" x 3'5\""}
                          href=""
                          data-coords="376,244,457,190,477,214,400,270"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_1_11"
                        />
                        <area
                          target=""
                          alt="deck"
                          data-title={"Deck\n 11'2\" x 4'7\""}
                          href=""
                          data-coords="348,411,452,342,480,375,383,451"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_1_13"
                        />
                        <area
                          target=""
                          alt="living / dining"
                          data-title={"Living/Dining\n 11'4\" x 19'10\""}
                          href=""
                          data-coords="348,407,219,247,260,218,326,177,376,234,371,242,450,335"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_1_14"
                        />
                        <area
                          target=""
                          alt="bedroom 2"
                          data-title={"Bedroom 2\n 11' x 12'"}
                          href=""
                          data-coords="488,364,580,300,495,210,403,270"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_1_15"
                        />
                      </map>
                    </>
                  )}
                  {unit === "2" && (
                    <>
                      {/* Change Unit 2 Refugee Image here */}
                      <img
                        src={unit2Refugee}
                        useMap="#image-map"
                        ref={imgRef}
                        style={{
                          maxWidth: "100%",
                          height: "auto",
                          width: "100%",
                        }}
                      />
                      <map name="image-map">
                        <area
                          target=""
                          alt="master bedroom"
                          data-title={"Master Bedroom\n 11' x 12'10\""}
                          href=""
                          data-coords="307,291,217,266,201,231,192,221,258,126,269,162,314,178,364,195"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_2_1"
                        />
                        <area
                          target=""
                          alt="master passage"
                          data-title={"Master Bedroom Passage\n 2'4\" x 3'5\""}
                          href=""
                          data-coords="354,218,364,194,388,204,375,229"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_2_2"
                        />
                        <area
                          target=""
                          alt="master toilet"
                          data-title={"Master Toilet\n 5' x 8'4\""}
                          href=""
                          data-coords="312,174,338,131,347,100,344,82,296,60,282,82,260,118,272,158"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_2_3"
                        />
                        <area
                          target=""
                          alt="master wardrobe"
                          data-title={"Master Wardrobe\n 5' x 2'"}
                          href=""
                          data-coords="359,192,315,180,324,164,368,179"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_2_4"
                        />
                        <area
                          target=""
                          alt="passage 1"
                          data-title={"Entrance foyer\n 4'6\" x 6'3\""}
                          href=""
                          data-coords="589,191,617,111,663,125,634,204"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_2_6"
                        />
                        <area
                          target=""
                          alt="dry balcony"
                          data-title={"Dry Balcony\n 3'3\" x 8'0\""}
                          href=""
                          data-coords="475,153,452,143,477,98,489,103,504,109,498,127,489,143"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_2_9"
                        />
                        <area
                          target=""
                          alt="kitchen"
                          data-title={"Kitchen\n 10'10\" x 8'"}
                          href=""
                          data-coords="483,151,501,120,506,77,512,64,620,96,612,116,588,185"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_2_10"
                        />
                        <area
                          target=""
                          alt="passage 2"
                          data-title={"Passage\n 10'0\" x 3'5\""}
                          href=""
                          data-coords="472,266,488,236,394,204,378,231"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_2_11"
                        />
                        <area
                          target=""
                          alt="bedroom 2"
                          data-title={"Bedroom 2\n 11' x 12'"}
                          href=""
                          data-coords="416,376,470,266,357,226,304,327"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_2_12"
                        />
                        <area
                          target=""
                          alt="bedroom 2 toilet"
                          data-title={"Common Toilet\n 5'0\" x 8'4\""}
                          href=""
                          data-coords="420,208,456,148,410,130,383,176,388,184,384,196"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_2_13"
                        />
                        <area
                          target=""
                          alt="bedroom 2 passage"
                          data-title={"Study Room\n 6'8\" x 8'4\""}
                          href=""
                          data-coords="488,233,517,172,458,154,424,210"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_2_15"
                        />
                        <area
                          target=""
                          alt="deck"
                          data-title={"Deck\n 11'2\" x 4'7\""}
                          href=""
                          data-coords="558,379,445,337,421,387,542,439"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_2_16"
                        />
                        <area
                          target=""
                          alt="living / dining"
                          data-title={"Living/Dining\n 11'4\" x 19'10\""}
                          href=""
                          data-coords="444,333,520,173,587,191,632,205,564,379"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_2_17"
                        />
                      </map>
                    </>
                  )}
                  {unit === "3" && (
                    <>
                      <img
                        src={unit3Typical}
                        useMap="#image-map"
                        ref={imgRef}
                        style={{
                          maxWidth: "100%",
                          height: "auto",
                          width: "100%",
                        }}
                      />
                      <map name="image-map">
                        <area
                          target=""
                          alt="master bedroom"
                          data-title={"Master Bedroom\n 11' x 12'"}
                          href=""
                          data-coords="245,288,257,315,267,304,367,380,448,289,349,221,353,216,344,187"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_1"
                        />
                        <area
                          target=""
                          alt="master toilet"
                          data-title={"Master Toilet\n 5' x 8'"}
                          href=""
                          data-coords="362,390,400,347,473,401,438,448"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_2"
                        />
                        <area
                          target=""
                          alt="master wardrobe"
                          data-title={"Master Wardrobe\n 6'3\" x 2'"}
                          href=""
                          data-coords="401,343,445,292,463,304,418,355"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_3"
                        />
                        <area
                          target=""
                          alt="common toilet"
                          data-title={"Common Toilet\n 5' x 8'"}
                          href=""
                          data-coords="454,292,487,253,556,298,524,340"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_4"
                        />
                        <area
                          target=""
                          alt="master passage"
                          data-title={"Passage\n 5' x 3'5\""}
                          href=""
                          data-coords="421,267,455,228,485,247,449,287"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_5"
                        />
                        <area
                          target=""
                          alt="dry balcony"
                          data-title={"Dry Balcony\n 3'3\" x 7'5\""}
                          href=""
                          data-coords="508,385,533,353,602,401,578,435"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_6"
                        />
                        <area
                          target=""
                          alt="kitchen"
                          data-title={"Kitchen\n 10'6\"  x 8'4\""}
                          href=""
                          data-coords="535,347,569,302,563,297,594,256,676,308,611,401"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_7"
                        />
                        <area
                          target=""
                          alt="bedroom 2"
                          data-title={"Bedroom 2\n 10' x 12'"}
                          href=""
                          data-coords="343,185,315,169,388,97,420,118,423,150,485,190,419,266,355,220"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_8"
                        />
                        <area
                          target=""
                          alt="deck"
                          data-title={"Deck\n 11'2\" x 4'7\""}
                          href=""
                          data-coords="391,85,426,109,495,38,462,17"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_9"
                        />
                        <area
                          target=""
                          alt="living / dining"
                          data-title={
                            "Living/Dining \n 11'2\" x 19'4\" & 6'3\" x 11'8\""
                          }
                          href=""
                          data-coords="455,227,494,183,434,143,425,150,423,118,502,37,672,137,660,168,559,297"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_10"
                        />
                      </map>
                    </>
                  )}
                  {unit === "4" && (
                    <>
                      <img
                        src={unit4Typical}
                        useMap="#image-map"
                        ref={imgRef}
                        style={{
                          maxWidth: "100%",
                          height: "auto",
                          width: "100%",
                        }}
                      />
                      <map name="image-map">
                        <area
                          target=""
                          alt="master bedroom"
                          data-title={"Master Bedroom\n 12'6\" x 11'10\""}
                          href=""
                          data-coords="329,362,260,301,244,280,324,200,336,221,411,279"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_1"
                        />
                        <area
                          target=""
                          alt="master toilet"
                          data-title={"Master Toilet\n 8'8\" x 5'"}
                          href=""
                          data-coords="348,412,317,382,373,326,404,352"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_2"
                        />
                        <area
                          target=""
                          alt="master passage"
                          data-title={" Passage\n 12'2\" x 3'5\""}
                          href=""
                          data-coords="389,260,457,189,480,206,413,280"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_3"
                        />
                        <area
                          target=""
                          alt="bedroom 2 bathroom"
                          data-title={"Toilet 2\n 5' x 7'5\""}
                          href=""
                          data-coords="377,389,408,355,458,398,428,435"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_4"
                        />
                        <area
                          target=""
                          alt="bedroom 2"
                          data-title={" Bedroom 2 \n 10'10\" x 12'10\""}
                          href=""
                          data-coords="374,321,440,252,531,320,463,395"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_5"
                        />
                        <area
                          target=""
                          alt="bedroom 3"
                          data-title={" Bedroom 3 \n 10'8\" x 12'10\""}
                          href=""
                          data-coords="447,245,508,179,596,241,534,310"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_6"
                        />
                        <area
                          target=""
                          alt="common toilet"
                          data-title={"Common Toilet\n 5' x 8'"}
                          href=""
                          data-coords="378,180,407,151,455,187,426,218"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_7"
                        />
                        <area
                          target=""
                          alt="dry balcony"
                          data-title={"Dry Balcony \n 3'3\" x 8'4\""}
                          href=""
                          data-coords="340,212,323,193,320,196,301,181,281,151,304,132,314,140,328,165,362,192"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_8"
                        />
                        <area
                          target=""
                          alt="kitchen"
                          data-title={"Kitchen \n 10'6\" x 8'4\""}
                          href=""
                          data-coords="316,138,304,131,366,70,420,105,432,151,424,162,407,150,363,191,328,162"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_9"
                        />
                        <area
                          target=""
                          alt="deck"
                          data-title={"Deck\n 11'2\" x 4'7\""}
                          href=""
                          data-coords="569,206,619,147,622,122,627,115,661,136,659,170,604,231"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_10"
                        />
                        <area
                          target=""
                          alt="living / dining"
                          data-title={
                            "Living/Dining\n 11'2\" x 19'14\" & 5'5\" x 11'10\""
                          }
                          href=""
                          data-coords="566,206,512,168,480,203,426,162,434,151,421,105,493,31,626,113,621,122,619,147"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_11"
                        />
                      </map>
                    </>
                  )}
                </>
              )}
              {plan === "second" && (
                <>
                  {unit === "1" && (
                    <>
                      <img
                        src={unit1Typical}
                        useMap="#image-map"
                        ref={imgRef}
                        style={{
                          maxWidth: "100%",
                          height: "auto",
                          width: "100%",
                        }}
                      />
                      <map name="image-map">
                        <area
                          target=""
                          alt="master bedroom"
                          data-title={"Master Bedroom\n 11' x 12'10\""}
                          href=""
                          data-coords="215,139,228,164,271,183,322,204,341,177,353,182,395,109,390,79,278,36"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_1"
                        />
                        <area
                          target=""
                          alt="master passage"
                          data-title={"Master Passage\n 3'5\" x 3'5\""}
                          href=""
                          data-coords="324,204,342,176,375,190,359,220"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_2"
                        />
                        <area
                          target=""
                          alt="master wardrobe"
                          data-title={"Master Wardrobe\n 5' x 2'"}
                          href=""
                          data-coords="265,200,275,185,323,204,310,222"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_3"
                        />
                        <area
                          target=""
                          alt="master toilet"
                          data-title={"Master Toilet\n 5' x 8'4\""}
                          href=""
                          data-coords="173,234,159,213,177,185,184,188,214,140,227,166,271,185,227,259"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_4"
                        />
                        <area
                          target=""
                          alt="common Toilet"
                          data-title={"Common Toilet\n 8' x 4'5\""}
                          href=""
                          data-coords="303,249,315,229,323,234,334,214,404,244,383,284"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_5"
                        />
                        <area
                          target=""
                          alt="utility/storage"
                          data-title={"Utility/Storage\n 7' x 3'5\""}
                          href=""
                          data-coords="287,286,304,254,371,283,353,317"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_6"
                        />
                        <area
                          target=""
                          alt="dry balcony"
                          data-title={"Dry Balcony\n 3'3\" x 8'0\""}
                          href=""
                          data-coords="287,384,328,307,356,320,315,398"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_7"
                        />
                        <area
                          target=""
                          alt="pooja room"
                          data-title={"Pooja Room\n 1'2\" x 3'5\""}
                          href=""
                          data-coords="373,285,385,291,369,323,357,317"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_8"
                        />
                        <area
                          target=""
                          alt="passage 1"
                          data-title={"Passage 1\n 5' x 3'5\""}
                          href=""
                          data-coords="360,223,376,191,422,212,407,243"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_9"
                        />
                        <area
                          target=""
                          alt="kitchen"
                          data-title={"Kitchen\n 10'10\" x 8'"}
                          href=""
                          data-coords="319,398,360,323,465,372,427,452"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_10"
                        />
                        <area
                          target=""
                          alt="passage 2"
                          data-title={"Entrance Foyer\n 4'6\" x 6'3\""}
                          href=""
                          data-coords="440,434,469,374,516,395,489,457"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_11"
                        />
                        <area
                          target=""
                          alt="bedroom 3"
                          data-title={"Bedroom 3\n 11' x 12'"}
                          href=""
                          data-coords="391,78,405,57,510,100,492,133,489,159,458,224,355,180,395,107"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_12"
                        />
                        <area
                          target=""
                          alt="deck"
                          data-title={"Deck\n 11'2\" x 4'7\""}
                          href=""
                          data-coords="518,100,628,142,611,182,500,137"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_13"
                        />
                        <area
                          target=""
                          alt="Living / Dining"
                          data-title={
                            "Living / Dining\n 11'4\" x 19'10\" & 3'8\" x 12'2\""
                          }
                          href=""
                          data-coords="371,323,424,213,461,225,492,160,495,137,607,183,516,393"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_14"
                        />
                        <area
                          target=""
                          alt="Bedroom 2"
                          data-title={"Bedroom 2\n 11'7\" x 11'"}
                          href=""
                          data-coords="620,192,736,240,685,358,569,309"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_15"
                        />
                        <area
                          target=""
                          alt="Bedroom 2 Passage"
                          data-title={"Bedroom 2 Passage\n 3'5\" x 3'7\""}
                          href=""
                          data-coords="558,306,603,326,589,355,544,336"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_16"
                        />
                        <area
                          target=""
                          alt="Bedroom 2 Wardrobe"
                          data-title={"Bedroom 2 Wardrobe\n 2'10\" x 1'18\""}
                          href=""
                          data-coords="552,356,557,342,587,355,582,371"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_17"
                        />
                        <area
                          target=""
                          alt="Bedroom 2 Toilet"
                          data-title={"Toilet 2 \n 8' x 5'"}
                          href=""
                          data-coords="584,373,605,325,688,361,665,410"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_18"
                        />
                      </map>
                    </>
                  )}
                  {unit === "2" && (
                    <>
                      <img
                        src={unit2Typical}
                        useMap="#image-map"
                        ref={imgRef}
                        style={{
                          maxWidth: "100%",
                          height: "auto",
                          width: "100%",
                        }}
                      />
                      <map name="image-map">
                        <area
                          target=""
                          alt="master bedroom"
                          data-title={"Master Bedroom\n 11' x 12'10\""}
                          href=""
                          data-coords="149,216,164,224,157,232,171,257,262,292,324,197,233,165,225,125"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_1"
                        />
                        <area
                          target=""
                          alt="master passage"
                          data-title={"Master Passage\n 3'5\" x 3'5\""}
                          href=""
                          data-coords="309,220,325,197,360,211,346,234"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_2"
                        />
                        <area
                          target=""
                          alt="master toilet"
                          data-title={"Master Toilet\n 5' x 8'4\""}
                          href=""
                          data-coords="225,123,251,90,245,87,260,68,313,83,306,87,309,105,301,116,304,136,272,177,234,165"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_3"
                        />
                        <area
                          target=""
                          alt="master wardrobe"
                          data-title={"Master Wardrobe\n 5' x 2'"}
                          href=""
                          data-coords="274,180,285,165,332,180,324,196"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_4"
                        />
                        <area
                          target=""
                          alt="common bathroom"
                          data-title={"Common Toilet\n 8' x 4'5\""}
                          href=""
                          data-coords="340,199,348,184,340,180,351,163,428,190,408,223"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_5"
                        />
                        <area
                          target=""
                          alt="passage 1"
                          data-title={"Passage 1\n 5' x 3'5\""}
                          href=""
                          data-coords="408,226,394,253,346,236,360,209"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_6"
                        />
                        <area
                          target=""
                          alt="bedroom 3"
                          data-title={"Bedroom 3\n 11' x 12'"}
                          href=""
                          data-coords="255,323,318,230,424,268,394,318,392,302,371,337,370,360,363,367"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_7"
                        />
                        <area
                          target=""
                          alt="utility / storage"
                          data-title={"Utility/Storage\n 7' x 3'5\""}
                          href=""
                          data-coords="417,184,356,162,370,136,435,158"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_8"
                        />
                        <area
                          target=""
                          alt="dry balcony"
                          data-title={"Dry Balcony\n 3'3\" x 8'0\""}
                          href=""
                          data-coords="444,60,443,102,413,147,440,159,451,144,450,120,466,97,463,82,472,68"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_9"
                        />
                        <area
                          target=""
                          alt="kitchen"
                          data-title={"Kitchen\n 10'10\" x 8'"}
                          href=""
                          data-coords="442,157,545,193,554,178,554,213,562,189,569,121,580,101,478,70,468,82,466,97,451,119"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_10"
                        />
                        <area
                          target=""
                          alt="passage 2"
                          data-title={"Entrance Foyer\n 4'6\" x 6'3\""}
                          href=""
                          data-coords="547,197,565,170,569,123,577,116,621,131,581,212,573,236,543,224"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_11"
                        />
                        <area
                          target=""
                          alt="bedroom 2"
                          data-title={"Bedroom 2\n 11'7\" x 11'"}
                          href=""
                          data-coords="603,303,570,289,526,381,538,387,533,395,648,437,690,333"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_12"
                        />
                        <area
                          target=""
                          alt="bedroom 2 toilet"
                          data-title={"Toilet 2 \n 8' x 5'"}
                          href=""
                          data-coords="610,299,621,275,627,242,634,224,721,253,688,329"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_13"
                        />
                        <area
                          target=""
                          alt="bedroom 2 wardrobe"
                          data-title={"Bedroom 2 Wardrobe\n 2'10\" x 1'18\""}
                          href=""
                          data-coords="615,284,621,269,579,253,570,266"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_14"
                        />
                        <area
                          target=""
                          alt="bedroom 2 passage"
                          data-title={"Bedroom 2 Passage\n 3'5\" x 3'7\""}
                          href=""
                          data-coords="568,290,573,284,561,279,569,267,614,284,606,303"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_15"
                        />
                        <area
                          target=""
                          alt="deck"
                          data-title={"Deck\n 11'2\" x 4'7\""}
                          href=""
                          data-coords="373,382,370,338,392,302,394,334,510,378,486,428"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_16"
                        />
                        <area
                          target=""
                          alt="Living / Dining"
                          data-title={
                            "Living / Dining\n 11'4\" x 19'10\" & 3'8\" x 12'2\""
                          }
                          href=""
                          data-coords="511,373,396,330,394,321,428,265,411,258,409,229,450,164,545,196,542,226,573,237,572,260"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_17"
                        />
                      </map>
                    </>
                  )}
                  {unit === "3" && (
                    <>
                      <img
                        src={unit3Typical}
                        useMap="#image-map"
                        ref={imgRef}
                        style={{
                          maxWidth: "100%",
                          height: "auto",
                          width: "100%",
                        }}
                      />
                      <map name="image-map">
                        <area
                          target=""
                          alt="master bedroom"
                          data-title={"Master Bedroom\n 11' x 12'"}
                          href=""
                          data-coords="245,288,257,315,267,304,367,380,448,289,349,221,353,216,344,187"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_1"
                        />
                        <area
                          target=""
                          alt="master toilet"
                          data-title={"Master Toilet\n 5' x 8'"}
                          href=""
                          data-coords="362,390,400,347,473,401,438,448"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_2"
                        />
                        <area
                          target=""
                          alt="master wardrobe"
                          data-title={"Master Wardrobe\n 6'3\" x 2'"}
                          href=""
                          data-coords="401,343,445,292,463,304,418,355"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_3"
                        />
                        <area
                          target=""
                          alt="common toilet"
                          data-title={"Common Toilet\n 5' x 8'"}
                          href=""
                          data-coords="454,292,487,253,556,298,524,340"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_4"
                        />
                        <area
                          target=""
                          alt="master passage"
                          data-title={"Passage\n 5' x 3'5\""}
                          href=""
                          data-coords="421,267,455,228,485,247,449,287"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_5"
                        />
                        <area
                          target=""
                          alt="dry balcony"
                          data-title={"Dry Balcony\n 3'3\" x 7'5\""}
                          href=""
                          data-coords="508,385,533,353,602,401,578,435"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_6"
                        />
                        <area
                          target=""
                          alt="kitchen"
                          data-title={"Kitchen\n 10'6\"  x 8'4\""}
                          href=""
                          data-coords="535,347,569,302,563,297,594,256,676,308,611,401"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_7"
                        />
                        <area
                          target=""
                          alt="bedroom 2"
                          data-title={"Bedroom 2\n 10' x 12'"}
                          href=""
                          data-coords="343,185,315,169,388,97,420,118,423,150,485,190,419,266,355,220"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_8"
                        />
                        <area
                          target=""
                          alt="deck"
                          data-title={"Deck\n 11'2\" x 4'7\""}
                          href=""
                          data-coords="391,85,426,109,495,38,462,17"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_9"
                        />
                        <area
                          target=""
                          alt="living / dining"
                          data-title={
                            "Living/Dining \n 11'2\" x 19'4\" & 6'3\" x 11'8\""
                          }
                          href=""
                          data-coords="455,227,494,183,434,143,425,150,423,118,502,37,672,137,660,168,559,297"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_10"
                        />
                      </map>
                    </>
                  )}
                </>
              )}
              {activeArea && (
                <svg
                  className="absolute pointer-events-none"
                  xmlns="http://www.w3.org/2000/svg"
                  width={imageSize.width}
                  height={imageSize.height}
                  style={{
                    top: `${imageSize.top}px`,
                    left: `${imageSize.left}px`,
                  }}
                >
                  <polygon
                    className="highlight"
                    points={activeArea.join(",")}
                  />

                  {(() => {
                    // Split text into multiple lines with maximum 15 characters per line
                    const lines = activeAreaName.split(" ").reduce(
                      (acc, word) => {
                        const lastLine = acc[acc.length - 1] || "";
                        if ((lastLine + " " + word).length <= 25) {
                          acc[acc.length - 1] = lastLine
                            ? `${lastLine} ${word}`
                            : word;
                        } else {
                          acc.push(word);
                        }
                        return acc;
                      },
                      [""]
                    );

                    // Calculate width and height for the rect based on longest line and number of lines
                    const maxLineWidth = Math.max(
                      ...lines.map((line) => line.length * 9)
                    );
                    const rectWidth = maxLineWidth + paddingX * 2;
                    const rectHeight = lines.length * 20 + paddingY * 2;

                    // X and Y coordinates for positioning the rect
                    const rectX =
                      Math.max(
                        ...activeArea.filter((_, index) => index % 2 === 0)
                      ) + 5;
                    const rectY =
                      Math.min(
                        ...activeArea.filter((_, index) => index % 2 !== 0)
                      ) -
                      25 -
                      paddingY;

                    // Adjust rectX to prevent overflow beyond the right boundary of the image
                    const adjustedRectX =
                      rectX + rectWidth > imageSize.width
                        ? imageSize.width - rectWidth - 5
                        : rectX;

                    return (
                      <>
                        {/* Background rect with calculated dimensions */}
                        <rect
                          x={adjustedRectX}
                          y={rectY}
                          width={rectWidth}
                          height={rectHeight}
                          fill="#d8b387"
                          rx="5"
                          ry="5"
                        />

                        {/* Render each line of text with calculated positioning */}
                        {lines.map((line, index) => (
                          <text
                            key={index}
                            x={adjustedRectX + paddingX}
                            y={rectY + paddingY + 16 + index * 16}
                            fill="white"
                            fontSize="14"
                            fontFamily="Outfit, sans-serif"
                            textAnchor="start"
                          >
                            {line}
                          </text>
                        ))}
                      </>
                    );
                  })()}
                </svg>
              )}
            </div>
            <div
              className="w-full xl:w-2/12  lg:w-2/12 md:w-12/12 w-full rounded-lg mt-6 xl:mt-0 lg:mt-0 "
              // style={{ boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px" }}
            >
              <div
                className="flex xl:flex-wrap rounded-lg border lg:flex-wrap xl:gap-0 lg:gap-0 gap-4 items-center justify-between xl:py-6 py-6 xl:px-0 px-2"
                style={{
                  background: "#f0eeee",
                  boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                }}
              >
                <div className="xl:pt-4 w-full xl:w-9/12 mx-auto">
                  <div
                    className="cursor-pointer font text-sm font-semibold text-center text-white py-2 rounded bgbutton"
                    onClick={() => history.goBack()}
                  >
                    Go Back
                  </div>
                </div>
                <div className="xl:pt-2 lg:pt-2 w-full xl:w-9/12 mx-auto">
                  <a href={`/2d-unit/${floor}/${plan}/${unit}`}>
                    <div className="font text-sm font-semibold text-center text-white py-2 rounded bgbutton">
                      2D Unit Plan
                    </div>
                  </a>
                </div>
                <div className="xl:pt-2 pt-2 xl:block lg:block block w-full xl:w-9/12 mx-auto">
                  {/* <div className="font text-sm font-semibold text-center text-white py-2 rounded bgbutton">
                    Zoom Image
                  </div> */}
                </div>
              </div>

              {/* <div className="px-4 pb-4 xl:pt-4 xl:block lg:block hidden">
                                <h1 className="font-bold uppercase text-sm font" style={{ color: "#be935f" }}>
                                    Apartment Features
                                </h1>
                                <p className="xl:pt-4 font font-semibold text-sm">
                                    1. Homes have wide sundecks
                                </p>
                                <p className="xl:pt-2 font font-semibold text-sm">
                                    2.  Internal Flooring
                                </p>
                                <p className=" font text-sm">
                                    Large format Vitrified tiles,
                                    anti-skid Vitrified tiles
                                    in balcony and utility room
                                </p>
                                <p className="xl:pt-2 font font-semibold text-sm">
                                    3. Windows
                                </p>
                                <p className=" font text-sm">
                                    Heavy Duty aluminium
                                    Single-glazed windows
                                </p>
                                <p className="xl:pt-2 font font-semibold text-sm">
                                    4. Doors
                                </p>
                                <p className=" font text-sm">
                                    Main entrance door in veneer with
                                    melamine finish & laminate
                                    on all internal doors
                                </p>
                            </div> */}
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
