/*eslint-disable*/
import React, { useEffect, useState, useRef } from "react";
import Btowertypical from "assets/img/floor-plan/Tower-B-Typical.jpg";
import Btowerrefugee from "assets/img/floor-plan/Tower-B-Refugee.jpg";
import Btowersecond from "assets/img/floor-plan/Tower-B-Second-floor.jpg";
import IndexNavbarr from "components/Navbars/IndexNavbarr.js";
import "./ImageMapComponent.css";
import Footer from "components/Footers/Footer";
import { useParams } from "react-router";

export default function DetailB() {

    const paddingX = 2;
    const paddingY = 2;

    
  const [activeArea, setActiveArea] = useState(null);
  const [activeAreaName, setActiveAreaName] = useState("");
  const [activeElement, setActiveElement] = useState(null);
  const [imageSize, setImageSize] = useState({
    width: 0,
    height: 0,
    top: 0,
    left: 0,
  });
  const [isTouchDevice, setIsTouchDevice] = useState(false);
  const imgRef = useRef(null);
  const originalWidth = 886;
  const { floor, plan } = useParams();

  const resizeImageMap = () => {
    const img = imgRef.current;
    const map = document.querySelector('map[name="image-map"]');
    const widthRatio = img.clientWidth / originalWidth;

    map.querySelectorAll("area").forEach((area) => {
      const originalCoords = area.dataset.coords.split(",").map(Number);
      const newCoords = originalCoords.map((coord) => coord * widthRatio);
      area.coords = newCoords.join(",");
    });
  };

  useEffect(() => {
    setIsTouchDevice("ontouchstart" in window || navigator.maxTouchPoints > 0);
    setActiveArea(null);
    resizeImageMap();

    window.addEventListener("resize", resizeImageMap);
    window.addEventListener("load", resizeImageMap);

    const img = document.querySelector("img[usemap]");
    if (img) {
      const updateImageSize = () => {
        const { clientWidth, clientHeight, offsetTop, offsetLeft } = img;
        setImageSize({
          width: clientWidth,
          height: clientHeight,
          top: offsetTop,
          left: offsetLeft,
        });
      };
      updateImageSize();
      window.addEventListener("load", updateImageSize);
      window.addEventListener("resize", updateImageSize);

      return () => window.removeEventListener("resize", updateImageSize);
    }

    return () => {
      window.removeEventListener("resize", resizeImageMap);
      window.removeEventListener("load", resizeImageMap);
    };
  }, []);

  const navigateToNext = (unit) => {
    // console.log('/iso-3d/' + floor + '/' + plan + '/' + unit);
    window.location = "/iso-b-3d/" + floor + "/" + plan + "/" + unit;
  };

  const handleAreaClick = (area) => {
    setActiveArea(area.target.coords.split(",").map(Number));
    setActiveElement(area.target.id);
    setActiveAreaName(area.target.title);
  };

  const handleAreaMouseEnter = (area) => {
    if (!isTouchDevice) {
      setActiveArea(area.target.coords.split(",").map(Number));
      setActiveElement(area.target.id);
      setActiveAreaName(area.target.title);
    }
  };

  const handleAreaMouseLeave = (area) => {
    if (!isTouchDevice) {
      setActiveArea(null);
      setActiveElement(null);
      setActiveAreaName("");
    }
  };

  const [isZoomed, setIsZoomed] = useState(false);
  const [zoomedImage, setZoomedImage] = useState(null);

  // Object holding images for each plan type
  const images = {
    typical: Btowertypical,
    refugee: Btowerrefugee,
    second: Btowersecond,
  };

  const zoomImage = (selectedPlan) => {
    if (images[selectedPlan]) {
      setZoomedImage(images[selectedPlan]);
      setIsZoomed(true);
    }
  };

  // Close the zoomed image
  const closeZoom = () => {
    setIsZoomed(false);
    setZoomedImage(null);
  };

  return (
    <>
      <IndexNavbarr fixed />

      <section className="relative h-screen xl:py-32 lg:pt-32 md:pt-24 xl:pb-12 pt-24 bg-gray-100">
        <div className="container mx-auto flex justify-center items-center">
          <div className="flex flex-wrap justify-between  lg:flex-row flex-col-reverse">
            <div
              className="w-full xl:w-2/12  lg:w-2/12 md:w-12/12 w-full bg-white border rounded-lg mt-6 xl:mt-0 lg:mt-0 "
              style={{ boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px" }}
            >
              <div
                className="flex xl:flex-wrap lg:flex-wrap items-center justify-between xl:py-6 py-6"
                style={{ background: "#f0eeee" }}
              >
                <div className="pt-4 w-full px-6">
                  <div className="font text-sm font-semibold text-center text-white py-2 rounded bgbutton">
                    Floor No. : {floor}
                  </div>
                </div>
              </div>

              <div className="px-6 xl:w-12/12 lg:w-12/12 md:w-4/12 mx-auto pb-4 xl:pt-4 lg:pt-6 pt-2 xl:block lg:block ">
                <div
                  className="font xl:text-xs lg:text-xs text-xs font-semibold text-center text-white py-3 rounded-xl"
                  style={{
                    background: "#d8b387",
                    textTransform:
                      activeElement === "mainImage_1_1" ? "uppercase" : "none",
                    fontSize:
                      activeElement === "mainImage_1_1" ? "13px" : "14px",
                  }}
                >
                  Unit No. 1
                </div>
                <div
                  className="mt-2 font xl:text-xs lg:text-xs text-xs font-semibold text-center text-white py-3 rounded-xl"
                  style={{
                    background: "#d8b387",
                    textTransform:
                      activeElement === "mainImage_1_2" ? "uppercase" : "none",
                    fontSize:
                      activeElement === "mainImage_1_2" ? "13px" : "14px",
                  }}
                >
                  Unit No. 2
                </div>
                <div
                  className="mt-2 font xl:text-xs lg:text-xs text-xs font-semibold text-center text-white py-3 rounded-xl"
                  style={{
                    background: "#d8b387",
                    textTransform:
                      activeElement === "mainImage_1_3" ? "uppercase" : "none",
                    fontSize:
                      activeElement === "mainImage_1_3" ? "13px" : "14px",
                  }}
                >
                  Unit No. 3
                </div>
                {floor !== "2" && (
                  <div
                    className="mt-2 font xl:text-xs lg:text-xs text-xs font-semibold text-center text-white py-3 rounded-xl"
                    style={{
                      background: "#d8b387",
                      textTransform:
                        activeElement === "mainImage_1_4"
                          ? "uppercase"
                          : "none",
                      fontSize:
                        activeElement === "mainImage_1_4" ? "13px" : "14px",
                    }}
                  >
                    Unit No. 4
                  </div>
                )}
              </div>
            </div>

            <div className="w-full xl:w-8/12 lg:w-8/12 md:w-12/12 px-6 xl:pt-0 lg:pt-0 md:pt-0 pt-6 pb-2">
              {plan === "typical" && (
                <>
                  <img
                    src={Btowertypical}
                    useMap="#image-map"
                    ref={imgRef}
                    style={{ maxWidth: "100%", height: "auto", width: "100%" }}
                  />
                  <map name="image-map">
                    <area
                      target=""
                      alt=""
                      href="#!"
                      data-coords="580,190,603,192,604,201,676,199,677,161,696,159,697,127,727,126,731,162,757,162,755,50,699,51,697,38,580,37,580,57,516,55,516,140,579,142"
                      shape="poly"
                      onClick={
                        isTouchDevice
                          ? handleAreaClick
                          : () => navigateToNext("1")
                      }
                      onDoubleClick={
                        isTouchDevice ? () => navigateToNext("1") : undefined
                      }
                      onMouseLeave={
                        !isTouchDevice ? handleAreaMouseLeave : undefined
                      }
                      onMouseEnter={
                        !isTouchDevice ? handleAreaMouseEnter : undefined
                      }
                      id="mainImage_1_1"
                      title="Unit No. 1"
                    />
                    <area
                      target=""
                      alt=""
                      href="#!"
                      data-coords="453,192,456,141,516,139,516,58,452,57,453,42,337,38,336,52,278,53,274,159,301,158,305,128,335,127,331,160,355,159,356,200,428,200,430,192"
                      shape="poly"
                      onClick={
                        isTouchDevice
                          ? handleAreaClick
                          : () => navigateToNext("2")
                      }
                      onDoubleClick={
                        isTouchDevice ? () => navigateToNext("2") : undefined
                      }
                      onMouseLeave={
                        !isTouchDevice ? handleAreaMouseLeave : undefined
                      }
                      onMouseEnter={
                        !isTouchDevice ? handleAreaMouseEnter : undefined
                      }
                      id="mainImage_1_2"
                      title="Unit No. 2"
                    />
                    <area
                      target=""
                      alt=""
                      href="#!"
                      data-coords="513,316,492,318,452,319,452,276,384,278,384,316,398,320,397,350,364,350,364,318,337,318,341,420,400,420,398,439,454,441,514,441"
                      shape="poly"
                      onClick={
                        isTouchDevice
                          ? handleAreaClick
                          : () => navigateToNext("3")
                      }
                      onDoubleClick={
                        isTouchDevice ? () => navigateToNext("3") : undefined
                      }
                      onMouseLeave={
                        !isTouchDevice ? handleAreaMouseLeave : undefined
                      }
                      onMouseEnter={
                        !isTouchDevice ? handleAreaMouseEnter : undefined
                      }
                      id="mainImage_1_3"
                      title="Unit No. 3"
                    />
                    <area
                      target=""
                      alt=""
                      href="#!"
                      data-coords="515,318,580,318,581,276,649,278,649,319,624,321,624,359,657,360,660,318,723,320,722,371,736,375,737,403,719,405,718,443,575,445,575,435,516,437"
                      shape="poly"
                      onClick={
                        isTouchDevice
                          ? handleAreaClick
                          : () => navigateToNext("4")
                      }
                      onDoubleClick={
                        isTouchDevice ? () => navigateToNext("4") : undefined
                      }
                      onMouseLeave={
                        !isTouchDevice ? handleAreaMouseLeave : undefined
                      }
                      onMouseEnter={
                        !isTouchDevice ? handleAreaMouseEnter : undefined
                      }
                      id="mainImage_1_4"
                      title="Unit No. 4"
                    />
                  </map>
                </>
              )}
              {plan === "refugee" && (
                <>
                  <img
                    src={Btowerrefugee}
                    useMap="#image-map"
                    ref={imgRef}
                    style={{ maxWidth: "100%", height: "auto", width: "100%" }}
                  />
                  <map name="image-map">
                    <area
                      target=""
                      alt="unit 1"
                      href="#!"
                      data-coords="582,189,600,190,605,198,676,200,677,159,698,159,701,128,725,128,728,160,759,159,756,51,698,52,696,35,580,38"
                      shape="poly"
                      onClick={
                        isTouchDevice
                          ? handleAreaClick
                          : () => navigateToNext("1")
                      }
                      onDoubleClick={
                        isTouchDevice ? () => navigateToNext("1") : undefined
                      }
                      onMouseLeave={
                        !isTouchDevice ? handleAreaMouseLeave : undefined
                      }
                      onMouseEnter={
                        !isTouchDevice ? handleAreaMouseEnter : undefined
                      }
                      id="mainImage_1_1"
                      title="Unit No. 1"
                    />
                    <area
                      target=""
                      alt="unit2"
                      href="#!"
                      data-coords="452,190,453,40,336,38,336,53,280,51,274,160,304,160,304,128,332,126,332,160,354,160,356,202,427,200,428,191"
                      shape="poly"
                      onClick={
                        isTouchDevice
                          ? handleAreaClick
                          : () => navigateToNext("2")
                      }
                      onDoubleClick={
                        isTouchDevice ? () => navigateToNext("2") : undefined
                      }
                      onMouseLeave={
                        !isTouchDevice ? handleAreaMouseLeave : undefined
                      }
                      onMouseEnter={
                        !isTouchDevice ? handleAreaMouseEnter : undefined
                      }
                      id="mainImage_1_2"
                      title="Unit No. 2"
                    />
                    <area
                      target=""
                      alt="unit 3"
                      href="#!"
                      data-coords="512,320,512,436,400,439,400,422,344,422,339,318,365,317,365,350,398,350,399,320,384,317,384,275,453,276,450,319,491,318"
                      shape="poly"
                      onClick={
                        isTouchDevice
                          ? handleAreaClick
                          : () => navigateToNext("3")
                      }
                      onDoubleClick={
                        isTouchDevice ? () => navigateToNext("3") : undefined
                      }
                      onMouseLeave={
                        !isTouchDevice ? handleAreaMouseLeave : undefined
                      }
                      onMouseEnter={
                        !isTouchDevice ? handleAreaMouseEnter : undefined
                      }
                      id="mainImage_1_3"
                      title="Unit No. 3"
                    />
                    <area
                      target=""
                      alt="unit 4"
                      href="#!"
                      data-coords="515,319,532,318,580,319,580,275,648,277,648,316,626,320,624,357,659,359,659,317,719,321,722,377,735,379,733,403,716,407,715,443,576,443,574,433,515,436"
                      shape="poly"
                      onClick={
                        isTouchDevice
                          ? handleAreaClick
                          : () => navigateToNext("4")
                      }
                      onDoubleClick={
                        isTouchDevice ? () => navigateToNext("4") : undefined
                      }
                      onMouseLeave={
                        !isTouchDevice ? handleAreaMouseLeave : undefined
                      }
                      onMouseEnter={
                        !isTouchDevice ? handleAreaMouseEnter : undefined
                      }
                      id="mainImage_1_4"
                      title="Unit No. 4"
                    />
                  </map>
                </>
              )}
              {plan === "second" && floor === "2" && (
                <>
                  <img
                    src={Btowersecond}
                    useMap="#image-map"
                    ref={imgRef}
                    style={{ maxWidth: "100%", height: "auto", width: "100%" }}
                  />
                  <map name="image-map">
                    <area
                      target=""
                      alt="unit1"
                      href="#!"
                      data-coords="580,191,602,191,604,200,676,200,674,160,697,158,698,128,728,128,728,160,759,160,756,52,699,52,698,37,639,37,580,36,578,56,516,56,517,140,577,140"
                      shape="poly"
                      onClick={
                        isTouchDevice
                          ? handleAreaClick
                          : () => navigateToNext("1")
                      }
                      onDoubleClick={
                        isTouchDevice ? () => navigateToNext("1") : undefined
                      }
                      onMouseLeave={
                        !isTouchDevice ? handleAreaMouseLeave : undefined
                      }
                      onMouseEnter={
                        !isTouchDevice ? handleAreaMouseEnter : undefined
                      }
                      id="mainImage_1_1"
                      title="Unit No. 1"
                    />
                    <area
                      target=""
                      alt="unit 2"
                      href="#!"
                      data-coords="515,141,453,142,452,190,430,192,428,201,356,201,356,160,333,160,334,127,305,125,304,160,272,160,276,51,333,52,335,37,453,37,454,60,516,56"
                      shape="poly"
                      onClick={
                        isTouchDevice
                          ? handleAreaClick
                          : () => navigateToNext("2")
                      }
                      onDoubleClick={
                        isTouchDevice ? () => navigateToNext("2") : undefined
                      }
                      onMouseLeave={
                        !isTouchDevice ? handleAreaMouseLeave : undefined
                      }
                      onMouseEnter={
                        !isTouchDevice ? handleAreaMouseEnter : undefined
                      }
                      id="mainImage_1_2"
                      title="Unit No. 2"
                    />
                    <area
                      target=""
                      alt="unit 3"
                      href="#!"
                      data-coords="512,317,514,436,397,440,398,420,343,421,340,318,365,318,365,351,396,351,397,319,384,318,384,276,452,276,454,318"
                      shape="poly"
                      onClick={
                        isTouchDevice
                          ? handleAreaClick
                          : () => navigateToNext("3")
                      }
                      onDoubleClick={
                        isTouchDevice ? () => navigateToNext("3") : undefined
                      }
                      onMouseLeave={
                        !isTouchDevice ? handleAreaMouseLeave : undefined
                      }
                      onMouseEnter={
                        !isTouchDevice ? handleAreaMouseEnter : undefined
                      }
                      id="mainImage_1_3"
                      title="Unit No. 3"
                    />
                  </map>
                </>
              )}

              {activeArea && (
                <svg
                  className="absolute pointer-events-none"
                  xmlns="http://www.w3.org/2000/svg"
                  width={imageSize.width}
                  height={imageSize.height}
                  style={{
                    top: `${imageSize.top}px`,
                    left: `${imageSize.left}px`,
                  }}
                >
                  <polygon
                    className="highlight"
                    points={activeArea.join(",")}
                  />

                  {(() => {
                    // Split text into multiple lines with maximum 15 characters per line
                    const lines = activeAreaName.split(" ").reduce(
                      (acc, word) => {
                        const lastLine = acc[acc.length - 1] || "";
                        if ((lastLine + " " + word).length <= 25) {
                          acc[acc.length - 1] = lastLine
                            ? `${lastLine} ${word}`
                            : word;
                        } else {
                          acc.push(word);
                        }
                        return acc;
                      },
                      [""]
                    );

                    // Calculate width and height for the rect based on longest line and number of lines
                    const maxLineWidth = Math.max(
                      ...lines.map((line) => line.length * 9)
                    );
                    const rectWidth = maxLineWidth + paddingX * 2;
                    const rectHeight = lines.length * 20 + paddingY * 2;

                    // X and Y coordinates for positioning the rect
                    const rectX =
                      Math.max(
                        ...activeArea.filter((_, index) => index % 2 === 0)
                      ) + 5;
                    const rectY =
                      Math.min(
                        ...activeArea.filter((_, index) => index % 2 !== 0)
                      ) -
                      25 -
                      paddingY;

                    // Adjust rectX to prevent overflow beyond the right boundary of the image
                    const adjustedRectX =
                      rectX + rectWidth > imageSize.width
                        ? imageSize.width - rectWidth - 5
                        : rectX;

                    return (
                      <>
                        {/* Background rect with calculated dimensions */}
                        <rect
                          x={adjustedRectX}
                          y={rectY}
                          width={rectWidth}
                          height={rectHeight}
                          fill="#d8b387"
                          rx="5"
                          ry="5"
                        />

                        {/* Render each line of text with calculated positioning */}
                        {lines.map((line, index) => (
                          <text
                            key={index}
                            x={adjustedRectX + paddingX}
                            y={rectY + paddingY + 16 + index * 16}
                            fill="white"
                            fontSize="14"
                            fontFamily="Outfit, sans-serif"
                            textAnchor="start"
                          >
                            {line}
                          </text>
                        ))}
                      </>
                    );
                  })()}
                </svg>
              )}
            </div>
            <div
              className="w-full xl:w-2/12  lg:w-2/12 md:w-12/12 w-full rounded-lg mt-6 xl:mt-0 lg:mt-0 "
              // style={{ boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px" }}
            >
              <div
                className="flex xl:flex-wrap rounded-lg border lg:flex-wrap xl:gap-0 lg:gap-0 gap-4 items-center justify-between xl:py-6 py-6 xl:px-0 px-2"
                style={{
                  background: "#f0eeee",
                  boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                }}
              >
                <div className="xl:pt-4 w-full xl:w-9/12 mx-auto">
                  <a href="/tower">
                    <div className="font text-sm font-semibold text-center text-white py-2 rounded bgbutton">
                      Go Back
                    </div>
                  </a>
                </div>
                {/* <div className="xl:pt-2 lg:pt-2 w-full xl:w-9/12 mx-auto">
                                    <a href="/iso-3d">
                                        <div className="font text-sm font-semibold text-center text-white py-2 rounded bgbutton">
                                            3D Unit Plan
                                        </div>
                                    </a>
                                </div> */}
                <div className="xl:pt-2 pt-2 xl:block lg:block block w-full xl:w-9/12 mx-auto">
                  <div
                    className="cursor-pointer  font text-sm font-semibold text-center text-white py-2 rounded bgbutton"
                    onClick={() => zoomImage(plan)} // Pass the selected plan
                  >
                    Zoom Image
                  </div>
                </div>
              </div>
            </div>
          </div>
          {isZoomed && (
            <div className="zoom-overlay">
              <div className="zoom-container">
                {/* Image to be zoomed */}
                <img
                  src={zoomedImage} // Dynamically set the zoomed image
                  alt={`${plan} plan`}
                  className="zoomed-image"
                />

                {/* Close button */}
                <div className="close-button " onClick={closeZoom}>
                  <i className="fas fa-times pl-1 "></i>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>

      <Footer />
    </>
  );
}
