/*eslint-disable*/
import React, { useState } from "react";
import IndexNav from "components/Navbars/IndexNav.js";
import './ImageMapComponent.css';
import Footer from "components/Footers/Footer";
import Certificate338878 from "assets/img/Certificate338878.webp";
import Certificatee338878 from "assets/img/Certificatee338878.webp";
import rera from "assets/img/rera.webp"

export default function Disclaimer() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');

    const handleImageClick = (imageSrc) => {
        setSelectedImage(imageSrc);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedImage('');
    };

    return (
        <>
            <IndexNav fixed />

            <section className="relative h-screen xl:py-32 lg:pt-32 md:pt-32 xl:pb-12 pt-24 bg-gray-100">
                <div className="container mx-auto flex justify-center items-center">
                    <div className="flex flex-wrap justify-between xl:py-12 lg:py-12 xl:px-36 lg:px-36  px-4">
                        <div className="font xl:text-lg lg:text-base flex flex-wrap items-center">
                            <p className="xl:w-10/12 lg:w-10/12 w-12/12 text-base ">
                             <p className="font-semibold" style={{color:"#bf925e"}}>Disclaimer : <span className="font-normal text-black">All representations, pictorial, written or otherwise, made herein, are only for better understanding of the
                                project and overall offering. All developments are subject to necessary approvals and applicable laws, including
                                any subsequent changes. You are requested to verify the project's details thoroughly on ERA portal through the
                                Project's MahaRERA Registration No. P51700077743. The final and binding terms of sale will be stated in the Builder
                                Buyer Agreement. As on date, the Builder/Promoter has received the commencement certificate for Tower 3A up to
                                25th Floor and for Tower 3B up to 24th Floor of the proiect Embassy One Thane and proposes to have an
                                elevation/construction up to 40th Floor, subject to receipt of necessary approvals.</span> </p>
                            </p>
                            <div className="xl:w-2/12 lg:w-2/12 justify-center  flex mx-auto">
                                <img
                                    src={rera}
                                    alt="Certificate 2"
                                    className="xl:w-9/12 lg:w-9/12 w-4/12 rounded-lg cursor-pointer"
                                />
                            </div>
                        </div>

                        <div className="flex flex-wrap py-12">
                            <div className="xl:w-6/12 lg:w-6/12">
                                <img
                                    src={Certificate338878}
                                    alt="Certificate 1"
                                    className="rounded-lg cursor-pointer"
                                    onClick={() => handleImageClick(Certificate338878)}
                                />
                            </div>
                            <div className="xl:w-6/12 lg:w-6/12">
                                <img
                                    src={Certificatee338878}
                                    alt="Certificate 2"
                                    className="rounded-lg cursor-pointer"
                                    onClick={() => handleImageClick(Certificatee338878)}
                                />
                            </div>

                        </div>
                        <a href="/" className="xl:w-2/12 w-3/12 pb-32 flex justify-center mx-auto">
                            <div className=" text-center py-2 rounded bgbutton font text-white xl:w-9/12">
                                Go Back
                            </div>
                        </a>





                    </div>
                    {isModalOpen && (
                        <div className="fixed top-32 inset-0 bg-blackk w-11/12 pb-32 rounded-lg flex justify-center items-center z-50 " >
                            <div className="p-4 rounded-lg relative  w-full xl:mt-24" style={{ height: "600px", overflowY: "scroll" }}>
                                <button
                                    onClick={closeModal}
                                    className="absolute top-2 right-2 text-2xl font-bold text-white border rounded-full  w-12 h-12 hover:text-black"
                                >
                                    ×
                                </button>
                                <img
                                    src={selectedImage}
                                    alt="Selected"
                                    className="rounded-lg mx-auto pb-32 pt-12"
                                    style={{ maxWidth: '90%', maxHeight: '140vh', objectFit: 'contain' }}
                                />
                            </div>
                        </div>
                    )}
                </div>

            </section>

            <Footer />
        </>
    );
}
