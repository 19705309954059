/*eslint-disable*/
import React, { useEffect, useState, useRef } from "react";
import IndexNavbarr from "components/Navbars/IndexNavbarr.js";
import Footer from "components/Footers/Footer";
import gallery3 from "assets/img/gallery/gallery3.webp";
import colors from "tailwindcss/colors";

export default function Projecthighlight() {

  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  // Original content: 4 items + 5 additional items for demonstration
  const originalContent = [
    {
      title: "Total Area - 7.2 acres",
      description: "Spanning across 3 phases",
    },
    {
      title: "Unit Sizes - 2 BHK and 3BHK",
      description: "",
    },
    {
      title: "Prominent Location -Thane- POKHRAN ROAD 2",
      description: <ul className="list-disc list-inside">
        <li>
        Excellent connectivity to Thane station, upcoming metro, and eastern expressway.
        </li>
        <li>
        Great proximity to the top Schools, Hospitals and Retail.
        </li>
        {/* <li>
        Great Proximity to Top Schools.
        </li>
        <li>
        Hospitals.
        </li>
        <li>
        Retail for added Convenience.
        </li> */}
      </ul>
    },
    {
      title: "Panoramic Views",
      description: "Stunning views of the Yeoor Hills and Thane skyline ",
    },
    {
      title: "Thought-fully Designed Amenities",
      description: "Exclusive clubhouse and premium amenities to elevate your lifestyle  ",
    },
  ];

  return (
    <>
      <IndexNavbarr fixed />

      <section className="">
        <div
          className="xl:height lg:h-screen md:h-screen h-screen sm:h-auto overflow-hidden min-h-screen flex justify-center items-center" // Added overflow-hidden
          style={{
            backgroundImage: `url(${gallery3})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top", // Optional: centers the image
            backgroundAttachment: "fixed", // Ensures the background is fixed
          }}

        >
          <div className="container mx-auto  xxl:px-0 xl:px-0 lg:px-0 px-4  ">
            <div className="w-full flex justify-center ">
              <div className="xxl:w-4/12 xl:w-4/12 lg:w-4/12"></div>
              <div className="xxl:w-4/12 5 xl:w-4/12 lg:w-4/12"></div>




              <div className="xxl:w-4/12  xl:w-4/12 lg:w-4/12 flex flex-col bgblack-200 shadow rounded-lg h-350-px">
                <div className="xl:py-4 lg:pt-6 py-4 px-4">
                  <h2 className="flex items-center text-white font xl:text-2xl lg:text-2xl  font-semibold">
                    <div className="flex items-center" style={{ height: '30px' }}>
                      {/* <svg xmlns="http://www.w3.org/2000/svg" height="40" width="40">
        <path
          fill="#fdaf17"
          d="M20 31.5q-2.208 0-4.188-.792-1.979-.791-3.52-2.166l-3.542 3.5q-.208.208-.479.208t-.479-.208q-.209-.209-.209-.5 0-.292.209-.459l3.5-3.5Q9.875 26 9.104 24.042q-.771-1.959-.771-4.209 0-4.875 3.396-8.27Q15.125 8.167 20 8.167h11.667v11.666q0 4.875-3.396 8.271Q24.875 31.5 20 31.5Zm0-1.417q4.292 0 7.271-3t2.979-7.25V9.583H20q-4.292 0-7.271 2.98-2.979 2.979-2.979 7.27 0 1.917.667 3.667.666 1.75 1.833 3.083l8.875-8.833q.167-.208.458-.208.292 0 .5.208.209.208.209.479t-.209.521l-8.833 8.792q1.333 1.208 3.083 1.875 1.75.666 3.667.666Zm0-10.25Z"
        ></path>
      </svg> */}
                    </div>
                    <span className="xxl:text-2xl xl:text-xl lg:text-base font">
                      Project Overview
                    </span>
                  </h2>
                </div>

                {/* Container for content and scrollbar */}
                <div className="flex-grow overflow-hidden ">
                  <ul
                    className={`list-disc list-inside pl-4 pr-4 transition-all duration-500 
            ${isExpanded ? 'h-60' : 'h-60'} 
            ${isExpanded ? 'overflow-y-auto' : 'overflow-hidden'} scrollbar-thin scrollbar-thumb-white scrollbar-track-black`}
                    style={{ maxHeight: '14rem' }} // Fixed height for both states
                  >
                    {/* Map through the content, show all when expanded */}
                    {originalContent.slice(0, isExpanded ? originalContent.length : 4).map((item, index) => (
                      <React.Fragment key={index}>
                        <li className="text-white font font-bold heading xxl:text-sm xl:text-sm lg:text-xs text-base  uppercase mb-1">
                          {item.title}
                        </li>
                        <p className="pl-4 text-white font xxl:text-sm xl:text-sm lg:text-xs text-sm mb-2">
                          {item.description}
                        </p>
                      </React.Fragment>
                    ))}
                  </ul>
                </div>

                <div
                  className="flex justify-center items-center space-x-4 cursor-pointer py-2 arrow-container "
                  onClick={toggleExpand}
                >
                  <p className="text-white  font xxl:text-sm xl:text-sm lg:text-xs text-xs py-1 uppercase font-semibold">
                    {isExpanded ?
                      <div className="bgbutton flex items-center py-2 px-6">
                        Less <i className=" fas fa-chevron-up text-xs pl-3"></i>
                      </div>
                      :
                      <div className="bgbutton flex items-center py-2 px-6">
                        More <i className=" fas fa-chevron-down text-xs pl-3"></i>
                      </div>}
                  </p>

                  {/* <div className="flex items-center justify-center w-6 h-4  border1 rounded-full ml-2 ">
                    <span className="text-white" style={{ fontSize: '17px', letterSpacing: '2px', fontWeight: 'medium', lineHeight: '1' }}>
                      {isExpanded ? <i className="fas fa-chevron-up text-xs "></i> : <i className=" fas fa-chevron-down text-xs"></i>}
                    </span>


                  </div> */}
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
