// /*eslint-disable*/
// import React, { useEffect } from "react";
// import IndexNavbar from "components/Navbars/IndexNavbar.js";
// import Footer from "components/Footers/Footer.js";

// export default function Index() {
//   // Function to dynamically load external scripts
//   const loadScript = (src, async = true) => {
//     const script = document.createElement("script");
//     script.src = src;
//     script.async = async;
//     document.body.appendChild(script);
//   };

//   useEffect(() => {
//     // Load the Wistia embed scripts
//     loadScript("https://fast.wistia.com/embed/medias/nxzj4cbvyh.jsonp");
//     loadScript("https://fast.wistia.com/assets/external/E-v1.js");

//     // Wistia player initialization and custom event handling
//     window._wq = window._wq || [];
//     let videoInstance = null;

//     window._wq.push({
//       id: "nxzj4cbvyh",
//       onReady: function (video) {
//         videoInstance = video;

//         // Disable the play button
//         const playButton = document.querySelector('.wistia_embed .w-playbutton');
//         if (playButton) {
//           playButton.style.display = "none";
//         }

//         // Add event listener to toggle sound on click
//         const soundButton = document.createElement("button");
//         soundButton.innerText = "Toggle Sound";
//         soundButton.addEventListener("click", () => {
//           video.volume(video.volume() === 0 ? 1 : 0);
//         });

//         document.body.appendChild(soundButton);

//         // Pause the video when the page is hidden
//         document.addEventListener("visibilitychange", () => {
//           if (document.hidden) {
//             video.pause();
//           } else if (!video.paused) {
//             video.play();
//           }
//         });

//         // Pause the video when the page is closed or navigated away
//         window.addEventListener("beforeunload", () => {
//           video.pause();
//         });

//         // Loop the video when it ends
//         video.bind("end", () => {
//           video.play();
//         });
//       }
//     });

//     // Autoplay the video
//     window._wq.push({
//       id: "nxzj4cbvyh",
//       onReady: (video) => {
//         video.play();
//       }
//     });

//     // Clean up scripts when the component unmounts
//     return () => {
//       document.body.querySelectorAll("script[src*='wistia']").forEach(script => script.remove());
//     };
//   }, []);

//   return (
//     <>
//       <IndexNavbar fixed />

//       <section className="relative w-full h-screen flex justify-center" style={{flexDirection: "column"}}>
//         <div className="wistia_responsive_padding" style={{ padding: "56.25% 0 0 0", position: "relative" }}>
//           <div className="wistia_responsive_wrapper" style={{ height: "100%", position: "absolute", top: "0", width: "100%" }}>
//             <div className="wistia_embed wistia_async_nxzj4cbvyh seo=true videoFoam=true" style={{ height: "100%", width: "100%", position: "relative" }}>
//               <div className="wistia_swatch" style={{ height: "100%", width: "100%", position: "absolute", top: "0", left: "0", opacity: "0", overflow: "hidden", transition: "opacity 200ms" }}>
//                 <img
//                   src="https://fast.wistia.com/embed/medias/nxzj4cbvyh/swatch"
//                   style={{ filter: "blur(5px)", height: "100%", width: "100%", objectFit: "contain" }}
//                   alt=""
//                   aria-hidden="true"
//                   onLoad={(e) => e.currentTarget.parentNode.style.opacity = 1}
//                 />
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>

//       <Footer />
//     </>
//   );
// }

/*eslint-disable*/
// import React, { useEffect, useState, useRef } from "react";

// import IndexNavbarr from "components/Navbars/IndexNavbarr.js";
// import Footer from "components/Footers/Footer";
// import embassylocation from "assets/img/embassylocation.webp";
// import locations from "assets/img/locations.webp";


// export default function Location() {


//     return (
//         <>
//             <IndexNavbarr fixed />

//             <section className="xl:pt-24 lg:pt-20">
//                 <img className=" w-full xl:pt-0 lg:pt-0 md:pt-0 pt-64" src={locations} alt=""/>

//             </section>

//             <Footer />
//         </>
//     );
// }


import React, { useEffect, useState, useRef } from "react";

import IndexNavbarr from "components/Navbars/IndexNavbarr.js";
import Footer from "components/Footers/Footer";

const Location = () => {
    useEffect(() => {
        // Create the script elements dynamically
        const script1 = document.createElement('script');
        script1.src = 'https://fast.wistia.com/embed/medias/9sy8c0jfxr.jsonp';
        script1.async = true;

        const script2 = document.createElement('script');
        script2.src = 'https://fast.wistia.com/assets/external/E-v1.js';
        script2.async = true;

        // Append the scripts to the document body
        document.body.appendChild(script1);
        document.body.appendChild(script2);

        // Cleanup the scripts when component unmounts
        return () => {
            document.body.removeChild(script1);
            document.body.removeChild(script2);
        };
    }, []);

    return (
        <>
            <IndexNavbarr fixed />
            <div className="xl:pt-0 pt-64 lg:pt-0">
                <div className="wistia_responsive_padding" style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
                    <div className="wistia_responsive_wrapper" style={{ height: '100%', left: 0, position: 'absolute', top: 0, width: '100%' }}>
                        <div className="wistia_embed wistia_async_9sy8c0jfxr seo=true videoFoam=true" style={{ height: '100%', position: 'relative', width: '100%' }}>
                            <div className="wistia_swatch" style={{ height: '100%', left: 0, opacity: 0, overflow: 'hidden', position: 'absolute', top: 0, transition: 'opacity 200ms', width: '100%' }}>
                                <img
                                    src="https://fast.wistia.com/embed/medias/9sy8c0jfxr/swatch"
                                    style={{ filter: 'blur(5px)', height: '100%', objectFit: 'contain', width: '100%' }}
                                    alt=""
                                    aria-hidden="true"
                                    onLoad={(e) => (e.currentTarget.parentNode.style.opacity = 1)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Location;


