/*eslint-disable*/
import React, { useEffect, useState, useRef } from "react";
import IndexNavbarr from "components/Navbars/IndexNavbarr.js";
import "./ImageMapComponent.css";
import Footer from "components/Footers/Footer";
import kitchen from "assets/img/kitchen.png";
import intercom from "assets/img/intercom (1).png";
import videodoor from "assets/img/video-door-phone (1).png";
import cctv from "assets/img/cctv.png";
import generator from "assets/img/generator.png";
import tiles from "assets/img/tiles.png";
import tiles1 from "assets/img/tiles (1).png";
import aircondition from "assets/img/air-conditioning.png";
import fire from "assets/img/fire.png";
import tower1 from "assets/img/tower1.jpg";
import projectdetails from "assets/img/projectdetails.webp";

export default function Tower() {
  const containerRef = useRef(null);
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
  const originalWidth = 4000;
  const originalHeight = 2251;
  const [selectedFloor, setSelectedFloor] = useState("");

  const highlight = (floor) => {
    floor.target.style.backgroundColor = "#d8b387";
    // setSelectedFloor(floor.target.id);
  };

  const removeHighlight = (floor) => {
    floor.target.style.backgroundColor = "";
    // setSelectedFloor('')
  };

  const showLabel = (floor) => {
    const labels = document.getElementsByClassName("floor-label");
    Array.from(labels).map((lbl) => {
      lbl.remove();
    })
    const label = document.createElement("span");
    label.className = "floor-label span-" + floor.target.id;
    label.innerText = floor.target.getAttribute("data-title");
    label.style.position = "absolute";
    label.style.top = "0px";
    const screenWidth = window.innerWidth;
    label.style.left = (screenWidth >= 880) ? '100px' : '30px';
    label.style.backgroundColor = "#d8b387";
    label.style.color = "white";
    label.style.padding = "2px 2px";
    label.style.fontSize = "10px";
    label.style.borderRadius = "4px";
    label.style.zIndex = "1000";
    label.style.fontWeight = "500";
    label.style.width = "100%"; // Allow label to grow but limit the width
    label.style.maxWidth = "50px"; // Allow label to grow but limit the width
    label.style.wordWrap = "break-word"; // Ensure text wraps to the next line
    label.style.marginTop = "8px";
    label.addEventListener("dblclick", navigateToPage); 

    // Append the label to the marker element
    floor.target.appendChild(label);
  };

  const navigateToPage = (floor) => {
    window.location.href = floor.target.getAttribute("data-href");
  }

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        const { width, height } = containerRef.current.getBoundingClientRect();
        setImageSize({ width, height });
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initial size
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const markers = [
    {
      id: 2,
      data_title: "Floor 2",
      x: 2603,
      y: 1880,
      href: "/detail/2/second",
    },
    {
      id: 3,
      data_title: "Floor 3",
      x: 2603,
      y: 1830,
      href: "/detail/3/typical",
    },
    {
      id: 4,
      data_title: "Floor 4",
      x: 2603,
      y: 1780,
      href: "/detail/4/typical",
    },
    {
      id: 5,
      data_title: "Floor 5",
      x: 2603,
      y: 1730,
      href: "/detail/5/typical",
    },
    {
      id: 6,
      data_title: "Floor 6",
      x: 2603,
      y: 1680,
      href: "/detail/6/typical",
    },
    {
      id: 7,
      data_title: "Floor 7",
      x: 2603,
      y: 1630,
      href: "/detail/7/refugee",
    },
    {
      id: 8,
      data_title: "Floor 8",
      x: 2603,
      y: 1580,
      href: "/detail/8/typical",
    },
    {
      id: 9,
      data_title: "Floor 9",
      x: 2603,
      y: 1530,
      href: "/detail/9/typical",
    },
    {
      id: 10,
      data_title: "Floor 10",
      x: 2603,
      y: 1480,
      href: "/detail/10/typical",
    },
    {
      id: 11,
      data_title: "Floor 11",
      x: 2603,
      y: 1430,
      href: "/detail/11/typical",
    },
    {
      id: 12,
      data_title: "Floor 12",
      x: 2603,
      y: 1380,
      href: "/detail/12/refugee",
    },
    {
      id: 13,
      data_title: "Floor 13",
      x: 2603,
      y: 1330,
      href: "/detail/13/typical",
    },
    {
      id: 14,
      data_title: "Floor 14",
      x: 2603,
      y: 1280,
      href: "/detail/14/typical",
    },
    {
      id: 15,
      data_title: "Floor 15",
      x: 2603,
      y: 1230,
      href: "/detail/15/typical",
    },
    {
      id: 16,
      data_title: "Floor 16",
      x: 2603,
      y: 1180,
      href: "/detail/16/typical",
    },
    {
      id: 17,
      data_title: "Floor 17",
      x: 2603,
      y: 1130,
      href: "/detail/17/refugee",
    },
    {
      id: 18,
      data_title: "Floor 18",
      x: 2603,
      y: 1080,
      href: "/detail/18/typical",
    },
    {
      id: 19,
      data_title: "Floor 19",
      x: 2603,
      y: 1030,
      href: "/detail/19/typical",
    },
    {
      id: 20,
      data_title: "Floor 20",
      x: 2603,
      y: 980,
      href: "/detail/20/typical",
    },
    {
      id: 21,
      data_title: "Floor 21",
      x: 2603,
      y: 930,
      href: "/detail/21/typical",
    },
    {
      id: 22,
      data_title: "Floor 22",
      x: 2603,
      y: 880,
      href: "/detail/22/refugee",
    },
    {
      id: 23,
      data_title: "Floor 23",
      x: 2603,
      y: 830,
      href: "/detail/23/typical",
    },
    {
      id: 24,
      data_title: "Floor 24",
      x: 2603,
      y: 780,
      href: "/detail/24/typical",
    },
    {
      id: 25,
      data_title: "Floor 25",
      x: 2603,
      y: 730,
      href: "/detail/25/typical",
    },
    {
      id: 26,
      data_title: "Floor 2",
      x: 2190,
      y: 1880,
      href: "/detail-b/2/second",
    },
    {
      id: 27,
      data_title: "Floor 3",
      x: 2190,
      y: 1830,
      href: "/detail-b/3/typical",
    },
    {
      id: 28,
      data_title: "Floor 4",
      x: 2190,
      y: 1780,
      href: "/detail-b/4/typical",
    },
    {
      id: 29,
      data_title: "Floor 5",
      x: 2190,
      y: 1730,
      href: "/detail-b/5/typical",
    },
    {
      id: 30,
      data_title: "Floor 6",
      x: 2190,
      y: 1680,
      href: "/detail-b/6/typical",
    },
    {
      id: 31,
      data_title: "Floor 7",
      x: 2190,
      y: 1630,
      href: "/detail-b/7/refugee",
    },
    {
      id: 32,
      data_title: "Floor 8",
      x: 2190,
      y: 1580,
      href: "/detail-b/8/typical",
    },
    {
      id: 33,
      data_title: "Floor 9",
      x: 2190,
      y: 1530,
      href: "/detail-b/9/typical",
    },
    {
      id: 34,
      data_title: "Floor 10",
      x: 2190,
      y: 1480,
      href: "/detail-b/10/typical",
    },
    {
      id: 35,
      data_title: "Floor 11",
      x: 2190,
      y: 1430,
      href: "/detail-b/11/typical",
    },
    {
      id: 36,
      data_title: "Floor 12",
      x: 2190,
      y: 1380,
      href: "/detail-b/12/refugee",
    },
    {
      id: 37,
      data_title: "Floor 13",
      x: 2190,
      y: 1330,
      href: "/detail-b/13/typical",
    },
    {
      id: 38,
      data_title: "Floor 14",
      x: 2190,
      y: 1280,
      href: "/detail-b/14/typical",
    },
    {
      id: 39,
      data_title: "Floor 15",
      x: 2190,
      y: 1230,
      href: "/detail-b/15/typical",
    },
    {
      id: 40,
      data_title: "Floor 16",
      x: 2190,
      y: 1180,
      href: "/detail-b/16/typical",
    },
    {
      id: 41,
      data_title: "Floor 17",
      x: 2190,
      y: 1130,
      href: "/detail-b/17/refugee",
    },
    {
      id: 42,
      data_title: "Floor 18",
      x: 2190,
      y: 1080,
      href: "/detail-b/18/typical",
    },
    {
      id: 43,
      data_title: "Floor 19",
      x: 2190,
      y: 1030,
      href: "/detail-b/19/typical",
    },
    {
      id: 44,
      data_title: "Floor 20",
      x: 2190,
      y: 980,
      href: "/detail-b/20/typical",
    },
    {
      id: 45,
      data_title: "Floor 21",
      x: 2190,
      y: 930,
      href: "/detail-b/21/typical",
    },
    {
      id: 46,
      data_title: "Floor 22",
      x: 2190,
      y: 880,
      href: "/detail-b/22/refugee",
    },
    {
      id: 47,
      data_title: "Floor 23",
      x: 2190,
      y: 830,
      href: "/detail-b/23/typical",
    },
    {
      id: 48,
      data_title: "Floor 24",
      x: 2190,
      y: 780,
      href: "/detail-b/24/typical",
    },
    {
      id: 49,
      data_title: "Floor 25",
      x: 2190,
      y: 730,
      href: "/detail-b/25/typical",
    },
  ];

  return (
    <>
      <IndexNavbarr fixed />
      <section className="relative xl:h-screen lg:h-screen md:h-screen xl:py-32 lg:pt-32 md:pt-16 xl:pb-12 pt-24 pb-32 bg-gray-100 min-h-screen flex justify-center items-center">
        <div className="container mx-auto flex justify-center items-center">
          <div className="flex flex-wrap justify-between  lg:flex-row flex-col-reverse xl:px-0 lg:px-0 px-2">
            <div
              className="w-full xl:w-2/12  lg:w-2/12 md:w-12/12  bg-white border rounded-lg mt-6 xl:mt-0 lg:mt-0 "
              style={{ boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px" }}
            >
              <div
                className="flex xl:flex-wrap lg:flex-wrap items-center justify-between xl:py-6 py-6"
                style={{ background: "#f0eeee" }}
              >
                <div className="pt-4 w-full px-6">
                  <div className="font xxl:text-base xl:text-base text-sm font-semibold text-center text-white py-2 rounded bgbutton">
                    Tower Features
                  </div>
                </div>
              </div>

              <ul className="list-disc list-inside px-1  xl:pt-1 pt-4 xl:pb-0 lg:pb-0 md:pb-0 pb-4 xl:block lg:block block overflow-y-scroll">
                <div className="flex flex-col px-2">
                  <div className="flex justify-start gap-3 items-center xl:mt-4 mt-2">
                    <div className="xxl:w-2/12 xl:w-2/12 lg:w-2/12 w-2/12 flex-shrink-0 flex justify-center">
                      <img className="w-8" src={intercom} alt="Intercom" />
                    </div>
                    <div className="xxl:w-10/12 xl:w-10/12 lg:w-10/12 w-10/12">
                      <p className="font xl:text-sm lg:text-xs text-base ">
                        Intercom Systems
                      </p>
                    </div>
                  </div>

                  <div className="flex justify-start gap-3 items-center xl:mt-4 mt-2">
                    <div className="xxl:w-2/12 xl:w-2/12 lg:w-2/12 w-2/12 flex-shrink-0 flex justify-center">
                      <img
                        className="w-8"
                        src={videodoor}
                        alt="Video Door Phone"
                      />
                    </div>
                    <div className="xxl:w-10/12 xl:w-10/12 lg:w-10/12 w-10/12">
                      <p className="font xl:text-sm lg:text-xs text-base ">
                        Video Door Phone
                      </p>
                    </div>
                  </div>

                  <div className="flex justify-start gap-3 items-center xl:mt-4 mt-2">
                    <div className="xxl:w-2/12 xl:w-2/12 lg:w-2/12 w-2/12 flex-shrink-0 flex justify-center">
                      <img className="w-8" src={cctv} alt="CCTV Camera" />
                    </div>
                    <div className="xxl:w-10/12 xl:w-10/12 lg:w-10/12 w-10/12">
                      <p className="font xl:text-sm lg:text-xs text-base ">
                        CCTV Camera (Ground Floor Entrance Lobby)
                      </p>
                    </div>
                  </div>

                  <div className="flex justify-start gap-3 items-center xl:mt-4 mt-2">
                    <div className="xxl:w-2/12 xl:w-2/12 lg:w-2/12 w-2/12 flex-shrink-0 flex justify-center">
                      <img className="w-8" src={generator} alt="Power Backup" />
                    </div>
                    <div className="xxl:w-10/12 xl:w-10/12 lg:w-10/12 w-10/12">
                      <p className="font xl:text-sm lg:text-xs text-base ">
                        Power Backup (Common Area)
                      </p>
                    </div>
                  </div>

                  <div className="flex justify-start gap-3 items-center xl:mt-4 mt-2">
                    <div className="xxl:w-2/12 xl:w-2/12 lg:w-2/12 w-2/12 flex-shrink-0 flex justify-center">
                      <img className="w-8" src={fire} alt="Fire Fighting" />
                    </div>
                    <div className="xxl:w-10/12 xl:w-10/12 lg:w-10/12 w-10/12">
                      <p className="font xl:text-sm lg:text-xs text-base ">
                        Fire Fighting Systems
                      </p>
                    </div>
                  </div>

                  {/* <div className="flex justify-start items-center xl:mt-4 mt-2">
                    <div className="xl:w-12 w-16 flex-shrink-0 flex justify-center">
                      <img
                        className="xl:w-7 w-8 lg:w-7"
                        src={videodoor}
                        alt="Video Door Phone"
                      />
                    </div>
                    <div className="">
                      <p className="xl:text-sm font lg:text-sm text-base">
                        Video Door
                        <br /> Phone
                      </p>
                    </div>
                  </div> */}

                  {/* <div className="flex justify-start items-center xl:mt-4 mt-2">
                    <div className="xl:w-12 w-16 flex-shrink-0 flex justify-center">
                      <img className="xl:w-7 w-8 lg:w-7" src={cctv} alt="CCTV Camera" />
                    </div>
                    <div className="">
                      <p className="xl:text-sm font lg:text-sm text-base">
                        CCTV Camera
                        <br /> (Ground Floor Entrance Lobby)
                      </p>
                    </div>
                  </div> */}

                  {/* <div className="flex justify-start items-center xl:mt-4 mt-2">
                    <div className="xl:w-12 w-16 flex-shrink-0 flex justify-center">
                      <img className="xl:w-7 w-8 lg:w-7" src={generator} alt="Power Backup" />
                    </div>
                    <div className="">
                      <p className="xl:text-sm font lg:text-sm text-base">
                        Power Backup
                        <br /> (Common Area)
                      </p>
                    </div>
                  </div> */}

                  {/* <div className="flex justify-start items-center xl:mt-4 mt-2">
                    <div className="xl:w-12 w-16 flex-shrink-0 flex justify-center">
                      <img className="xl:w-7 w-8 lg:w-7" src={fire} alt="Fire Fighting" />
                    </div>
                    <div className="">
                      <p className="xl:text-sm font lg:text-sm text-base">
                        Fire Fighting
                        <br /> Systems
                      </p>
                    </div>
                  </div> */}
                </div>
              </ul>
            </div>

            <div
              className="relative w-full xxl:w-8/12 xl:w-8/12 lg:w-8/12 pl-4 pr-4"
              useRef={containerRef}
            >
              <img src={projectdetails} alt="tower" className="w-full h-auto" />
              {markers.map((marker) => {
                const [isHovered, setIsHovered] = useState(false);
                return (
                  <>
                    <a
                      id={`floor-${marker.id}`}
                      key={marker.id}
                      href="#!"
                      data-href={marker.href}
                      className="absolute p-1"
                      data-title={marker.data_title}
                      style={{
                        top: `${(marker.y / originalHeight) * 100}%`,
                        left: `${(marker.x / originalWidth) * 100}%`,
                        display: "flex",
                        height: `${(45 / originalHeight) * 100}%`,
                        width: `${(380 / originalWidth) * 100}%`,
                      }}
                      onMouseOver={highlight}
                      onMouseLeave={removeHighlight}
                      onClick={showLabel}
                      onDoubleClick={navigateToPage}
                    ></a>
                  </>
                );
              })}
            </div>
            <div
              className="w-full xl:w-2/12  lg:w-2/12 md:w-12/12 w-full  bg-white border rounded-lg mt-6 xl:mt-0 lg:mt-0 "
              style={{ boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px" }}
            >
              <div
                className="flex xl:flex-wrap lg:flex-wrap items-center justify-between xl:py-6 py-6"
                style={{ background: "#f0eeee" }}
              >
                <div className="pt-4 w-full px-6">
                  <div className="font xxl:text-base xl:text-base text-sm font-semibold text-center text-white py-2 rounded bgbutton">
                    Unit Details
                  </div>
                </div>
              </div>

              <div className="px-4 pb-4 xl:pt-1 pt-2 xl:block lg:block block">
                {/* Apartment Features Heading */}
                {/* <h1 className="font-bold uppercase text-sm" style={{ color: "#be935f" }}>
    Apartment Features
  </h1> */}

                {/* Feature 1 */}

                <div className="flex justify-start gap-3 items-center xl:mt-4 mt-2">
                  <div className="xxl:w-2/12 xl:w-2/12 lg:w-2/12 w-2/12 flex-shrink-0 flex justify-center">
                    <img className="w-8" src={tiles1} alt="Marble Flooring" />
                  </div>
                  <div className="xxl:w-10/12 xl:w-10/12 lg:w-10/12 w-10/12">
                    <p className="font xl:text-sm lg:text-xs text-base ">
                      Imported marble flooring in Living, Dining, and all
                      bedrooms
                    </p>
                  </div>
                </div>

                <div className="flex justify-start gap-3 items-center xl:mt-4 mt-2">
                  <div className="xxl:w-2/12 xl:w-2/12 lg:w-2/12 w-2/12 flex-shrink-0 flex justify-center">
                    <img className="w-8" src={tiles} alt="Tile Flooring" />
                  </div>
                  <div className="xxl:w-10/12 xl:w-10/12 lg:w-10/12 w-10/12">
                    <p className="font xl:text-sm lg:text-xs text-base ">
                      Vitrified tiles in Kitchen and Bathrooms
                    </p>
                  </div>
                </div>

                <div className="flex justify-start gap-3 items-center xl:mt-4 mt-2">
                  <div className="xxl:w-2/12 xl:w-2/12 lg:w-2/12 w-2/12 flex-shrink-0 flex justify-center">
                    <img className="w-8" src={kitchen} alt="Modular Kitchen" />
                  </div>
                  <div className="xxl:w-10/12 xl:w-10/12 lg:w-10/12 w-10/12">
                    <p className="font xl:text-sm lg:text-xs text-base ">
                      Modular kitchen design with quartz countertop;
                      under-counter setup
                    </p>
                  </div>
                </div>

                <div className="flex justify-start gap-3 items-center xl:mt-4 mt-2">
                  <div className="xxl:w-2/12 xl:w-2/12 lg:w-2/12 w-2/12 flex-shrink-0 flex justify-center">
                    <img
                      className="w-8"
                      src={aircondition}
                      alt="Air Conditioning"
                    />
                  </div>
                  <div className="xxl:w-10/12 xl:w-10/12 lg:w-10/12 w-10/12">
                    <p className="font xl:text-sm lg:text-xs text-base ">
                      Air-conditioning in Living and Bedroom
                    </p>
                  </div>
                </div>

                {/* <div className="flex justify-start items-center">
                  <div className="xl:w-16 w-12 lg:w-16 flex-shrink-0 flex justify-center">
                    <img className="xl:w-7 w-8 lg:w-7" src={aircondition} alt="Air Conditioning" />
                  </div>
                  <div className="ml-3">
                    <p className="xl:text-sm font lg:text-sm text-base">
                    Air-conditioning in Living and Bedroom
                    </p>
                  </div>
                </div> */}

                {/* <div className="flex justify-start items-center mt-4">
                  <div className="xl:w-16 w-12 lg:w-16 flex-shrink-0 flex justify-center">
                    <img className="xl:w-7 w-8 lg:w-7" src={tiles1} alt="Marble Flooring" />
                  </div>
                  <div className="xl:ml-3 ml-4">
                    <p className="xl:text-sm font lg:text-sm text-base">
                    Imported marble flooring in Living, Dining, and all bedrooms
                    </p>
                  </div>
                </div> */}

                {/* Feature 2 */}
                {/* <div className="flex justify-start items-center mt-4">
                  <div className="xl:w-12 w-12 flex-shrink-0 flex justify-center">
                    <img className="xl:w-7 w-8 lg:w-7" src={tiles} alt="Tile Flooring" />
                  </div>
                  <div className="ml-3">
                    <p className="xl:text-sm font lg:text-sm text-base">
                    Vitrified tiles in Kitchen and Bathrooms
                    </p>
                  </div>
                </div> */}

                {/* <div className="flex justify-start items-center mt-4">
                  <div className="xl:w-12 w-12 flex-shrink-0 flex justify-center">
                    <img className="xl:w-7 w-8 lg:w-7" src={tiles} alt="Tile Flooring" />
                  </div>
                  <div className="ml-3">
                    <p className="xl:text-sm font lg:text-sm text-base">
                    Modular kitchen design with quartz countertop; under-counter setup 
                    </p>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
