/*eslint-disable*/
import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import unitatwod from "assets/img/typical/unitatwod.jpg";
import IndexNavbarr from "components/Navbars/IndexNavbarr.js";
import "./ImageMapComponent.css";
import Footer from "components/Footers/Footer";
import { useParams } from "react-router";
import unit1Typical from "assets/img/typical-2d/Tower A Typical Plan Unit 1 3BHK.jpg";
import unit2Typical from "assets/img/typical-2d/Tower A Typical Plan Unit 2 3BHK.jpg";
import unit3Typical from "assets/img/typical-2d/Tower A Typical Plan Unit 3 2BHK.jpg";
import unit4Typical from "assets/img/typical-2d/Tower A Typical Plan Unit 4 3BHK.jpg";
import unit1Refugee from "assets/img/refugee-2d/Tower A Refuge Floor Unit 1 2BHK.jpg";
import unit2Refugee from "assets/img/refugee-2d/Tower A Refuge Floor Unit 2 2BHK.jpg";

export default function Iso() {
  const history = useHistory();

  const [activeArea, setActiveArea] = useState(null);
  const [activeAreaName, setActiveAreaName] = useState("");
  const [activeElement, setActiveElement] = useState(null);
  const [imageSize, setImageSize] = useState({
    width: 0,
    height: 0,
    top: 0,
    left: 0,
  });
  const [isTouchDevice, setIsTouchDevice] = useState(false);
  const imgRef = useRef(null);
  const originalWidth = 886;
  const { floor, plan, unit } = useParams();
  const paddingX = 2;
  const paddingY = 2;

  const resizeImageMap = () => {
    const img = imgRef.current;
    const map = document.querySelector('map[name="image-map"]');
    const widthRatio = img.clientWidth / originalWidth;

    map.querySelectorAll("area").forEach((area) => {
      const originalCoords = area.dataset.coords.split(",").map(Number);
      const newCoords = originalCoords.map((coord) => coord * widthRatio);
      area.coords = newCoords.join(",");
    });
  };

  useEffect(() => {
    setIsTouchDevice("ontouchstart" in window || navigator.maxTouchPoints > 0);
    setActiveArea(null);
    resizeImageMap();

    window.addEventListener("resize", resizeImageMap);
    window.addEventListener("load", resizeImageMap);

    const img = document.querySelector("img[usemap]");
    if (img) {
      const updateImageSize = () => {
        const { clientWidth, clientHeight, offsetTop, offsetLeft } = img;
        setImageSize({
          width: clientWidth,
          height: clientHeight,
          top: offsetTop,
          left: offsetLeft,
        });
      };
      updateImageSize();
      window.addEventListener("load", updateImageSize);
      window.addEventListener("resize", updateImageSize);

      return () => window.removeEventListener("resize", updateImageSize);
    }

    return () => {
      window.removeEventListener("resize", resizeImageMap);
      window.removeEventListener("load", resizeImageMap);
    };
  }, []);

  useEffect(() => {
    console.log(activeAreaName);
  }, [activeAreaName]);

  const handleAreaClick = (area) => {
    setActiveArea(area.target.coords.split(",").map(Number));
    setActiveElement(area.target.id);
    setActiveAreaName(area.target.getAttribute("data-title"));
  };

  const handleAreaMouseEnter = (area) => {
    if (!isTouchDevice) {
      setActiveArea(area.target.coords.split(",").map(Number));
      setActiveElement(area.target.id);
      setActiveAreaName(area.target.getAttribute("data-title"));
    }
  };

  const handleAreaMouseLeave = (area) => {
    if (!isTouchDevice) {
      setActiveArea(null);
      setActiveElement(null);
      setActiveAreaName("");
    }
  };

  return (
    <>
      <IndexNavbarr fixed />

      <section className="relative h-screen xl:py-32 lg:pt-32 md:pt-24 xl:pb-12 pt-24 bg-gray-100">
        <div className="container mx-auto flex justify-center items-center">
          <div className="flex flex-wrap justify-between  lg:flex-row flex-col-reverse">
            <div className="w-full xl:w-2/12 lg:w-2/12 md:w-12/12 w-full  px-1 pt-2 ">
              <div
                className="bg-white  rounded-lg  "
                style={{
                  boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                  height: "500px",
                  overflowY: "scroll",
                }}
              >
                {plan === "typical" && unit === "1" && (
                  <table className=" rounded-lg w-full py-2">
                    <tr
                      className="t_foyer"
                      style={
                        ["mainImage_1_9"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Entrance Foyer
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        4'6" x 6'3"
                      </td>
                    </tr>
                    <tr
                      className="t_living "
                      style={
                        ["mainImage_1_13"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Living/Dining{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs"
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'4" x 19'10" <br></br>& 3'8" x 12'2"
                      </td>
                    </tr>
                    <tr
                      className="t_balcony_1"
                      style={
                        ["mainImage_1_12"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Deck
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'2" x 4'7"
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["mainImage_1_8"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Kitchen
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        10'10" x 8'
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["mainImage_1_7"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Dry Balcony
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        3'3" x 8'0"
                      </td>
                    </tr>
                    <tr
                      className="t_utility"
                      style={
                        ["mainImage_1_6"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Utility/Storage
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        7' x 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_bedroom_1"
                      style={
                        ["mainImage_1_14"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Pooja Room
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        1'2" x 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_balcony"
                      style={
                        ["mainImage_1_18"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        {" "}
                        Bedroom 2 Passage
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        3'5" x 3'7"
                      </td>
                    </tr>
                    <tr
                      className="t_passage"
                      style={
                        ["mainImage_1_17"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Bedroom 2 Wardrobe
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        2'10" × 1'18"
                      </td>
                    </tr>
                    <tr
                      className="t_bedroom_2"
                      style={
                        ["mainImage_1_15"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Bedroom 2
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'7"× 11'
                      </td>
                    </tr>
                    <tr
                      className="t_toilet_2"
                      style={
                        ["mainImage_1_16"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        {" "}
                        Toilet 2{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        8' x 5'
                      </td>
                    </tr>
                    <tr
                      className="t_master_passage"
                      style={
                        ["mainImage_1_11"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Passage 1
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5'× 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_bedroom"
                      style={
                        ["mainImage_1_10"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        {" "}
                        Bedroom 3
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11' x 12'
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_1_5"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Common Toilet
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        8' x 4'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_1_2"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Passage
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        3'5" x 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_1_1"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Bedroom
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11' x 12'10"
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_1_3"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Wardrobe
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5' x 2'
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_1_4"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Toilet
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5' x 8'4"
                      </td>
                    </tr>
                  </table>
                )}
                {plan === "typical" && unit === "2" && (
                  <table className=" rounded-lg w-full py-2">
                    <tr
                      className="t_foyer"
                      style={
                        ["mainImage_2_6"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Entrance Foyer
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        4'6" x 6'3"
                      </td>
                    </tr>
                    <tr
                      className="t_living "
                      style={
                        ["mainImage_2_10"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Living/Dining{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs"
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'4" x 19'10" <br></br>& 3'8" x 12'2"
                      </td>
                    </tr>
                    <tr
                      className="t_balcony_1"
                      style={
                        ["mainImage_2_5"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Deck
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'2" x 4'7"
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["mainImage_2_7"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Kitchen
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        10'10" x 8'
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["mainImage_2_8"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Dry Balcony
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        3'3" x 8'0"
                      </td>
                    </tr>
                    <tr
                      className="t_utility"
                      style={
                        ["mainImage_2_9"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Utility/Storage
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        7' x 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_bedroom_1"
                      style={
                        ["mainImage_2_11"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Pooja Room
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        1'2" x 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_balcony"
                      style={
                        ["mainImage_2_4"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        {" "}
                        Bedroom 2 Passage
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        3'5" x 3'7"
                      </td>
                    </tr>
                    <tr
                      className="t_passage"
                      style={
                        ["mainImage_2_3"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Bedroom 2 Wardrobe
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        2'10" × 1'18"
                      </td>
                    </tr>
                    <tr
                      className="t_bedroom_2"
                      style={
                        ["mainImage_2_1"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Bedroom 2
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'7"× 11'
                      </td>
                    </tr>
                    <tr
                      className="t_toilet_2"
                      style={
                        ["mainImage_2_2"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        {" "}
                        Toilet 2{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        8' x 5'
                      </td>
                    </tr>
                    <tr
                      className="t_master_passage"
                      style={
                        ["mainImage_2_14"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Passage 1
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5'× 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_bedroom"
                      style={
                        ["mainImage_2_13"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        {" "}
                        Bedroom 3
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11' x 12'
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_2_12"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Common Toilet
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        8' x 4'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_2_18"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Passage
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        3'5" x 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_2_15"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Bedroom
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11' x 12'10"
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_2_16"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Wardrobe
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5' x 2'
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_2_17"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Toilet
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5' x 8'4"
                      </td>
                    </tr>
                  </table>
                )}
                {plan === "typical" && unit === "3" && (
                  <table className=" rounded-lg w-full py-2">
                    <tr
                      className="t_living "
                      style={
                        ["mainImage_3_10"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Living/Dining{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs"
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'2" x 19'4" <br></br>& 6'3" x 11'8"
                      </td>
                    </tr>
                    <tr
                      className="t_balcony_1"
                      style={
                        ["mainImage_3_9"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Deck
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'2" x 4'7"
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["mainImage_3_7"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Kitchen
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        10'6" x 8'4"
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["mainImage_3_6"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Dry Balcony
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        3'3" x 7'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_balcony"
                      style={
                        ["mainImage_3_5"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Passage
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5' x 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_bedroom_2"
                      style={
                        ["mainImage_3_8"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Bedroom 2
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        10'× 12'
                      </td>
                    </tr>
                    <tr
                      className="t_toilet_2"
                      style={
                        ["mainImage_3_4"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        {" "}
                        Common Toilet{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5' x 8'
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_3_1"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Bedroom
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11' x 12'
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_3_3"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Wardrobe
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        6'3" x 2'
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_3_2"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Toilet
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5' x 8'
                      </td>
                    </tr>
                  </table>
                )}
                {plan === "typical" && unit === "4" && (
                  <table className=" rounded-lg w-full py-2">
                    <tr
                      className="t_living "
                      style={
                        ["mainImage_4_11"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Living/Dining{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs"
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'2" x 19'14" <br></br>& 5'5" x 11'10"
                      </td>
                    </tr>
                    <tr
                      className="t_balcony_1"
                      style={
                        ["mainImage_4_10"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Deck
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'2" x 4'7"
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["mainImage_4_9"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Kitchen
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        10'6" x 8'4"
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["mainImage_4_8"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Dry Balcony
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        3'3" x 8'4"
                      </td>
                    </tr>
                    <tr
                      className="t_master_balcony"
                      style={
                        ["mainImage_4_3"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Passage
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        12'2" x 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_passage"
                      style={
                        ["mainImage_4_6"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Bedroom 3
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        10'8" × 12'10"
                      </td>
                    </tr>
                    <tr
                      className="t_bedroom_2"
                      style={
                        ["mainImage_4_7"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Common Toilet
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5'× 8'
                      </td>
                    </tr>
                    <tr
                      className="t_toilet_2"
                      style={
                        ["mainImage_4_5"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        {" "}
                        Bedroom 2{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        10'10" x 12'10"
                      </td>
                    </tr>
                    <tr
                      className="t_master_passage"
                      style={
                        ["mainImage_4_4"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Toilet 2
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5'× 7'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_bedroom"
                      style={
                        ["mainImage_4_1"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        {" "}
                        Master Bedroom
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        12'6" x 11'10"
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_4_2"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Toilet
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        8'8" x 5'
                      </td>
                    </tr>
                  </table>
                )}
                {plan === "second" && unit === "1" && (
                  <table className=" rounded-lg w-full py-2">
                    <tr
                      className="t_foyer"
                      style={
                        ["mainImage_1_9"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Entrance Foyer
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        4'6" x 6'3"
                      </td>
                    </tr>
                    <tr
                      className="t_living "
                      style={
                        ["mainImage_1_13"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Living/Dining{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs"
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'4" x 19'10" <br></br>& 3'8" x 12'2"
                      </td>
                    </tr>
                    <tr
                      className="t_balcony_1"
                      style={
                        ["mainImage_1_12"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Deck
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'2" x 4'7"
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["mainImage_1_8"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Kitchen
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        10'10" x 8'
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["mainImage_1_7"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Dry Balcony
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        3'3" x 8'0"
                      </td>
                    </tr>
                    <tr
                      className="t_utility"
                      style={
                        ["mainImage_1_6"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Utility/Storage
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        7' x 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_bedroom_1"
                      style={
                        ["mainImage_1_14"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Pooja Room
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        1'2" x 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_balcony"
                      style={
                        ["mainImage_1_18"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        {" "}
                        Bedroom 2 Passage
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        3'5" x 3'7"
                      </td>
                    </tr>
                    <tr
                      className="t_passage"
                      style={
                        ["mainImage_1_17"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Bedroom 2 Wardrobe
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        2'10" × 1'18"
                      </td>
                    </tr>
                    <tr
                      className="t_bedroom_2"
                      style={
                        ["mainImage_1_15"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Bedroom 2
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'7"× 11'
                      </td>
                    </tr>
                    <tr
                      className="t_toilet_2"
                      style={
                        ["mainImage_1_16"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        {" "}
                        Toilet 2{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        8' x 5'
                      </td>
                    </tr>
                    <tr
                      className="t_master_passage"
                      style={
                        ["mainImage_1_11"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Passage 1
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5'× 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_bedroom"
                      style={
                        ["mainImage_1_10"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        {" "}
                        Bedroom 3
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11' x 12'
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_1_5"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Common Toilet
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        8' x 4'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_1_2"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Passage
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        3'5" x 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_1_1"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Bedroom
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11' x 12'10"
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_1_3"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Wardrobe
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5' x 2'
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_1_4"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Toilet
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5' x 8'4"
                      </td>
                    </tr>
                  </table>
                )}
                {plan === "second" && unit === "2" && (
                  <table className=" rounded-lg w-full py-2">
                    <tr
                      className="t_foyer"
                      style={
                        ["mainImage_2_6"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Entrance Foyer
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        4'6" x 6'3"
                      </td>
                    </tr>
                    <tr
                      className="t_living "
                      style={
                        ["mainImage_2_10"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Living/Dining{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs"
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'4" x 19'10" <br></br>& 3'8" x 12'2"
                      </td>
                    </tr>
                    <tr
                      className="t_balcony_1"
                      style={
                        ["mainImage_2_5"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Deck
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'2" x 4'7"
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["mainImage_2_7"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Kitchen
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        10'10" x 8'
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["mainImage_2_8"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Dry Balcony
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        3'3" x 8'0"
                      </td>
                    </tr>
                    <tr
                      className="t_utility"
                      style={
                        ["mainImage_2_9"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Utility/Storage
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        7' x 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_bedroom_1"
                      style={
                        ["mainImage_2_11"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Pooja Room
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        1'2" x 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_balcony"
                      style={
                        ["mainImage_2_4"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        {" "}
                        Bedroom 2 Passage
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        3'5" x 3'7"
                      </td>
                    </tr>
                    <tr
                      className="t_passage"
                      style={
                        ["mainImage_2_3"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Bedroom 2 Wardrobe
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        2'10" × 1'18"
                      </td>
                    </tr>
                    <tr
                      className="t_bedroom_2"
                      style={
                        ["mainImage_2_1"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Bedroom 2
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'7"× 11'
                      </td>
                    </tr>
                    <tr
                      className="t_toilet_2"
                      style={
                        ["mainImage_2_2"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        {" "}
                        Toilet 2{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        8' x 5'
                      </td>
                    </tr>
                    <tr
                      className="t_master_passage"
                      style={
                        ["mainImage_2_14"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Passage 1
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5'× 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_bedroom"
                      style={
                        ["mainImage_2_13"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        {" "}
                        Bedroom 3
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11' x 12'
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_2_12"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Common Toilet
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        8' x 4'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_2_18"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Passage
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        3'5" x 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_2_15"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Bedroom
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11' x 12'10"
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_2_16"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Wardrobe
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5' x 2'
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_2_17"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Toilet
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5' x 8'4"
                      </td>
                    </tr>
                  </table>
                )}
                {plan === "second" && unit === "3" && (
                  <table className=" rounded-lg w-full py-2">
                    <tr
                      className="t_living "
                      style={
                        ["mainImage_3_10"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Living/Dining{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs"
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'2" x 19'4" <br></br>& 6'3" x 11'8"
                      </td>
                    </tr>
                    <tr
                      className="t_balcony_1"
                      style={
                        ["mainImage_3_9"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Deck
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'2" x 4'7"
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["mainImage_3_7"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Kitchen
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        10'6" x 8'4"
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["mainImage_3_6"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Dry Balcony
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        3'3" x 7'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_balcony"
                      style={
                        ["mainImage_3_5"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Passage
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5' x 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_bedroom_2"
                      style={
                        ["mainImage_3_8"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Bedroom 2
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        10'× 12'
                      </td>
                    </tr>
                    <tr
                      className="t_toilet_2"
                      style={
                        ["mainImage_3_4"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        {" "}
                        Common Toilet{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5' x 8'
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_3_1"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Bedroom
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11' x 12'
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_3_3"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Wardrobe
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        6'3" x 2'
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_3_2"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Toilet
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5' x 8'
                      </td>
                    </tr>
                  </table>
                )}

                {plan === "refugee" && unit === "1" && (
                  <table className=" rounded-lg w-full py-2">
                    <tr
                      className="t_living "
                      style={
                        ["s_mainImage_1_9"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Entrance Foyer{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs"
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        4'6" x 6'3"
                      </td>
                    </tr>
                    <tr
                      className="t_living "
                      style={
                        ["s_mainImage_1_14"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Living/Dining{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs"
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'4" x 19'10"{" "}
                      </td>
                    </tr>
                    <tr
                      className="t_balcony_1"
                      style={
                        ["s_mainImage_1_13"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Deck
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'2" x 4'7"
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["s_mainImage_1_8"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Study Room
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        6'8" x 8'4"
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["s_mainImage_1_10"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Kitchen
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        10'10" x 8'
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["s_mainImage_1_7"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Dry Balcony
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        3'3" x 8'0"
                      </td>
                    </tr>
                    <tr
                      className="t_master_balcony"
                      style={
                        ["s_mainImage_1_16"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Passage
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        10'0" x 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_bedroom_2"
                      style={
                        ["s_mainImage_1_15"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Bedroom 2
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'× 12'
                      </td>
                    </tr>
                    <tr
                      className="t_toilet_2"
                      style={
                        ["s_mainImage_1_5"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        {" "}
                        Common Toilet{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5'0" x 8'4"
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["s_mainImage_1_2"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Bedroom Passage
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        2'4" x 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["s_mainImage_1_1"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Bedroom
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11' x 12'10"
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["s_mainImage_1_3"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Wardrobe
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5' x 2'
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["s_mainImage_1_4"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Toilet
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5' x 8'4"
                      </td>
                    </tr>
                  </table>
                )}
                {plan === "refugee" && unit === "2" && (
                  <table className=" rounded-lg w-full py-2">
                    <tr
                      className="t_living "
                      style={
                        ["s_mainImage_2_6"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Entrance Foyer{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs"
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        4'6" x 6'3"
                      </td>
                    </tr>
                    <tr
                      className="t_living "
                      style={
                        ["s_mainImage_2_17"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Living/Dining{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs"
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'4" x 19'10"{" "}
                      </td>
                    </tr>
                    <tr
                      className="t_balcony_1"
                      style={
                        ["s_mainImage_2_16"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Deck
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'2" x 4'7"
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["s_mainImage_2_15"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Study Room
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        6'8" x 8'4"
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["s_mainImage_2_10"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Kitchen
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        10'10" x 8'
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["s_mainImage_2_9"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Dry Balcony
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        3'3" x 8'0"
                      </td>
                    </tr>
                    <tr
                      className="t_master_balcony"
                      style={
                        ["s_mainImage_2_11"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Passage
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        10'0" x 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_bedroom_2"
                      style={
                        ["s_mainImage_2_12"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Bedroom 2
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'× 12'
                      </td>
                    </tr>
                    <tr
                      className="t_toilet_2"
                      style={
                        ["s_mainImage_2_5"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        {" "}
                        Common Toilet{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5'0" x 8'4"
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["s_mainImage_2_2"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Bedroom Passage
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        2'4" x 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["s_mainImage_2_1"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Bedroom
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11' x 12'10"
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["s_mainImage_2_4"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Wardrobe
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5' x 2'
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["s_mainImage_2_3"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Toilet
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5' x 8'4"
                      </td>
                    </tr>
                  </table>
                )}

                {plan === "refugee" && unit === "3" && (
                  <table className=" rounded-lg w-full py-2">
                    <tr
                      className="t_living "
                      style={
                        ["mainImage_3_10"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Living/Dining{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs"
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'2" x 19'4" <br></br>& 6'3" x 11'8"
                      </td>
                    </tr>
                    <tr
                      className="t_balcony_1"
                      style={
                        ["mainImage_3_9"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Deck
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'2" x 4'7"
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["mainImage_3_7"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Kitchen
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        10'6" x 8'4"
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["mainImage_3_6"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Dry Balcony
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        3'3" x 7'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_balcony"
                      style={
                        ["mainImage_3_5"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Passage
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5' x 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_bedroom_2"
                      style={
                        ["mainImage_3_8"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Bedroom 2
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        10'× 12'
                      </td>
                    </tr>
                    <tr
                      className="t_toilet_2"
                      style={
                        ["mainImage_3_4"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        {" "}
                        Common Toilet{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5' x 8'
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_3_1"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Bedroom
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11' x 12'
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_3_3"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Wardrobe
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        6'3" x 2'
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_3_2"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Toilet
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5' x 8'
                      </td>
                    </tr>
                  </table>
                )}
                {plan === "refugee" && unit === "4" && (
                  <table className=" rounded-lg w-full py-2">
                    <tr
                      className="t_living "
                      style={
                        ["mainImage_4_11"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Living/Dining{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs"
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'2" x 19'14" <br></br>& 5'5" x 11'10"
                      </td>
                    </tr>
                    <tr
                      className="t_balcony_1"
                      style={
                        ["mainImage_4_10"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Deck
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'2" x 4'7"
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["mainImage_4_9"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Kitchen
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        10'6" x 8'4"
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["mainImage_4_8"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Dry Balcony
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        3'3" x 8'4"
                      </td>
                    </tr>
                    <tr
                      className="t_master_balcony"
                      style={
                        ["mainImage_4_3"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Passage
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        12'2" x 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_passage"
                      style={
                        ["mainImage_4_6"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Bedroom 3
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        10'8" × 12'10"
                      </td>
                    </tr>
                    <tr
                      className="t_bedroom_2"
                      style={
                        ["mainImage_4_7"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Common Toilet
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5'× 8'
                      </td>
                    </tr>
                    <tr
                      className="t_toilet_2"
                      style={
                        ["mainImage_4_5"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        {" "}
                        Bedroom 2{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        10'10" x 12'10"
                      </td>
                    </tr>
                    <tr
                      className="t_master_passage"
                      style={
                        ["mainImage_4_4"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Toilet 2
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5'× 7'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_bedroom"
                      style={
                        ["mainImage_4_1"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        {" "}
                        Master Bedroom
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        12'6" x 11'10"
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_4_2"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Toilet
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        8'8" x 5'
                      </td>
                    </tr>
                  </table>
                )}
              </div>
              <div className="px-2 pt-12 pb-16 xl:hidden lg:hidden block">
                <h1
                  className="font-bold uppercase text-sm font"
                  style={{ color: "#be935f" }}
                >
                  Apartment Features
                </h1>
                <p className="xl:pt-4 pt-2 font font-semibold text-sm">
                  1. Homes have wide sundecks
                </p>
                <p className="xl:pt-2 pt-2 font font-semibold text-sm">
                  2. Internal Flooring
                </p>
                <p className=" font text-sm">
                  Large format Vitrified tiles, anti-skid Vitrified tiles in
                  balcony and utility room
                </p>
                <p className="xl:pt-2 pt-2 font font-semibold text-sm">
                  3. Windows
                </p>
                <p className=" font text-sm">
                  Heavy Duty aluminium Single-glazed windows
                </p>
                <p className="xl:pt-2 pt-2 font font-semibold text-sm">
                  4. Doors
                </p>
                <p className=" font text-sm">
                  Main entrance door in veneer with melamine finish & laminate
                  on all internal doors
                </p>
              </div>
            </div>

            <div className="w-full xl:w-8/12 lg:w-8/12 md:w-12/12 px-6 xl:pt-0 lg:pt-0 md:pt-0 pt-6 pb-2">
              {plan === "typical" && (
                <>
                  {unit === "1" && (
                    <>
                      <img
                        src={unit1Typical}
                        useMap="#image-map"
                        ref={imgRef}
                        style={{
                          maxWidth: "100%",
                          height: "auto",
                          width: "100%",
                        }}
                      />
                      <map name="image-map">
                        <area
                          target=""
                          alt="master bedroom"
                          data-title={"Master Bedroom\n 11' x 12'10\""}
                          href=""
                          data-coords="220,91,239,91,237,77,339,77,339,91,355,91,355,199,355,239,221,239"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_1"
                        />
                        <area
                          target=""
                          alt="master passage"
                          data-title={"Master Passage\n 3'5\" x 3'5\""}
                          href=""
                          data-coords="355,199,398,198,398,240,356,240"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_2"
                        />
                        <area
                          target=""
                          alt="master wardrobe"
                          data-title={"Master Wardrobe\n 5' x 2'"}
                          href=""
                          data-coords="286,240,348,239,348,264,287,264"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_3"
                        />
                        <area
                          target=""
                          alt="master toilet"
                          data-title={"Master Toilet\n 5' x 8'4\""}
                          href=""
                          data-coords="221,242,281,244,281,347,214,345,211,310,222,310"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_4"
                        />
                        <area
                          target=""
                          alt="common toilet"
                          data-title={"Common Toilet\n 8' x 4'5\""}
                          href=""
                          data-coords="369,245,458,245,458,296,355,298,355,271,370,271"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_5"
                        />
                        <area
                          target=""
                          alt="utility / storage"
                          data-title={"Utility/Storage\n 7' x 3'5\""}
                          href=""
                          data-coords="446,348,363,346,360,305,446,304"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_6"
                        />
                        <area
                          target=""
                          alt="dry balcony"
                          data-title={"Dry Balcony\n 3'3\" x 8'0\""}
                          href=""
                          data-coords="411,353,450,353,450,453,411,453"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_7"
                        />
                        <area
                          target=""
                          alt="kitchen"
                          data-title={"Kitchen\n 10'10\" x 8'"}
                          href=""
                          data-coords="455,352,589,352,588,447,457,447"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_8"
                        />
                        <area
                          target=""
                          alt="passage 2"
                          data-title={"Entrance Foyer\n 4'6\" x 6'3\""}
                          href=""
                          data-coords="594,348,646,348,648,428,594,427"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_9"
                        />
                        <area
                          target=""
                          alt="bedroom 3"
                          data-title={"Bedroom 3\n 11' x 12'"}
                          href=""
                          data-coords="370,42,504,42,504,194,370,194"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_10"
                        />
                        <area
                          target=""
                          alt="passage 1"
                          data-title={"Passage 1\n 5' x 3'5\""}
                          href=""
                          data-coords="401,199,462,199,462,240,401,239"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_11"
                        />
                        <area
                          target=""
                          alt="deck"
                          data-title={"Deck\n 11'2\" x 4'7\""}
                          href=""
                          data-coords="512,53,648,54,648,97,512,97"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_12"
                        />
                        <area
                          target=""
                          alt="living / dining"
                          data-title={
                            "Living / Dining\n 11'4\" x 19'10\" & 3'8\" x 12'2\""
                          }
                          href=""
                          data-coords="463,200,511,199,510,104,648,104,646,346,465,345"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_13"
                        />
                        <area
                          target=""
                          alt="pooja"
                          data-title={"Pooja Room\n 1'2\" x 3'5\""}
                          href=""
                          data-coords="450,306,463,306,463,346,450,346"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_14"
                        />
                        <area
                          target=""
                          alt="bedroom 2"
                          data-title={"Bedroom 2\n 11'7\" x 11'"}
                          href=""
                          data-coords="661,239,703,239,703,231,803,232,805,92,677,92,677,103,663,104"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_15"
                        />
                        <area
                          target=""
                          alt="bedroom 2 toilet"
                          data-title={"Toilet 2 \n 8' x 5'"}
                          href=""
                          data-coords="709,238,805,235,803,298,709,296"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_16"
                        />
                        <area
                          target=""
                          alt="bedroom 2 wardrobe"
                          data-title={"Bedroom 2 Wardrobe\n 2'10\" x 1'18\""}
                          href=""
                          data-coords="668,277,703,276,703,297,669,295"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_17"
                        />
                        <area
                          target=""
                          alt="bedroom 2 passage"
                          data-title={"Bedroom 2 Passage\n 3'5\" x 3'7\""}
                          href=""
                          data-coords="646,240,703,240,703,275,648,277"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_18"
                        />
                      </map>
                    </>
                  )}
                  {unit === "2" && (
                    <>
                      <img
                        src={unit2Typical}
                        useMap="#image-map"
                        ref={imgRef}
                        style={{
                          maxWidth: "100%",
                          height: "auto",
                          width: "100%",
                        }}
                      />
                      <map name="image-map">
                        <area
                          target=""
                          alt="bedroom 2"
                          data-title={"Bedroom 2\n 11'7\" x 11'"}
                          href=""
                          data-coords="213,89,342,89,339,101,352,101,354,230,211,230"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_1"
                        />
                        <area
                          target=""
                          alt="bedroom 2 toilet"
                          data-title={"Toilet 2 \n 8' x 5'"}
                          href=""
                          data-coords="211,233,309,233,308,292,213,294"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_2"
                        />
                        <area
                          target=""
                          alt="bedroom 2 wardrobe"
                          data-title={"Bedroom 2 Wardrobe\n 2'10\" x 1'18\""}
                          href=""
                          data-coords="312,272,350,272,348,292,313,292"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_3"
                        />
                        <area
                          target=""
                          alt="bedroom 2 passage"
                          data-title={"Bedroom 2 Passage\n 3'5\" x 3'7\""}
                          href=""
                          data-coords="313,230,354,230,355,237,367,237,367,272,313,271"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_4"
                        />
                        <area
                          target=""
                          alt="deck"
                          data-title={"Deck\n 11'2\" x 4'7\""}
                          href=""
                          data-coords="369,51,504,51,507,93,367,93"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_5"
                        />
                        <area
                          target=""
                          alt="passage 2"
                          data-title={"Entrance Foyer\n 4'6\" x 6'3\""}
                          href=""
                          data-coords="369,344,424,344,423,425,367,424"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_6"
                        />
                        <area
                          target=""
                          alt="kitchen"
                          data-title={"Kitchen\n 10'10\" x 8'"}
                          href=""
                          data-coords="427,349,560,346,560,441,427,441"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_7"
                        />
                        <area
                          target=""
                          alt="dry balcony"
                          data-title={"Dry Balcony\n 3'3\" x 8'0\""}
                          href=""
                          data-coords="565,349,604,349,604,448,566,449"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_8"
                        />
                        <area
                          target=""
                          alt="utility / storage"
                          data-title={"Utility/Storage\n 7' x 3'5\""}
                          href=""
                          data-coords="570,302,653,302,654,344,572,345"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_9"
                        />
                        <area
                          target=""
                          alt="living / dining"
                          data-title={
                            "Living / Dining\n 11'4\" x 19'10\" & 3'8\" x 12'2\""
                          }
                          href=""
                          data-coords="367,102,507,101,505,192,550,194,552,342,370,345"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_10"
                        />
                        <area
                          target=""
                          alt="pooja"
                          data-title={"Pooja Room\n 1'2\" x 3'5\""}
                          href=""
                          data-coords="552,300,565,300,565,343,552,342"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_11"
                        />
                        <area
                          target=""
                          alt="common toilet"
                          data-title={"Common Toilet\n 8' x 4'5\""}
                          href=""
                          data-coords="554,240,645,240,645,269,654,269,654,296,556,294"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_12"
                        />
                        <area
                          target=""
                          alt="bedroom 3"
                          data-title={"Bedroom 3\n 11' x 12'"}
                          href=""
                          data-coords="511,40,645,41,645,191,512,190"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_13"
                        />
                        <area
                          target=""
                          alt="passage 1"
                          data-title={"Passage 1\n 5' x 3'5\""}
                          href=""
                          data-coords="550,196,615,196,614,238,552,238"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_14"
                        />
                        <area
                          target=""
                          alt="master bedroom"
                          data-title={"Master Bedroom\n 11' x 12'10\""}
                          href=""
                          data-coords="660,87,677,87,677,74,778,75,779,86,794,89,794,236,663,235"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_15"
                        />
                        <area
                          target=""
                          alt="master wardrobe"
                          data-title={"Master Wardrobe\n 5' x 2'"}
                          href=""
                          data-coords="665,237,728,237,729,260,667,261"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_16"
                        />
                        <area
                          target=""
                          alt="master toilet"
                          data-title={"Master Toilet\n 5' x 8'4\""}
                          href=""
                          data-coords="733,243,794,243,795,308,803,308,803,343,734,344"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_17"
                        />
                        <area
                          target=""
                          alt="master passage"
                          data-title={"Master Passage\n 3'5\" x 3'5\""}
                          href=""
                          data-coords="617,197,660,198,661,235,615,237"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_18"
                        />
                      </map>
                    </>
                  )}
                  {unit === "3" && (
                    <>
                      <img
                        src={unit3Typical}
                        useMap="#image-map"
                        ref={imgRef}
                        style={{
                          maxWidth: "100%",
                          height: "auto",
                          width: "100%",
                        }}
                      />
                      <map name="image-map">
                        <area
                          target=""
                          alt="master bedroom"
                          data-title={"Master Bedroom\n 11' x 12'"}
                          href=""
                          data-coords="562,259,701,260,701,403,561,403"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_1"
                        />
                        <area
                          target=""
                          alt="master toilet"
                          data-title={"Master Toilet\n 5' x 8'"}
                          href=""
                          data-coords="648,152,711,152,711,253,646,253"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_2"
                        />
                        <area
                          target=""
                          alt="master wardrobe"
                          data-title={"Master Wardrobe\n 6'3\" x 2'"}
                          href=""
                          data-coords="565,234,644,232,645,259,565,257"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_3"
                        />
                        <area
                          target=""
                          alt="common toilet"
                          data-title={"Common Toilet\n 5' x 8'"}
                          href=""
                          data-coords="496,152,557,152,557,252,495,252"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_4"
                        />
                        <area
                          target=""
                          alt="master passage"
                          data-title={"Passage\n 5' x 3'5\""}
                          href=""
                          data-coords="491,258,491,300,561,300,561,259"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_5"
                        />
                        <area
                          target=""
                          alt="dry balcony"
                          data-title={"Dry Balcony\n 3'3\" x 7'5\""}
                          href=""
                          data-coords="565,44,599,44,599,146,565,146"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_6"
                        />
                        <area
                          target=""
                          alt="kitchen"
                          data-title={"Kitchen\n 10'6\"  x 8'4\""}
                          href=""
                          data-coords="491,139,491,144,424,144,424,39,557,40,557,140,491,139"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_7"
                        />
                        <area
                          target=""
                          alt="bedroom 2"
                          data-title={"Bedroom 2\n 10' x 12'"}
                          href=""
                          data-coords="428,303,554,304,554,449,428,449"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_8"
                        />
                        <area
                          target=""
                          alt="deck"
                          data-title={"Deck\n 11'2\" x 4'7\""}
                          href=""
                          data-coords="268,403,411,403,411,451,270,449"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_9"
                        />
                        <area
                          target=""
                          alt="living / dining"
                          data-title={
                            "Living/Dining \n 11'2\" x 19'4\" & 6'3\" x 11'8\""
                          }
                          href=""
                          data-coords="268,151,491,151,489,299,413,299,411,398,270,398"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_10"
                        />
                      </map>
                    </>
                  )}
                  {unit === "4" && (
                    <>
                      <img
                        src={unit4Typical}
                        useMap="#image-map"
                        ref={imgRef}
                        style={{
                          maxWidth: "100%",
                          height: "auto",
                          width: "100%",
                        }}
                      />
                      <map name="image-map">
                        <area
                          target=""
                          alt="master bedroom"
                          data-title={"Master Bedroom\n 12'6\" x 11'10\""}
                          href=""
                          data-coords="271,136,407,135,404,262,272,263"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_1"
                        />
                        <area
                          target=""
                          alt="master toilet"
                          data-title={"Master Toilet\n 8'8\" x 5'"}
                          href=""
                          data-coords="244,265,336,265,335,316,243,316"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_2"
                        />
                        <area
                          target=""
                          alt="master passage"
                          data-title={" Passage\n 12'2\" x 3'5\""}
                          href=""
                          data-coords="407,225,537,228,537,262,405,263"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_3"
                        />
                        <area
                          target=""
                          alt="bedroom 2 bathroom"
                          data-title={"Toilet 2\n 5' x 7'5\""}
                          href=""
                          data-coords="281,323,336,323,337,400,282,402"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_4"
                        />
                        <area
                          target=""
                          alt="bedroom 2"
                          data-title={" Bedroom 2 \n 10'10\" x 12'10\""}
                          href=""
                          data-coords="339,265,457,266,455,404,339,406"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_5"
                        />
                        <area
                          target=""
                          alt="bedroom 3"
                          data-title={" Bedroom 3 \n 10'8\" x 12'10\""}
                          href=""
                          data-coords="468,266,580,266,581,395,469,395"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_6"
                        />
                        <area
                          target=""
                          alt="common toilet"
                          data-title={"Common Toilet\n 5' x 8'"}
                          href=""
                          data-coords="480,138,533,138,534,223,481,222"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_7"
                        />
                        <area
                          target=""
                          alt="dry balcony"
                          data-title={"Dry Balcony \n 3'3\" x 8'4\""}
                          href=""
                          data-coords="423,48,454,47,455,131,423,131"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_8"
                        />
                        <area
                          target=""
                          alt="kitchen"
                          data-title={"Kitchen \n 10'6\" x 8'4\""}
                          href=""
                          data-coords="459,43,572,43,572,132,461,131"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_9"
                        />
                        <area
                          target=""
                          alt="deck"
                          data-title={"Deck\n 11'2\" x 4'7\""}
                          href=""
                          data-coords="596,348,713,348,714,387,595,387"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_10"
                        />
                        <area
                          target=""
                          alt="living / dining"
                          data-title={
                            "Living/Dining\n 11'2\" x 19'14\" & 5'5\" x 11'10\""
                          }
                          href=""
                          data-coords="539,132,714,134,715,341,596,342,596,261,538,261"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_11"
                        />
                      </map>
                    </>
                  )}
                </>
              )}
              {plan === "refugee" && (
                <>
                  {unit === "1" && (
                    <>
                      {/* Change Unit 1 Refugee Image here */}
                      <img
                        src={unit1Refugee}
                        useMap="#image-map"
                        ref={imgRef}
                        style={{
                          maxWidth: "100%",
                          height: "auto",
                          width: "100%",
                        }}
                      />
                      <map name="image-map">
                        <area
                          target=""
                          alt="master bedroom"
                          data-title={"Master Bedroom\n 11' x 12'10\""}
                          href=""
                          data-coords="238,246,235,89,358,84,358,90,375,90,378,246"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_1_1"
                        />
                        <area
                          target=""
                          alt="master passage"
                          data-title={"Master Bedroom Passage\n 2'4\" x 3'5\""}
                          href=""
                          data-coords="377,244,377,203,410,206,412,248"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_1_2"
                        />
                        <area
                          target=""
                          alt="master wardrobe"
                          data-title={"Master Wardrobe\n 5' x 2'"}
                          href=""
                          data-coords="308,272,370,274,369,246,305,246"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_1_3"
                        />
                        <area
                          target=""
                          alt="master toilet"
                          data-title={"Master Toilet\n 5' x 8'4\""}
                          href=""
                          data-coords="228,324,228,356,300,358,298,252,237,250,237,319"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_1_4"
                        />
                        <area
                          target=""
                          alt="common bathroom"
                          data-title={"Common Toilet\n 5'0\" x 8'4\""}
                          href=""
                          data-coords="392,253,456,253,454,358,385,358,386,282,395,278"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_1_5"
                        />
                        <area
                          target=""
                          alt="dry balcony"
                          data-title={"Dry Balcony\n 3'3\" x 8'0\""}
                          href=""
                          data-coords="439,468,475,468,476,368,439,369"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_1_7"
                        />
                        <area
                          target=""
                          alt="pooja room"
                          data-title={"Study Room\n 6'8\" x 8'4\""}
                          href=""
                          data-coords="460,357,538,357,536,253,461,253"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_1_8"
                        />
                        <area
                          target=""
                          alt="passage 1"
                          data-title={"Entrance foyer\n 4'6\" x 6'3\""}
                          href=""
                          data-coords="635,444,677,445,677,437,684,438,683,356,628,360,627,386,628,429,629,437"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_1_9"
                        />
                        <area
                          target=""
                          alt="kitchen"
                          data-title={"Kitchen\n 10'10\" x 8'"}
                          href=""
                          data-coords="484,463,620,462,621,365,486,366"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_1_10"
                        />
                        <area
                          target=""
                          alt="deck"
                          data-title={"Deck\n 11'2\" x 4'7\""}
                          href=""
                          data-coords="542,95,682,95,681,52,543,51"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_1_13"
                        />
                        <area
                          target=""
                          alt="living / dining"
                          data-title={"Living/Dining\n 11'4\" x 19'10\""}
                          href=""
                          data-coords="539,248,539,203,543,197,540,104,683,105,684,357,627,360,540,356"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_1_14"
                        />
                        <area
                          target=""
                          alt="bedroom 2"
                          data-title={"Bedroom 2\n 11' x 12'"}
                          href=""
                          data-coords="395,198,533,196,533,44,394,44"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_1_15"
                        />
                        <area
                          target=""
                          alt="bedroom 2 passage"
                          data-title={"Passage\n 10'0\" x 3'5\""}
                          href=""
                          data-coords="416,247,534,246,536,204,418,205"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_1_16"
                        />
                      </map>
                    </>
                  )}
                  {unit === "2" && (
                    <>
                      {/* Change Unit 2 Refugee Image here */}
                      <img
                        src={unit2Refugee}
                        useMap="#image-map"
                        ref={imgRef}
                        style={{
                          maxWidth: "100%",
                          height: "auto",
                          width: "100%",
                        }}
                      />
                      <map name="image-map">
                        <area
                          target=""
                          alt="master bedroom"
                          data-title={"Master Bedroom\n 11' x 12'10\""}
                          href=""
                          data-coords="567,237,567,91,581,92,584,87,679,86,681,94,696,91,696,237"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_2_1"
                        />
                        <area
                          target=""
                          alt="master passage"
                          data-title={"Master Bedroom Passage\n 2'4\" x 3'5\""}
                          href=""
                          data-coords="534,201,565,199,565,236,533,239"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_2_2"
                        />
                        <area
                          target=""
                          alt="master toilet"
                          data-title={"Master Wardrobe\n 5' x 8'4\""}
                          href=""
                          data-coords="641,343,704,343,704,312,696,310,696,244,640,245"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_2_3"
                        />
                        <area
                          target=""
                          alt="master wardrobe"
                          data-title={"Master Wardrobe\n 5' x 2'"}
                          href=""
                          data-coords="572,239,574,262,632,263,633,239"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_2_4"
                        />
                        <area
                          target=""
                          alt="common bathroom"
                          data-title={"Common Toilet\n 5'0\" x 8'4\""}
                          href=""
                          data-coords="495,342,559,341,557,270,548,269,548,243,494,244"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_2_5"
                        />
                        <area
                          target=""
                          alt="passage 1"
                          data-title={"Entrance foyer\n 4'6\" x 6'3\""}
                          href=""
                          data-coords="327,419,277,418,278,345,332,344,329,372,332,408"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_2_6"
                        />
                        <area
                          target=""
                          alt="dry balcony"
                          data-title={"Dry Balcony\n 3'3\" x 8'0\""}
                          href=""
                          data-coords="473,448,511,449,510,352,473,350"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_2_9"
                        />
                        <area
                          target=""
                          alt="kitchen"
                          data-title={"Kitchen\n 10'10\" x 8'"}
                          href=""
                          data-coords="337,442,336,349,465,350,464,442"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_2_10"
                        />
                        <area
                          target=""
                          alt="passage 2"
                          data-title={"Passage\n 10'0\" x 3'5\""}
                          href=""
                          data-coords="528,239,414,238,414,200,528,199"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_2_11"
                        />
                        <area
                          target=""
                          alt="bedroom 2"
                          data-title={"Bedroom 2\n 11' x 12'"}
                          href=""
                          data-coords="421,191,549,191,548,49,420,48"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_2_12"
                        />
                        <area
                          target=""
                          alt="bedroom 2 passage"
                          data-title={"Study Room\n 6'8\" x 8'4\""}
                          href=""
                          data-coords="414,243,485,243,487,343,412,343"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_2_15"
                        />
                        <area
                          target=""
                          alt="deck"
                          data-title={"Deck\n 11'2\" x 4'7\""}
                          href=""
                          data-coords="278,95,279,54,410,54,409,96"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_2_16"
                        />
                        <area
                          target=""
                          alt="living / dining"
                          data-title={"Living/Dining\n 11'4\" x 19'10\""}
                          href=""
                          data-coords="330,343,412,342,413,241,411,106,278,102,280,342"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_2_17"
                        />
                      </map>
                    </>
                  )}
                  {unit === "3" && (
                    <>
                      <img
                        src={unit3Typical}
                        useMap="#image-map"
                        ref={imgRef}
                        style={{
                          maxWidth: "100%",
                          height: "auto",
                          width: "100%",
                        }}
                      />
                      <map name="image-map">
                        <area
                          target=""
                          alt="master bedroom"
                          data-title={"Master Bedroom\n 11' x 12'"}
                          href=""
                          data-coords="562,259,701,260,701,403,561,403"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_1"
                        />
                        <area
                          target=""
                          alt="master toilet"
                          data-title={"Master Toilet\n 5' x 8'"}
                          href=""
                          data-coords="648,152,711,152,711,253,646,253"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_2"
                        />
                        <area
                          target=""
                          alt="master wardrobe"
                          data-title={"Master Wardrobe\n 6'3\" x 2'"}
                          href=""
                          data-coords="565,234,644,232,645,259,565,257"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_3"
                        />
                        <area
                          target=""
                          alt="common toilet"
                          data-title={"Common Toilet\n 5' x 8'"}
                          href=""
                          data-coords="496,152,557,152,557,252,495,252"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_4"
                        />
                        <area
                          target=""
                          alt="master passage"
                          data-title={"Passage\n 5' x 3'5\""}
                          href=""
                          data-coords="491,258,491,300,561,300,561,259"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_5"
                        />
                        <area
                          target=""
                          alt="dry balcony"
                          data-title={"Dry Balcony\n 3'3\" x 7'5\""}
                          href=""
                          data-coords="565,44,599,44,599,146,565,146"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_6"
                        />
                        <area
                          target=""
                          alt="kitchen"
                          data-title={"Kitchen\n 10'6\"  x 8'4\""}
                          href=""
                          data-coords="491,139,491,144,424,144,424,39,557,40,557,140,491,139"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_7"
                        />
                        <area
                          target=""
                          alt="bedroom 2"
                          data-title={"Bedroom 2\n 10' x 12'"}
                          href=""
                          data-coords="428,303,554,304,554,449,428,449"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_8"
                        />
                        <area
                          target=""
                          alt="deck"
                          data-title={"Deck\n 11'2\" x 4'7\""}
                          href=""
                          data-coords="268,403,411,403,411,451,270,449"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_9"
                        />
                        <area
                          target=""
                          alt="living / dining"
                          data-title={
                            "Living/Dining \n 11'2\" x 19'4\" & 6'3\" x 11'8\""
                          }
                          href=""
                          data-coords="268,151,491,151,489,299,413,299,411,398,270,398"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_10"
                        />
                      </map>
                    </>
                  )}
                  {unit === "4" && (
                    <>
                      <img
                        src={unit4Typical}
                        useMap="#image-map"
                        ref={imgRef}
                        style={{
                          maxWidth: "100%",
                          height: "auto",
                          width: "100%",
                        }}
                      />
                      <map name="image-map">
                        <area
                          target=""
                          alt="master bedroom"
                          data-title={"Master Bedroom\n 12'6\" x 11'10\""}
                          href=""
                          data-coords="271,136,407,135,404,262,272,263"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_1"
                        />
                        <area
                          target=""
                          alt="master toilet"
                          data-title={"Master Toilet\n 8'8\" x 5'"}
                          href=""
                          data-coords="244,265,336,265,335,316,243,316"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_2"
                        />
                        <area
                          target=""
                          alt="master passage"
                          data-title={" Passage\n 12'2\" x 3'5\""}
                          href=""
                          data-coords="407,225,537,228,537,262,405,263"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_3"
                        />
                        <area
                          target=""
                          alt="bedroom 2 bathroom"
                          data-title={"Toilet 2\n 5' x 7'5\""}
                          href=""
                          data-coords="281,323,336,323,337,400,282,402"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_4"
                        />
                        <area
                          target=""
                          alt="bedroom 2"
                          data-title={" Bedroom 2 \n 10'10\" x 12'10\""}
                          href=""
                          data-coords="339,265,457,266,455,404,339,406"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_5"
                        />
                        <area
                          target=""
                          alt="bedroom 3"
                          data-title={" Bedroom 3 \n 10'8\" x 12'10\""}
                          href=""
                          data-coords="468,266,580,266,581,395,469,395"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_6"
                        />
                        <area
                          target=""
                          alt="common toilet"
                          data-title={"Common Toilet\n 5' x 8'"}
                          href=""
                          data-coords="480,138,533,138,534,223,481,222"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_7"
                        />
                        <area
                          target=""
                          alt="dry balcony"
                          data-title={"Dry Balcony \n 3'3\" x 8'4\""}
                          href=""
                          data-coords="423,48,454,47,455,131,423,131"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_8"
                        />
                        <area
                          target=""
                          alt="kitchen"
                          data-title={"Kitchen \n 10'6\" x 8'4\""}
                          href=""
                          data-coords="459,43,572,43,572,132,461,131"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_9"
                        />
                        <area
                          target=""
                          alt="deck"
                          data-title={"Deck\n 11'2\" x 4'7\""}
                          href=""
                          data-coords="596,348,713,348,714,387,595,387"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_10"
                        />
                        <area
                          target=""
                          alt="living / dining"
                          data-title={
                            "Living/Dining\n 11'2\" x 19'14\" & 5'5\" x 11'10\""
                          }
                          href=""
                          data-coords="539,132,714,134,715,341,596,342,596,261,538,261"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_11"
                        />
                      </map>
                    </>
                  )}
                </>
              )}
              {plan === "second" && (
                <>
                  {unit === "1" && (
                    <>
                      <img
                        src={unit1Typical}
                        useMap="#image-map"
                        ref={imgRef}
                        style={{
                          maxWidth: "100%",
                          height: "auto",
                          width: "100%",
                        }}
                      />
                      <map name="image-map">
                        <area
                          target=""
                          alt="master bedroom"
                          data-title={"Master Bedroom\n 11' x 12'10\""}
                          href=""
                          data-coords="220,91,239,91,237,77,339,77,339,91,355,91,355,199,355,239,221,239"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_1"
                        />
                        <area
                          target=""
                          alt="master passage"
                          data-title={"Master Passage\n 3'5\" x 3'5\""}
                          href=""
                          data-coords="355,199,398,198,398,240,356,240"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_2"
                        />
                        <area
                          target=""
                          alt="master wardrobe"
                          data-title={"Master Wardrobe\n 5' x 2'"}
                          href=""
                          data-coords="286,240,348,239,348,264,287,264"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_3"
                        />
                        <area
                          target=""
                          alt="master toilet"
                          data-title={"Master Toilet\n 5' x 8'4\""}
                          href=""
                          data-coords="221,242,281,244,281,347,214,345,211,310,222,310"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_4"
                        />
                        <area
                          target=""
                          alt="common toilet"
                          data-title={"Common Toilet\n 8' x 4'5\""}
                          href=""
                          data-coords="369,245,458,245,458,296,355,298,355,271,370,271"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_5"
                        />
                        <area
                          target=""
                          alt="utility / storage"
                          data-title={"Utility/Storage\n 7' x 3'5\""}
                          href=""
                          data-coords="446,348,363,346,360,305,446,304"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_6"
                        />
                        <area
                          target=""
                          alt="dry balcony"
                          data-title={"Dry Balcony\n 3'3\" x 8'0\""}
                          href=""
                          data-coords="411,353,450,353,450,453,411,453"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_7"
                        />
                        <area
                          target=""
                          alt="kitchen"
                          data-title={"Kitchen\n 10'10\" x 8'"}
                          href=""
                          data-coords="455,352,589,352,588,447,457,447"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_8"
                        />
                        <area
                          target=""
                          alt="passage 2"
                          data-title={"Entrance Foyer\n 4'6\" x 6'3\""}
                          href=""
                          data-coords="594,348,646,348,648,428,594,427"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_9"
                        />
                        <area
                          target=""
                          alt="bedroom 3"
                          data-title={"Bedroom 3\n 11' x 12'"}
                          href=""
                          data-coords="370,42,504,42,504,194,370,194"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_10"
                        />
                        <area
                          target=""
                          alt="passage 1"
                          data-title={"Passage 1\n 5' x 3'5\""}
                          href=""
                          data-coords="401,199,462,199,462,240,401,239"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_11"
                        />
                        <area
                          target=""
                          alt="deck"
                          data-title={"Deck\n 11'2\" x 4'7\""}
                          href=""
                          data-coords="512,53,648,54,648,97,512,97"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_12"
                        />
                        <area
                          target=""
                          alt="living / dining"
                          data-title={
                            "Living / Dining\n 11'4\" x 19'10\" & 3'8\" x 12'2\""
                          }
                          href=""
                          data-coords="463,200,511,199,510,104,648,104,646,346,465,345"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_13"
                        />
                        <area
                          target=""
                          alt="pooja"
                          data-title={"Pooja Room\n 1'2\" x 3'5\""}
                          href=""
                          data-coords="450,306,463,306,463,346,450,346"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_14"
                        />
                        <area
                          target=""
                          alt="bedroom 2"
                          data-title={"Bedroom 2\n 11'7\" x 11'"}
                          href=""
                          data-coords="661,239,703,239,703,231,803,232,805,92,677,92,677,103,663,104"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_15"
                        />
                        <area
                          target=""
                          alt="bedroom 2 toilet"
                          data-title={"Toilet 2 \n 8' x 5'"}
                          href=""
                          data-coords="709,238,805,235,803,298,709,296"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_16"
                        />
                        <area
                          target=""
                          alt="bedroom 2 wardrobe"
                          data-title={"Bedroom 2 Wardrobe\n 2'10\" x 1'18\""}
                          href=""
                          data-coords="668,277,703,276,703,297,669,295"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_17"
                        />
                        <area
                          target=""
                          alt="bedroom 2 passage"
                          data-title={"Bedroom 2 Passage\n 3'5\" x 3'7\""}
                          href=""
                          data-coords="646,240,703,240,703,275,648,277"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_18"
                        />
                      </map>
                    </>
                  )}
                  {unit === "2" && (
                    <>
                      <img
                        src={unit2Typical}
                        useMap="#image-map"
                        ref={imgRef}
                        style={{
                          maxWidth: "100%",
                          height: "auto",
                          width: "100%",
                        }}
                      />
                      <map name="image-map">
                        <area
                          target=""
                          alt="bedroom 2"
                          data-title={"Bedroom 2\n 11'7\" x 11'"}
                          href=""
                          data-coords="213,89,342,89,339,101,352,101,354,230,211,230"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_1"
                        />
                        <area
                          target=""
                          alt="bedroom 2 toilet"
                          data-title={"Toilet 2 \n 8' x 5'"}
                          href=""
                          data-coords="211,233,309,233,308,292,213,294"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_2"
                        />
                        <area
                          target=""
                          alt="bedroom 2 wardrobe"
                          data-title={"Bedroom 2 Wardrobe\n 2'10\" x 1'18\""}
                          href=""
                          data-coords="312,272,350,272,348,292,313,292"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_3"
                        />
                        <area
                          target=""
                          alt="bedroom 2 passage"
                          data-title={"Bedroom 2 Passage\n 3'5\" x 3'7\""}
                          href=""
                          data-coords="313,230,354,230,355,237,367,237,367,272,313,271"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_4"
                        />
                        <area
                          target=""
                          alt="deck"
                          data-title={"Deck\n 11'2\" x 4'7\""}
                          href=""
                          data-coords="369,51,504,51,507,93,367,93"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_5"
                        />
                        <area
                          target=""
                          alt="passage 2"
                          data-title={"Entrance Foyer\n 4'6\" x 6'3\""}
                          href=""
                          data-coords="369,344,424,344,423,425,367,424"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_6"
                        />
                        <area
                          target=""
                          alt="kitchen"
                          data-title={"Kitchen\n 10'10\" x 8'"}
                          href=""
                          data-coords="427,349,560,346,560,441,427,441"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_7"
                        />
                        <area
                          target=""
                          alt="dry balcony"
                          data-title={"Dry Balcony\n 3'3\" x 8'0\""}
                          href=""
                          data-coords="565,349,604,349,604,448,566,449"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_8"
                        />
                        <area
                          target=""
                          alt="utility / storage"
                          data-title={"Utility/Storage\n 7' x 3'5\""}
                          href=""
                          data-coords="570,302,653,302,654,344,572,345"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_9"
                        />
                        <area
                          target=""
                          alt="living / dining"
                          data-title={
                            "Living / Dining\n 11'4\" x 19'10\" & 3'8\" x 12'2\""
                          }
                          href=""
                          data-coords="367,102,507,101,505,192,550,194,552,342,370,345"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_10"
                        />
                        <area
                          target=""
                          alt="pooja"
                          data-title={"Pooja Room\n 1'2\" x 3'5\""}
                          href=""
                          data-coords="552,300,565,300,565,343,552,342"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_11"
                        />
                        <area
                          target=""
                          alt="common toilet"
                          data-title={"Common Toilet\n 8' x 4'5\""}
                          href=""
                          data-coords="554,240,645,240,645,269,654,269,654,296,556,294"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_12"
                        />
                        <area
                          target=""
                          alt="bedroom 3"
                          data-title={"Bedroom 3\n 11' x 12'"}
                          href=""
                          data-coords="511,40,645,41,645,191,512,190"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_13"
                        />
                        <area
                          target=""
                          alt="passage 1"
                          data-title={"Passage 1\n 5' x 3'5\""}
                          href=""
                          data-coords="550,196,615,196,614,238,552,238"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_14"
                        />
                        <area
                          target=""
                          alt="master bedroom"
                          data-title={"Master Bedroom\n 11' x 12'10\""}
                          href=""
                          data-coords="660,87,677,87,677,74,778,75,779,86,794,89,794,236,663,235"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_15"
                        />
                        <area
                          target=""
                          alt="master wardrobe"
                          data-title={"Master Wardrobe\n 5' x 2'"}
                          href=""
                          data-coords="665,237,728,237,729,260,667,261"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_16"
                        />
                        <area
                          target=""
                          alt="master toilet"
                          data-title={"Master Toilet\n 5' x 8'4\""}
                          href=""
                          data-coords="733,243,794,243,795,308,803,308,803,343,734,344"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_17"
                        />
                        <area
                          target=""
                          alt="master passage"
                          data-title={"Master Passage\n 3'5\" x 3'5\""}
                          href=""
                          data-coords="617,197,660,198,661,235,615,237"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_18"
                        />
                      </map>
                    </>
                  )}
                  {unit === "3" && (
                    <>
                      <img
                        src={unit3Typical}
                        useMap="#image-map"
                        ref={imgRef}
                        style={{
                          maxWidth: "100%",
                          height: "auto",
                          width: "100%",
                        }}
                      />
                      <map name="image-map">
                        <area
                          target=""
                          alt="master bedroom"
                          data-title={"Master Bedroom\n 11' x 12'"}
                          href=""
                          data-coords="562,259,701,260,701,403,561,403"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_1"
                        />
                        <area
                          target=""
                          alt="master toilet"
                          data-title={"Master Toilet\n 5' x 8'"}
                          href=""
                          data-coords="648,152,711,152,711,253,646,253"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_2"
                        />
                        <area
                          target=""
                          alt="master wardrobe"
                          data-title={"Master Wardrobe\n 6'3\" x 2'"}
                          href=""
                          data-coords="565,234,644,232,645,259,565,257"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_3"
                        />
                        <area
                          target=""
                          alt="common toilet"
                          data-title={"Common Toilet\n 5' x 8'"}
                          href=""
                          data-coords="496,152,557,152,557,252,495,252"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_4"
                        />
                        <area
                          target=""
                          alt="master passage"
                          data-title={"Passage\n 5' x 3'5\""}
                          href=""
                          data-coords="491,258,491,300,561,300,561,259"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_5"
                        />
                        <area
                          target=""
                          alt="dry balcony"
                          data-title={"Dry Balcony\n 3'3\" x 7'5\""}
                          href=""
                          data-coords="565,44,599,44,599,146,565,146"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_6"
                        />
                        <area
                          target=""
                          alt="kitchen"
                          data-title={"Kitchen\n 10'6\"  x 8'4\""}
                          href=""
                          data-coords="491,139,491,144,424,144,424,39,557,40,557,140,491,139"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_7"
                        />
                        <area
                          target=""
                          alt="bedroom 2"
                          data-title={"Bedroom 2\n 10' x 12'"}
                          href=""
                          data-coords="428,303,554,304,554,449,428,449"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_8"
                        />
                        <area
                          target=""
                          alt="deck"
                          data-title={"Deck\n 11'2\" x 4'7\""}
                          href=""
                          data-coords="268,403,411,403,411,451,270,449"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_9"
                        />
                        <area
                          target=""
                          alt="living / dining"
                          data-title={
                            "Living/Dining \n 11'2\" x 19'4\" & 6'3\" x 11'8\""
                          }
                          href=""
                          data-coords="268,151,491,151,489,299,413,299,411,398,270,398"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_10"
                        />
                      </map>
                    </>
                  )}
                </>
              )}
              {/* <img src={unitatwod} usemap="#image-map" ref={imgRef} style={{ maxWidth: '100%', height: 'auto', width: '100%' }} 
                            className="rounded-lg"/>
                            <map name="image-map">
                                <area target="" alt="" href="#!" data-coords="204,94,200,120,278,123,282,94" shape="poly" onClick={isTouchDevice ? handleAreaClick : undefined} onMouseLeave={!isTouchDevice ? handleAreaMouseLeave : undefined} onMouseEnter={!isTouchDevice ? handleAreaMouseEnter : undefined} id="mainImage_1_1" title="Master Balcony 7\'0&quot; &#10; x 4\'0&quot;" />
                                <area target="" alt="" href="#!" data-coords="148,237,176,123,299,124,288,238" shape="poly" onClick={isTouchDevice ? handleAreaClick : undefined} onMouseLeave={!isTouchDevice ? handleAreaMouseLeave : undefined} onMouseEnter={!isTouchDevice ? handleAreaMouseEnter : undefined} id="mainImage_1_2" title="Master Bedroom 11\'0&quot; &#10; x 15\'0&quot;" />
                                <area target="" alt="" href="#!" data-coords="231,287,344,288,346,241,242,241" shape="poly" onClick={isTouchDevice ? handleAreaClick : undefined} onMouseLeave={!isTouchDevice ? handleAreaMouseLeave : undefined} onMouseEnter={!isTouchDevice ? handleAreaMouseEnter : undefined} id="mainImage_1_3" title="Master Toilet 8\'0&quot; &#10; x 4\'6&quot;" />
                                <area target="" alt="" href="#!" data-coords="290,238,334,239,336,212,292,210" shape="poly" onClick={isTouchDevice ? handleAreaClick : undefined} onMouseLeave={!isTouchDevice ? handleAreaMouseLeave : undefined} onMouseEnter={!isTouchDevice ? handleAreaMouseEnter : undefined} id="mainImage_1_4" title="Master Bedroom Passage 3\'7&quot; &#10; x 3\'3&quot;" />
                                <area target="" alt="" href="#!" data-coords="336,238,416,239,416,209,336,210" shape="poly" onClick={isTouchDevice ? handleAreaClick : undefined} onMouseLeave={!isTouchDevice ? handleAreaMouseLeave : undefined} onMouseEnter={!isTouchDevice ? handleAreaMouseEnter : undefined} id="mainImage_1_5" title="Passage 6\'9&quot; &#10; x 3\'3&quot;" />
                                <area target="" alt="" href="#!" data-coords="292,208,416,208,415,120,303,118" shape="poly" onClick={isTouchDevice ? handleAreaClick : undefined} onMouseLeave={!isTouchDevice ? handleAreaMouseLeave : undefined} onMouseEnter={!isTouchDevice ? handleAreaMouseEnter : undefined} id="mainImage_1_6" title="Bedroom 2 10\'0&quot; &#10; x 12\'0&quot;" />
                                <area target="" alt="" href="#!" data-coords="342,316,412,317,408,240,349,240" shape="poly" onClick={isTouchDevice ? handleAreaClick : undefined} onMouseLeave={!isTouchDevice ? handleAreaMouseLeave : undefined} onMouseEnter={!isTouchDevice ? handleAreaMouseEnter : undefined} id="mainImage_1_7" title="Toilet 4\'6&quot; &#10; x 8\'0&quot;" />
                                <area target="" alt="" href="#!" data-coords="376,363,460,365,459,305,420,303,421,320,376,321" shape="poly" onClick={isTouchDevice ? handleAreaClick : undefined} onMouseLeave={!isTouchDevice ? handleAreaMouseLeave : undefined} onMouseEnter={!isTouchDevice ? handleAreaMouseEnter : undefined} id="mainImage_1_8" title="Utility 5\'11&quot; &#10; x 3\'10&quot;" />
                                <area target="" alt="" href="#!" data-coords="462,382,634,385,618,302,460,302" shape="poly" onClick={isTouchDevice ? handleAreaClick : undefined} onMouseLeave={!isTouchDevice ? handleAreaMouseLeave : undefined} onMouseEnter={!isTouchDevice ? handleAreaMouseEnter : undefined} id="mainImage_1_9" title="Kitchen 11\'4&quot; &#10; x 7\'6&quot;" />
                                <area target="" alt="" href="#!" data-coords="420,298,416,138,433,139,520,136,536,138,556,250,559,302" shape="poly" onClick={isTouchDevice ? handleAreaClick : undefined} onMouseLeave={!isTouchDevice ? handleAreaMouseLeave : undefined} onMouseEnter={!isTouchDevice ? handleAreaMouseEnter : undefined} id="mainImage_1_10" title="Living/Dining 11\'0&quot; &#10; x 20\'0&quot;" />
                                <area target="" alt="" href="#!" data-coords="607,252,556,251,560,301,617,303" shape="poly" onClick={isTouchDevice ? handleAreaClick : undefined} onMouseLeave={!isTouchDevice ? handleAreaMouseLeave : undefined} onMouseEnter={!isTouchDevice ? handleAreaMouseEnter : undefined} id="mainImage_1_11" title="Entrance Foyer 4\'0&quot; &#10; x 5\'3&quot;" />
                                <area target="" alt="" href="#!" data-coords="542,146,660,147,684,249,557,248" shape="poly" onClick={isTouchDevice ? handleAreaClick : undefined} onMouseLeave={!isTouchDevice ? handleAreaMouseLeave : undefined} onMouseEnter={!isTouchDevice ? handleAreaMouseEnter : undefined} id="mainImage_1_12" title=" Bedroom 1 10\'0&quot; &#10; x 13\'0&quot;" />
                                <area target="" alt="" href="#!" data-coords="431,102,436,137,522,134,516,102" shape="poly" onClick={isTouchDevice ? handleAreaClick : undefined} onMouseLeave={!isTouchDevice ? handleAreaMouseLeave : undefined} onMouseEnter={!isTouchDevice ? handleAreaMouseEnter : undefined} id="mainImage_1_13" title=" Balcony 7\'9&quot; &#10; x 5\'0&quot;" />
                                <area target="" alt="" href="#!" data-coords="682,232,745,235,722,166,666,166" shape="poly" onClick={isTouchDevice ? handleAreaClick : undefined} onMouseLeave={!isTouchDevice ? handleAreaMouseLeave : undefined} onMouseEnter={!isTouchDevice ? handleAreaMouseEnter : undefined} id="mainImage_1_14" title=" Toilet 4\'6&quot; &#10; x 8\'0&quot;" />
                            </map> */}
              {activeArea && (
                <svg
                  className="absolute pointer-events-none"
                  xmlns="http://www.w3.org/2000/svg"
                  width={imageSize.width}
                  height={imageSize.height}
                  style={{
                    top: `${imageSize.top}px`,
                    left: `${imageSize.left}px`,
                  }}
                >
                  <polygon
                    className="highlight"
                    points={activeArea.join(",")}
                  />

                  {(() => {
                    // Split text into multiple lines with maximum 15 characters per line
                    const lines = activeAreaName.split(" ").reduce(
                      (acc, word) => {
                        const lastLine = acc[acc.length - 1] || "";
                        if ((lastLine + " " + word).length <= 25) {
                          acc[acc.length - 1] = lastLine
                            ? `${lastLine} ${word}`
                            : word;
                        } else {
                          acc.push(word);
                        }
                        return acc;
                      },
                      [""]
                    );

                    // Calculate width and height for the rect based on longest line and number of lines
                    const maxLineWidth = Math.max(
                      ...lines.map((line) => line.length * 9)
                    );
                    const rectWidth = maxLineWidth + paddingX * 2;
                    const rectHeight = lines.length * 20 + paddingY * 2;

                    // X and Y coordinates for positioning the rect
                    const rectX =
                      Math.max(
                        ...activeArea.filter((_, index) => index % 2 === 0)
                      ) + 5;
                    const rectY =
                      Math.min(
                        ...activeArea.filter((_, index) => index % 2 !== 0)
                      ) -
                      25 -
                      paddingY;

                    // Adjust rectX to prevent overflow beyond the right boundary of the image
                    const adjustedRectX =
                      rectX + rectWidth > imageSize.width
                        ? imageSize.width - rectWidth - 5
                        : rectX;

                    return (
                      <>
                        {/* Background rect with calculated dimensions */}
                        <rect
                          x={adjustedRectX}
                          y={rectY}
                          width={rectWidth}
                          height={rectHeight}
                          fill="#d8b387"
                          rx="5"
                          ry="5"
                        />

                        {/* Render each line of text with calculated positioning */}
                        {lines.map((line, index) => (
                          <text
                            key={index}
                            x={adjustedRectX + paddingX}
                            y={rectY + paddingY + 16 + index * 16}
                            fill="white"
                            fontSize="14"
                            fontFamily="Outfit, sans-serif"
                            textAnchor="start"
                          >
                            {line}
                          </text>
                        ))}
                      </>
                    );
                  })()}
                </svg>
              )}
            </div>
            <div
              className="w-full xl:w-2/12  lg:w-2/12 md:w-12/12 w-full rounded-lg mt-6 xl:mt-0 lg:mt-0 "
              // style={{ boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px" }}
            >
              <div
                className="flex xl:flex-wrap rounded-lg border lg:flex-wrap xl:gap-0 lg:gap-0 gap-4 items-center justify-between xl:py-6 py-6 xl:px-0 px-2"
                style={{
                  background: "#f0eeee",
                  boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                }}
              >
                <div className="xl:pt-4 w-full xl:w-9/12 mx-auto">
                  <div
                    className="cursor-pointer font text-sm font-semibold text-center text-white py-2 rounded bgbutton"
                    onClick={() => history.goBack()}
                  >
                    Go Back
                  </div>
                </div>
                <div className="xl:pt-2 pt-2 xl:block lg:block block w-full xl:w-9/12 mx-auto">
                  {/* <div className="font text-sm font-semibold text-center text-white py-2 rounded bgbutton">
                    Zoom Image
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
