/*eslint-disable*/
import React, { useState } from "react";
import IndexNavbarr from "components/Navbars/IndexNavbarr.js";
import Footer from "components/Footers/Footer.js";

import gallery1 from "assets/img/gallery/gallery1.webp";
import gallery2 from "assets/img/gallery/gallery2.webp";
import gallery3 from "assets/img/gallery/gallery3.webp";
import gallery4 from "assets/img/gallery/gallery4.webp";
import imagee from "assets/img/gallery/imagee.png";
import gallery6 from "assets/img/gallery/gallery6.webp";
import gallery7 from "assets/img/gallery/gallery7.webp";
import gallery8 from "assets/img/gallery/gallery8.webp";
import gallery9 from "assets/img/gallery/gallery9.webp";  // Gallery9 image to display in original size
import gallery10 from "assets/img/gallery/gallery10.webp";
import gallery11 from "assets/img/gallery/gallery11.webp";
import gallery12 from "assets/img/gallery/gallery12.webp";

const exteriorImages = [
  gallery3, imagee, gallery4, gallery9, gallery7, gallery10, gallery11 
];

const interiorImages = [
  gallery1, gallery2, gallery8, gallery12, gallery6
];

export default function Index() {
  const [isOpen, setIsOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState("exterior");

  const openModal = (index) => {
    setCurrentIndex(index);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const showNextImage = () => {
    const images = selectedCategory === "exterior" ? exteriorImages : interiorImages;
    setCurrentIndex((currentIndex + 1) % images.length);
  };

  const showPreviousImage = () => {
    const images = selectedCategory === "exterior" ? exteriorImages : interiorImages;
    setCurrentIndex((currentIndex - 1 + images.length) % images.length);
  };

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    setCurrentIndex(0); // Reset to the first image of the new category
  };

  const imagesToShow = selectedCategory === "exterior" ? exteriorImages : interiorImages;

  return (
    <>
      <IndexNavbarr fixed />

      <section className="xl:py-12 py-12 min-h-screen flex justify-center items-center">
        <div className="container mx-auto px-4">
          <div className="w-full xl:pt-24 lg:pt-12 md:pt-32 pt-24">
            <div className="flex justify-center xl:gap-4 gap-4">
              <div
                className={`cursor-pointer bgbutton font-semibold rounded-lg py-2 xl:w-2/12 lg:w-3/12 md:w-3/12 w-6/12 text-center text-white xl:text-sm font ${selectedCategory === "exterior" ? "bg-blue-500" : ""}`}
                onClick={() => handleCategoryChange("exterior")}
              >
                Exterior
              </div>
              <div
                className={`cursor-pointer bgbutton font-semibold rounded-lg py-2 xl:w-2/12 lg:w-3/12 md:w-3/12 w-6/12 text-center text-white xl:text-sm font ${selectedCategory === "interior" ? "bg-blue-500" : ""}`}
                onClick={() => handleCategoryChange("interior")}
              >
                Interior
              </div>
            </div>
          </div>
          <div className="flex flex-wrap py-6">
            {/* Check if selectedCategory is exterior */}
            {selectedCategory === "exterior" && (
              <>
                {/* Left column: first 3 images */}
                <div className="w-4/12 px-1 py-2">
                  {imagesToShow.slice(0, 3).map((image, index) => (
                    <img
                      key={index}
                      src={image}
                      alt=""
                      className={`rounded-lg w-full xl:h-270-px lg:h-270-px  my-2 cursor-pointer ${image === gallery9 ? "gallery9-image" : ""}`}
                      onClick={() => openModal(index)}
                    />
                  ))}
                </div>

                {/* Center column: 4th image */}
                <div className="w-4/12 px-1 py-2 flex justify-center">
                  {imagesToShow[3] && (
                    <img
                      src={imagesToShow[3]}
                      alt=""
                      className={`rounded-lg w-full cursor-pointer ${imagesToShow[3] === gallery9 ? "gallery9-image" : ""}`}
                      onClick={() => openModal(3)}
                    />
                  )}
                </div>

                {/* Right column: remaining images */}
                <div className="w-4/12 px-1 py-2">
                  {imagesToShow.slice(4).map((image, index) => (
                    <img
                      key={index + 4} // Adjust index for correct keys
                      src={image}
                      alt=""
                      className={`rounded-lg w-full xl:h-270-px lg:h-270-px my-2 cursor-pointer ${image === gallery9 ? "gallery9-image" : ""}`}
                      onClick={() => openModal(index + 4)} // Adjust index for correct modal
                    />
                  ))}
                </div>
              </>
            )}

            {/* For interior images, keep the current layout */}
            {selectedCategory === "interior" && (
  <div className="flex flex-wrap justify-center py-6">
    {imagesToShow.map((image, index) => (
      <div
        key={index}
        className={`${
          (image === gallery12 || image === gallery6)
            ? "w-full xl:w-4/12 lg:w-4/12 md:w-4/12 px-2 py-2" // Center these images with auto margin and make them 50% wide
            : "xl:w-4/12 lg:w-4/12 md:w-6/12 w-full px-2 py-2" // Normal layout for other images
        }`}
      >
        <img
          src={image}
          alt=""
          className="rounded-lg w-full h-270-px cursor-pointer"
          onClick={() => openModal(index)}
        />
      </div>
    ))}
  </div>
)}

          </div>
        </div>
      </section>

      <Footer />

      {/* Modal */}
      {isOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <div
              onClick={closeModal}
              className="modal-close"
            >
              <div className="" style={{ marginTop: "-8px", marginLeft: "5px" }}>
                &times;
              </div>
            </div>
            <div className="image-container">
              <img
                src={imagesToShow[currentIndex]}
                alt=""
                className={`popup-image ${imagesToShow[currentIndex] === gallery9 ? "gallery9-modal" : ""}`}
              />
            </div>

            <button
              onClick={showPreviousImage}
              className="modal-navigation modal-prev"
            >
              &#10094;
            </button>
            <button
              onClick={showNextImage}
              className="modal-navigation modal-next"
            >
              &#10095;
            </button>
          </div>
        </div>
      )}
    </>
  );
}
