



// /*eslint-disable*/
// import React from "react";
// import { Link } from "react-router-dom";
// import logosnew from "assets/img/logosnew.webp";
// // components

// export default function Navbar(props) {
//   const [navbarOpen, setNavbarOpen] = React.useState(false);

//   const [menuOpen, setMenuOpen] = React.useState(false);

//   return (
//     <>
//       <nav className="top-0 fixed z-50 w-full flex flex-wrap items-center justify-between px-2 navbar-expand-lg bg-white border-b ">
//         <div className="container px-4 mx-auto flex flex-wrap items-center justify-between justify-center">
//           <div className="xl:block lg:block md:hidden hidden">

//           </div>
//           <div className="xl:w-2/12 lg:w-6/12 md:w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
//             <Link
//               to="/"
//               className=" xl:flex  lg:flex mx-auto justify-center text-blueGray-700 text-sm font-bold leading-relaxed inline-block mr-4 whitespace-nowrap uppercase"
//             >
//               <img src={logosnew} className="w-3/12 md:w-2/12 xl:w-9/12 lg:w-3/12 rounded-xl" alt="Embassy Logo" style={{
//                 background: "#fffc",
//                 padding: "15px"
//               }} />
//             </Link>
//             <div id="menuToggle" className="xl:hidden lg:hidden block">
//               <input type="checkbox" checked={menuOpen} onChange={() => setMenuOpen(!menuOpen)} className="xl:hidden lg:hidden block" />
//               <span></span>
//               <span></span>
//               <span></span>
//               <ul id="menu" className={menuOpen ? "open" : ""}>
//                 <a href="/">
//                   <li className="pt-12 font text-base font-medium text-black">Home</li>
//                 </a>
//                 <a href="/project-highlight">
//                   <li className="font text-base font-medium text-black py-6">Project Highlight</li>
//                 </a>
//                 <a href="/location">
//                   <li className="font text-base font-medium text-black">Location</li>
//                 </a>
//                 <a href="/master-plans">
//                   <li className="font text-base font-medium text-black py-6">Master Plans</li>
//                 </a>
//                 <a href="/tower">
//                   <li className="font text-base font-medium text-black">Project Details</li>
//                 </a>
//                 <a href="/amenities">
//                   <li className="font text-base font-medium text-black py-6">Amenities</li>
//                 </a>
//                 <a href="/">
//                   <li className="font text-base font-medium text-black">Collaterals</li>
//                 </a>
//                 <a href="/">
//                   <li className="font text-base font-medium text-black py-6">Walkthrough</li>
//                 </a>
//                 <a href="/gallery">
//                   <li className="font text-base font-medium text-black">Gallery</li>
//                 </a>
//               </ul>
//             </div>
//           </div>
//           <div className="xl:block lg:block md:hidden hidden">

//           </div>
//           {/* <div
//             className={
//               "lg:flex flex-grow  lg:bg-opacity-0 lg:shadow-none" +
//               (navbarOpen ? " fixed top-0 left-0 w-full h-full bg-white flex justify-center  z-50" : " hidden")
//             }
//             id="example-navbar-warning"
//           >
//             {/* Close button when menu is open
//             {navbarOpen && (
//               <button
//                 className=" top-4 right-2 fixed text-3xl"
//                 onClick={() => setNavbarOpen(false)}
//               >
//                 <i className="fas fa-times fixed right-2"></i>
//               </button>
//             )}

//             <ul className="flex flex-col lg:flex-row list-none lg:ml-auto text-center xl:hidden lg:hidden block xl:pt-12">
//               <li className="flex items-center px-3 py-2">
//                 <a href="/" className="hover:text-blueGray-500 text-blueGray-700 text-base uppercase font-bold">
//                   Home
//                 </a>
//               </li>
//               <li className="flex items-center px-3 py-2">
//                 <a href="/project-highlight" className="hover:text-blueGray-500 text-blueGray-700 text-base uppercase font-bold">
//                   Project Highlight
//                 </a>
//               </li>
//               <li className="flex items-center px-3 py-2">
//                 <a href="/location" className="hover:text-blueGray-500 text-blueGray-700 text-base uppercase font-bold">
//                   Location
//                 </a>
//               </li>
//               <li className="flex items-center px-3 py-2">
//                 <a href="/master-plans" className="hover:text-blueGray-500 text-blueGray-700 text-base uppercase font-bold">
//                   Master Plan
//                 </a>
//               </li>
//               <li className="flex items-center px-3 py-2">
//                 <a href="/tower" className="hover:text-blueGray-500 text-blueGray-700 text-base uppercase font-bold">
//                   Project Details
//                 </a>
//               </li>
//               <li className="flex items-center px-3 py-2">
//                 <a href="/amenities" className="hover:text-blueGray-500 text-blueGray-700 text-base uppercase font-bold">
//                   Amenities
//                 </a>
//               </li>
//               <li className="flex items-center px-3 py-2">
//                 <a href="/gallery" className="hover:text-blueGray-500 text-blueGray-700 text-base uppercase font-bold">
//                   Gallery
//                 </a>
//               </li>
//             </ul>
//           </div> */}


//         </div>
//       </nav>
//     </>
//   );
// }






/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
import embassylogoo from "assets/img/embassylogoo.webp";
import logosnew from "assets/img/logosnew.webp";
import equinox from "assets/img/equinox.png"
// components

export default function IndexNav(props) {
  const [navbarOpen, setNavbarOpen] = React.useState(false);

  const [menuOpen, setMenuOpen] = React.useState(false);

  return (
    <>
      <nav className="top-0 fixed z-50 w-full flex flex-wrap items-center justify-between px-2 navbar-expand-lg transparent ">
        <div className="container px-4 mx-auto py-1">
          <div className="flex flex-wrap items-center justify-between">
            <div className="xl:w-5/12 lg:w-5/12 w-4/12">
              <div className="">
                {/* <img src={logosnew} className="xl:w-2/12 lg:w-2/12 w-5/12" alt="Embassy Logo" style={{


                }} /> */}
              </div>
            </div>
            <div className="xl:w-3/12 lg:w-3/12 w-4/12">
              <div className="px-4">
                {/* <img src={embassylogoo} className="xl:w-5/12 lg:w-7/12 md:w-5/12 w-8/12" alt="Embassy Logo" style={{

                }} /> */}
              </div>
            </div>
            <div className="xl:w-4/12 lg:w-4/12 w-2/12  ">
              <div className="flex ml-auto justify-end">
                {/* <img src={equinox} className="xl:w-4/12 lg:w-4/12 w-12/12" alt="Embassy Logo" style={{

                }} /> */}
              </div>

            </div>
            <div className="w-2/12">
              <div id="menuToggle" className="xl:hidden lg:hidden block">
                <input type="checkbox" checked={menuOpen} onChange={() => setMenuOpen(!menuOpen)} className="xl:hidden lg:hidden block" />
                <span></span>
                <span></span>
                <span></span>
                <ul id="menu" className={menuOpen ? "open" : ""}>
                  <a href="/">
                    <li className="pt-12 font text-base font-medium text-black">Home</li>
                  </a>
                  <a href="/project-highlight">
                    <li className="font text-base font-medium text-black py-6">Project Highlight</li>
                  </a>
                  <a href="/location">
                    <li className="font text-base font-medium text-black">Location</li>
                  </a>
                  <a href="/master-plans">
                    <li className="font text-base font-medium text-black py-6">Master Plans</li>
                  </a>
                  <a href="/tower">
                    <li className="font text-base font-medium text-black">Project Details</li>
                  </a>
                  <a href="/amenities">
                    <li className="font text-base font-medium text-black py-6">Amenities</li>
                  </a>
                  <a href="/Embassyonethane.pdf" target="_blank" rel="noopener noreferrer">
                    <li className="font text-base font-medium text-black">Collaterals</li>
                  </a>
                  <a href="/walkthrough">
                    <li className="font text-base font-medium text-black py-6">Walkthrough</li>
                  </a>
                  <a href="/gallery">
                    <li className="font text-base font-medium text-black">Gallery</li>
                  </a>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>




      <nav className="top-0 fixed z-50 w-full flex flex-wrap items-center justify-between px-2 navbar-expand-lg bg-white border-b ">
        <div className="container px-4   ">
          <div className="xl:block lg:block md:hidden hidden">

          </div>
          {/* <div className="xl:w-8/12 lg:w-6/12 md:w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <Link
              to="/"
              className=" xl:flex  lg:flex  text-blueGray-700 text-sm font-bold leading-relaxed inline-block mr-4 whitespace-nowrap uppercase"
            >
              <img src={logosnew} className="w-32" alt="Embassy Logo" style={{
                background: "#fffc",
                padding: "15px"
              }} />
              <img src={embassylogoo} className="w-3/12 md:w-2/12 xl:w-8/12 lg:w-3/12 rounded-xl" alt="Embassy Logo" style={{
                background: "#fffc",
                padding: "15px"
              }} />

            </Link>
            <div id="menuToggle" className="xl:hidden lg:hidden block">
              <input type="checkbox" checked={menuOpen} onChange={() => setMenuOpen(!menuOpen)} className="xl:hidden lg:hidden block" />
              <span></span>
              <span></span>
              <span></span>
              <ul id="menu" className={menuOpen ? "open" : ""}>
                <a href="/">
                  <li className="pt-12 font text-base font-medium text-black">Home</li>
                </a>
                <a href="/project-highlight">
                  <li className="font text-base font-medium text-black py-6">Project Highlight</li>
                </a>
                <a href="/location">
                  <li className="font text-base font-medium text-black">Location</li>
                </a>
                <a href="/master-plans">
                  <li className="font text-base font-medium text-black py-6">Master Plans</li>
                </a>
                <a href="/tower">
                  <li className="font text-base font-medium text-black">Project Details</li>
                </a>
                <a href="/amenities">
                  <li className="font text-base font-medium text-black py-6">Amenities</li>
                </a>
                <a href="/">
                  <li className="font text-base font-medium text-black">Collaterals</li>
                </a>
                <a href="/">
                  <li className="font text-base font-medium text-black py-6">Walkthrough</li>
                </a>
                <a href="/gallery">
                  <li className="font text-base font-medium text-black">Gallery</li>
                </a>
              </ul>
            </div>
          </div> */}
          <div className="xl:block lg:block md:hidden hidden">

          </div>
          {/* <div
            className={
              "lg:flex flex-grow  lg:bg-opacity-0 lg:shadow-none" +
              (navbarOpen ? " fixed top-0 left-0 w-full h-full bg-white flex justify-center  z-50" : " hidden")
            }
            id="example-navbar-warning"
          >
            {/* Close button when menu is open
            {navbarOpen && (
              <button
                className=" top-4 right-2 fixed text-3xl"
                onClick={() => setNavbarOpen(false)}
              >
                <i className="fas fa-times fixed right-2"></i>
              </button>
            )}

            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto text-center xl:hidden lg:hidden block xl:pt-12">
              <li className="flex items-center px-3 py-2">
                <a href="/" className="hover:text-blueGray-500 text-blueGray-700 text-base uppercase font-bold">
                  Home
                </a>
              </li>
              <li className="flex items-center px-3 py-2">
                <a href="/project-highlight" className="hover:text-blueGray-500 text-blueGray-700 text-base uppercase font-bold">
                  Project Highlight
                </a>
              </li>
              <li className="flex items-center px-3 py-2">
                <a href="/location" className="hover:text-blueGray-500 text-blueGray-700 text-base uppercase font-bold">
                  Location
                </a>
              </li>
              <li className="flex items-center px-3 py-2">
                <a href="/master-plans" className="hover:text-blueGray-500 text-blueGray-700 text-base uppercase font-bold">
                  Master Plan
                </a>
              </li>
              <li className="flex items-center px-3 py-2">
                <a href="/tower" className="hover:text-blueGray-500 text-blueGray-700 text-base uppercase font-bold">
                  Project Details
                </a>
              </li>
              <li className="flex items-center px-3 py-2">
                <a href="/amenities" className="hover:text-blueGray-500 text-blueGray-700 text-base uppercase font-bold">
                  Amenities
                </a>
              </li>
              <li className="flex items-center px-3 py-2">
                <a href="/gallery" className="hover:text-blueGray-500 text-blueGray-700 text-base uppercase font-bold">
                  Gallery
                </a>
              </li>
            </ul>
          </div> */}


        </div>
      </nav>
    </>
  );
}

